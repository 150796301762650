import { useState } from 'react';
import useResizeObserver from 'use-resize-observer';

import type { RefObject } from 'react';

interface TableHeightProps<T extends Element = HTMLDivElement> {
  ref: RefObject<T> | null | undefined;
}

export function useTableHeight({ ref }: TableHeightProps) {
  const { height = 100 } = useResizeObserver<HTMLDivElement>({ ref });
  const [tableHeaderElement, setTableHeaderElement] =
    useState<HTMLDivElement>();

  const { height: headerHeight = 60 } = useResizeObserver<HTMLDivElement>({
    ref: tableHeaderElement,
  });

  if (ref && ref.current) {
    const headerDiv = ref.current.getElementsByClassName(
      'ant-table-header',
    )[0] as HTMLDivElement;
    if (headerDiv !== tableHeaderElement) {
      setTableHeaderElement(headerDiv);
    }
  }

  return height - headerHeight;
}
