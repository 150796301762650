import { MapControl } from '@nextgis/react-ngw-map';
import { AutoComplete, Spin, message } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';

import geocoderStore from '../../../store/geocoder';

import type { NgwMap } from '../../../interfaces';
import type { ResultItem } from '@nextgis/geocoder';

type AutoCompleteParams = Parameters<typeof AutoComplete>[0];

type OptionType = NonNullable<AutoCompleteParams['options']>[0];

type Suggestion = OptionType & {
  result?: () => Promise<ResultItem>;
};

export const GeocoderMapControl = observer(({ ngwMap }: { ngwMap: NgwMap }) => {
  const [options, setOptions] = useState<Suggestion[]>([]);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { geocoder } = geocoderStore;

  const fetchSuggestions = useCallback(
    async (searchText: string) => {
      if (!geocoder) {
        throw new Error('Geocoder not ready');
      }
      setError('');
      if (!searchText) {
        setOptions([]);
        return;
      }

      setLoading(true);
      try {
        const suggestions = geocoder.search(searchText);
        const formattedSuggestions = [];

        for await (const item of suggestions) {
          formattedSuggestions.push({
            value: String(item._id),
            label: item.text,
            result: item.result,
          });
        }

        setOptions(formattedSuggestions);
      } catch (err) {
        message.error('Error fetching suggestions');
        setError((err as Error).message);
      } finally {
        setLoading(false);
      }
    },
    [geocoder],
  );

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      fetchSuggestions(value);
    }, 300);

    return () => clearTimeout(delayDebounce);
  }, [fetchSuggestions, value]);

  const onSelect = useCallback(
    async (_: unknown, option: Suggestion) => {
      if (option.result) {
        const res = await option.result();
        if (ngwMap && res) {
          ngwMap.fitBounds(res.extent, { maxZoom: 16 });
        }
      }
    },
    [ngwMap],
  );

  return (
    <MapControl position="top-left" addClass="geocoder-map-control">
      <AutoComplete
        placeholder="Enter location"
        value={value}
        options={options}
        style={{ width: 300 }}
        onSelect={onSelect}
        onSearch={setValue}
        suffixIcon={loading && <Spin />}
        status={error ? 'error' : undefined}
      ></AutoComplete>
    </MapControl>
  );
});
