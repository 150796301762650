import { makeAutoObservable, toJS } from 'mobx';

import { MAX_MYPOI_TO_SAVE } from '../config';

import geocoderStore from './geocoder';
import managerStore from './manager';

import type { RegistrableStore, SerializableParam } from './interface';
import type { Position } from 'geojson';

class MyPoiStore implements RegistrableStore {
  coordinates: Position[] = [];

  isPaused = false;

  constructor() {
    makeAutoObservable(this);
  }

  get serializableParams(): SerializableParam[] {
    const coords = toJS(this.coordinates);
    return [
      {
        key: 'my-poi',
        value:
          coords.length && coords.length < MAX_MYPOI_TO_SAVE
            ? coords
            : undefined,
        serialize: () =>
          coords.map((c) => c.map((x) => x.toFixed(5)).join('x')).join(','),
        deserialize: (value) => {
          const coords = value.split(',').map((c) => c.split('x').map(Number));
          this.setCoordinates(coords);
        },
      },
    ];
  }

  setCoordinates = (coordinates: Position[]) => {
    this.coordinates = coordinates;

    for (const coord of coordinates) {
      geocoderStore.appendReversResult(coord);
    }
  };

  setPaused = (status: boolean) => {
    this.isPaused = status;
  };
}

export default managerStore.register(new MyPoiStore());
