import { Space } from 'antd';
import dayjs from 'dayjs';

import {
  isMidnight,
  isSecondBeforeMidnight,
  nullifyMinutesAndSeconds,
  setSecondBeforeMidnight,
} from '../../utils/datetime';

import { AppSelect } from './AppSelect';

import type { Dayjs } from 'dayjs';
import type { CSSProperties } from 'styled-components';

type HourRange = (Dayjs | undefined)[];
interface HoursRangePickerProps {
  value?: HourRange;
  onChange?: (newValue: HourRange | undefined) => void;
  style?: CSSProperties;
  selectStyle?: CSSProperties;
}

const OPTIONS_FROM = [
  { label: '00:00', value: 0, disabled: false },
  { label: '01:00', value: 1, disabled: false },
  { label: '02:00', value: 2, disabled: false },
  { label: '03:00', value: 3, disabled: false },
  { label: '04:00', value: 4, disabled: false },
  { label: '05:00', value: 5, disabled: false },
  { label: '06:00', value: 6, disabled: false },
  { label: '07:00', value: 7, disabled: false },
  { label: '08:00', value: 8, disabled: false },
  { label: '09:00', value: 9, disabled: false },
  { label: '10:00', value: 10, disabled: false },
  { label: '11:00', value: 11, disabled: false },
  { label: '12:00', value: 12, disabled: false },
  { label: '13:00', value: 13, disabled: false },
  { label: '14:00', value: 14, disabled: false },
  { label: '15:00', value: 15, disabled: false },
  { label: '16:00', value: 16, disabled: false },
  { label: '17:00', value: 17, disabled: false },
  { label: '18:00', value: 18, disabled: false },
  { label: '19:00', value: 19, disabled: false },
  { label: '20:00', value: 20, disabled: false },
  { label: '21:00', value: 21, disabled: false },
  { label: '22:00', value: 22, disabled: false },
  { label: '23:00', value: 23, disabled: false },
];
const OPTIONS_TO = [
  { label: '01:00', value: 1, disabled: false },
  { label: '02:00', value: 2, disabled: false },
  { label: '03:00', value: 3, disabled: false },
  { label: '04:00', value: 4, disabled: false },
  { label: '05:00', value: 5, disabled: false },
  { label: '06:00', value: 6, disabled: false },
  { label: '07:00', value: 7, disabled: false },
  { label: '08:00', value: 8, disabled: false },
  { label: '09:00', value: 9, disabled: false },
  { label: '10:00', value: 10, disabled: false },
  { label: '11:00', value: 11, disabled: false },
  { label: '12:00', value: 12, disabled: false },
  { label: '13:00', value: 13, disabled: false },
  { label: '14:00', value: 14, disabled: false },
  { label: '15:00', value: 15, disabled: false },
  { label: '16:00', value: 16, disabled: false },
  { label: '17:00', value: 17, disabled: false },
  { label: '18:00', value: 18, disabled: false },
  { label: '19:00', value: 19, disabled: false },
  { label: '20:00', value: 20, disabled: false },
  { label: '21:00', value: 21, disabled: false },
  { label: '22:00', value: 22, disabled: false },
  { label: '23:00', value: 23, disabled: false },
  { label: '24:00', value: 24, disabled: false },
];

const numberToDayjs = (value: number | undefined): Dayjs | undefined => {
  if (value === undefined) return value;
  // replace 24 by 23:59 in order to distinct this date from 00:00
  // during dayjs converting
  if (value === 24) return setSecondBeforeMidnight(dayjs());
  return nullifyMinutesAndSeconds(dayjs().hour(value));
};

const HoursRangePicker = ({
  value,
  onChange,
  style,
  selectStyle,
}: HoursRangePickerProps) => {
  const fromValue = value && value[0]?.hour();
  const toValue =
    value &&
    value[1] &&
    (isSecondBeforeMidnight(value[1]) || isMidnight(value[1]))
      ? 24
      : value && value[1]?.hour();
  const optionsTo = fromValue
    ? OPTIONS_TO.map((option) => {
        return {
          ...option,
          disabled: option.value <= fromValue,
        };
      })
    : OPTIONS_TO;
  const onFromValueChanged = (newFromValue: number | undefined) => {
    if (onChange) {
      if (newFromValue && toValue && newFromValue >= toValue) {
        onChange([numberToDayjs(newFromValue), undefined]);
      } else {
        onChange([numberToDayjs(newFromValue), value && value[1]]);
      }
    }
  };
  const onToValueChanged = (newToValue: number | undefined) => {
    if (onChange) {
      onChange([value && value[0], numberToDayjs(newToValue)]);
    }
  };

  return (
    <Space style={style}>
      <AppSelect
        value={fromValue}
        options={OPTIONS_FROM}
        style={selectStyle}
        allowClear
        onChange={onFromValueChanged}
        placeholder="--:--"
      ></AppSelect>
      {'-'}
      <AppSelect
        value={toValue}
        options={optionsTo}
        style={selectStyle}
        allowClear
        onChange={onToValueChanged}
        placeholder="--:--"
      ></AppSelect>
    </Space>
  );
};

export { HoursRangePicker, type HourRange };
