import dayjs from 'dayjs';

import type {
  ColoringRule,
  ComparisonConfigItem,
  DisplayMode,
  Scenario,
  ScenarioConfig,
  SelectOption,
} from './interfaces';
import type { Credentials } from '@nextgis/ngw-connector';

export const BASE_URL = process.env.NGW_URL;
export const auth: Credentials | undefined =
  process.env.NGW_LOGIN && process.env.NGW_PASSWORD
    ? ({
        login: process.env.NGW_LOGIN,
        password: process.env.NGW_PASSWORD,
      } as Credentials)
    : undefined;

export const BASE_PATH = process.env.VIAVI_BASE_PATH || '';
export const NGW_URL = location.origin;

export const MAX_MYPOI_TO_SAVE = 50;
export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm:ss';

export const USER_TIMEZONE = dayjs.tz.guess();

// Empty for default
export const NOMINATIM_URL = undefined;
export const USE_GEOCODER_LOCAL_STORAGE = true;

export const EXTENT_RESOURCE = 'viavi_area';

export const POI_PROP_ID_FIELD = '__id';

export const POI_LOGO_SVG_RESOURCE = 'viavi_logo';
export const POI_POINT_LAYER_RESOURCE = 'viavi_poi';
export const LTE_SECTORS_LAYER_RESOURCE = 'ltesectors';

export const POI_POINT_LAYER_ID = 'poi-point';
export const POI_POLYGON_LAYER_ID = 'poi-polygon';
export const LTE_SECTORS_LAYER_ID = 'ltesectors';

export const MY_POI_VALUE = 'mypoi';
export const MY_POI_LABEL = 'My POI';

export const NOT_SET_OPTION: SelectOption = { label: 'Not set', value: '' };

export const LTE_SECTORS_LAYER_MIN_ZOOM = 12;

export const DISPLAY_MODES: SelectOption<DisplayMode>[] = [
  { label: 'Map', value: 'map' },
  { label: 'Table', value: 'table' },
];

export const SCENARIOS: Record<Scenario, ScenarioConfig> = {
  'poi-analysis': {
    id: 'poi-analysis',
    title: 'POI Analysis',
  },
  'critical-areas': {
    id: 'critical-areas',
    title: 'Critical Area Identification',
  },
};

export const COMPARISON_ID = 'comparison';
export const COMPARISON_LABEL = 'Comparison';
export const COMPARISON_DELTA_ID = 'delta';
export const COMPARISON_DELTA_LABEL = 'Delta';
export const NULL_LABEL = 'N/D';
export const NULL_COLORING_RULE: ColoringRule = {
  rule: ['isSameAs', null],
  color: '#bebebe',
  label: NULL_LABEL,
};

export const COMPARISON_LABELS = [
  'worst',
  'worse',
  'eq.bad',
  'eq.good',
  'better',
  'best',
];
export const DEFAULT_COMPARISON_PALLETE = [
  {
    label: 'Worst Performance',
    color: '#D73027',
  },
  {
    label: 'Worse Performance',
    color: '#FC8D59',
  },
  {
    label: 'Equally Bad Performance',
    color: '#FEE08B',
  },
  {
    label: 'Equally Good Performance',
    color: '#D9EF8B',
  },
  {
    label: 'Better Performance',
    color: '#91CF60',
  },
  {
    label: 'Best Performance',
    color: '#1A9850',
  },
];

const COMAPISON_DESCRIPTION =
  "<p>This metric is built as a result of Client's KPI and Competitor's KPI perfromance comparison. User can compare similar or different KPIs.</p>  <p>KPI Comparison consists of six categories (naming can be changed):</p> <p><strong>| worst performance | worse performance | equally bad | equally good | better performance | best performance |</strong></p> <p>When the analysis is carried out, categories of Client's KPI and Competitor's KPI for each POI are checked and the category of KPI Comparison is determined. In terms of the additional criteria of comparison – the difference between Client’s KPI and Competitor’s KPI (delta-KPI, Δ) is determined by formula:</p> <p><strong>Δ = | Client's POI KPI - Competitor's POI KPI |</strong></p> <p>Δ will be calculated and added to analysis result only if the Client's POI KPI and the Competitor's POI KPI are the same and have equal units.</p>";

export const COMPARISON_DEFAULT_CONFIG: ComparisonConfigItem = {
  identity: COMPARISON_ID,
  name: COMPARISON_ID,
  label: COMPARISON_LABEL,
  labelDetailed: COMPARISON_LABEL,
  categoryColoringRules: DEFAULT_COMPARISON_PALLETE.map(
    (palleteItem, index) => ({
      color: palleteItem.color,
      rule: ['isSameAs', index],
      label: COMPARISON_LABELS[index],
    }),
  ),
  description: COMAPISON_DESCRIPTION,
};

export const SECOND_BEFORE_MIDNIGHT = '23:59:59';
export const MIDNIGHT = '00:00:00';
