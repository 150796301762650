import { AnalysisService } from './AnalysisService';

import type { AnalysisDataFetcher } from '../api/analysisApi';
import type { UIAnalysisCriteria } from '../domain/criteria';

export class CriticalAreaService extends AnalysisService {
  constructor(request: AnalysisDataFetcher) {
    const criticalAreaRequest: AnalysisDataFetcher = async (criteria) =>
      await request({ ...criteria, compare: true });
    super(criticalAreaRequest);
  }

  isAllowedToExecute(uiCriteria: UIAnalysisCriteria) {
    return (
      super.isAllowedToExecute(uiCriteria) &&
      uiCriteria.kpi.length === 2 &&
      !!uiCriteria.poiCategory
    );
  }
}
