import { Form } from 'antd';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';

import { AnalysisForm } from '../components/analysis/AnalysisSidebar';
import { AppSelect } from '../components/shared/AppSelect';
import analysisStore from '../store/analysis';
import appStore from '../store/app';

export const CriticalAreaSidebar = observer(() => {
  const { kpiSelectItems, competitorsKpiSelectItems } = appStore;

  const { criteria } = analysisStore;

  const [clientKpi, setClientKpi] = useState<string | null>(
    () => criteria.kpi[0] || null,
  );

  const [competitorKpi, setCompetitorKpi] = useState<string | null>(
    () => criteria.kpi[1] || null,
  );

  const kpi = useMemo(
    () => [clientKpi, competitorKpi].filter((kpi) => !!kpi) as string[],
    [clientKpi, competitorKpi],
  );

  return (
    <AnalysisForm kpi={kpi}>
      <Form.Item label="Client's KPI">
        <AppSelect
          value={clientKpi}
          onChange={setClientKpi}
          options={kpiSelectItems}
          placeholder="Select Client's KPI"
          showSearch
          allowClear
        />
      </Form.Item>
      <Form.Item label="Competitor's KPI">
        <AppSelect
          value={competitorKpi}
          onChange={setCompetitorKpi}
          options={competitorsKpiSelectItems}
          placeholder="Select Competitor's KPI"
          showSearch
          allowClear
        />
      </Form.Item>
    </AnalysisForm>
  );
});
