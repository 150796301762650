import { ClearOutlined } from '@ant-design/icons';
import { mdiArrowCollapseLeft, mdiArrowCollapseRight } from '@mdi/js';
import Icon from '@mdi/react';
import { MapControl } from '@nextgis/react-ngw-map';
import ReactNgwMap from '@nextgis/react-ngw-mapbox';
import { Badge, Button, Space, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { isValidElement, useEffect, useState } from 'react';

import connector from '../../api/connector';
import { usePoiLayer } from '../../hooks/usePoiLayer';
import analysisStore from '../../store/analysis';
import appStore from '../../store/app';
import chartsStore from '../../store/charts';
import mapStore from '../../store/map';
import { ViaviLoader } from '../ViaviLoader';

import { GeocoderMapControl } from './components/GeocoderMapControl';
import { MyPoiMapDraw } from './components/MyPoiMapDraw';
import { ToggleChartsButton } from './components/ToggleChartsButon';

import type { NgwMap, ViaviDisplayComponentProps } from '../../interfaces';
import type { MapOptions } from 'maplibre-gl';
import type { CSSProperties } from 'react';

// import { LteTogglerMapControl } from './components/LteTogglerMapControl';

export const ViaviMap = observer(
  ({
    isLoading,
    children,
    style,
    mapOptions,
    className,
    showGeocoder = true,
  }: ViaviDisplayComponentProps) => {
    usePoiLayer();

    const childrenAdapted = Array.isArray(children) ? children : [children];

    const chartSlot = childrenAdapted.find(
      (child) => isValidElement(child) && child.key === 'charts',
    );

    const { basemapStyles } = appStore;
    const { areChartsShown, selectedBars } = chartsStore;
    const { isMyPoi, criteria } = analysisStore;
    const { ngwMap, extent } = mapStore;

    const [initExtent] = useState(extent);

    const mapAdapterOptions: Partial<MapOptions> = {
      fadeDuration: 0,
      ...mapOptions,
    };

    let osm = true;
    const customAttribution: string[] = [];
    if (basemapStyles.length) {
      osm = false;
      const basemapStyle = basemapStyles[0];
      mapAdapterOptions.style = basemapStyle.url;
      if (basemapStyle.attribution) {
        customAttribution.push(basemapStyle.attribution);
      }
    }

    const toggleChartVisibility = () => {
      chartsStore.setAreChartsShown(!areChartsShown);
    };

    const viaviMapCss: CSSProperties = {
      width: '100%',
      position: 'relative',
      flexGrow: 1,
      ...style,
    };

    const whenCreated = (ngwMap: NgwMap) => {
      mapStore.setNgwMapId(ngwMap.getId());
      const updateExtent = () => {
        mapStore.setExtent(ngwMap.getBounds() || null);
      };
      ngwMap.emitter.on('zoomend', updateExtent);
      ngwMap.emitter.on('moveend', updateExtent);
      ngwMap.mapAdapter.map?.resize();
    };

    useEffect(() => {
      return () => {
        mapStore.setNgwMapId(null);
        mapStore.coloredIcons = {};
      };
    }, []);

    return (
      <div className={`viavi-map ${className || ''}`} style={viaviMapCss}>
        <ReactNgwMap
          id="map"
          osm={osm}
          bounds={initExtent || undefined}
          whenCreated={whenCreated}
          mapAdapterOptions={mapAdapterOptions}
          connector={connector}
          controlsOptions={{
            ZOOM: { position: 'bottom-left' },
            ATTRIBUTION: {
              position: 'bottom-right',
              customAttribution: [
                ...customAttribution,
                '<a href="https://nextgis.com" target="_blank">©NextGIS</a>',
              ],
            },
          }}
        >
          {/* {!!ngwMap && <LteTogglerMapControl ngwMap={ngwMap} />} */}
          {!!ngwMap && showGeocoder && <GeocoderMapControl ngwMap={ngwMap} />}
          {isMyPoi && !!ngwMap && (
            <MapControl position="bottom-left" bar>
              <MyPoiMapDraw ngwMap={ngwMap} />
            </MapControl>
          )}

          {!!chartSlot && (
            <>
              <MapControl position="top-right">
                <Space direction="vertical">
                  <Tooltip
                    title={areChartsShown ? 'Hide charts' : 'Show charts'}
                  >
                    <Badge
                      offset={[1, 1]}
                      count={areChartsShown ? 0 : selectedBars.length}
                      size="small"
                    >
                      <Button
                        className="font-weight-regular"
                        style={{
                          paddingLeft: '8px',
                          paddingRight: '8px',
                        }}
                        onClick={toggleChartVisibility}
                        icon={
                          <Icon
                            size="16px"
                            path={
                              areChartsShown
                                ? mdiArrowCollapseRight
                                : mdiArrowCollapseLeft
                            }
                          />
                        }
                      />
                    </Badge>
                  </Tooltip>
                  {areChartsShown && criteria.kpi.length > 1 && (
                    <ToggleChartsButton />
                  )}
                  {areChartsShown && !!selectedBars.length && (
                    <Tooltip title="Clear filters">
                      <Badge
                        offset={[1, 1]}
                        count={selectedBars.length}
                        size="small"
                      >
                        <Button
                          onClick={() => {
                            chartsStore.updateSelectedBars([]);
                          }}
                          icon={<ClearOutlined />}
                        ></Button>
                      </Badge>
                    </Tooltip>
                  )}
                </Space>
              </MapControl>

              <MapControl position="top-right"></MapControl>
              <MapControl
                addClass="chart-map-control"
                position="top-right"
                style={{
                  display: areChartsShown ? 'block' : 'none',
                }}
              >
                {chartSlot}
              </MapControl>
            </>
          )}
        </ReactNgwMap>
        {isLoading && <ViaviLoader />}
      </div>
    );
  },
);
