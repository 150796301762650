import dayjs from 'dayjs';
import { makeAutoObservable, runInAction } from 'mobx';
import sanitizeHtml from 'sanitize-html';

import { getConfig } from '../api/configApi';
import connector from '../api/connector';
import { getPoiCategories } from '../api/poiApi';
import {
  COMPARISON_DEFAULT_CONFIG,
  COMPARISON_ID,
  DATE_FORMAT,
  DISPLAY_MODES,
  NGW_URL,
  NOMINATIM_URL,
  USER_TIMEZONE,
} from '../config';
import {
  makeCategoryColoringRules,
  makeColoringRules,
  makeComparisonColoringRules,
} from '../utils/coloringRules';
import { getKpiDetailedLabel } from '../utils/kpiUtils';

import managerStore from './manager';
import mapStore from './map';

import type { RegistrableStore, SerializableParam } from './interface';
import type {
  BaseMapStyle,
  BufferConfig,
  CategoryConfigItem,
  ComparisonConfigItem,
  DisplayMode,
  KpiConfigItem,
  PoiCategoryItem,
  Scenario,
  SelectOption,
  TimeRanges,
  Tool,
} from '../interfaces';
import type { UserInfo } from '@nextgis/ngw-connector';
import type { Dayjs } from 'dayjs';

class AppStore implements RegistrableStore {
  isLogging = true;
  isReady = false;
  isLoading = false;
  isFailed = false;
  user: UserInfo | null = null;

  // available date range in utc
  timeRanges: TimeRanges = { daily: ['', ''], hourly: ['', ''] };

  // available dayly date range in user's timezone
  localeMinDate: Dayjs | undefined = undefined;
  localeMaxDate: Dayjs | undefined = undefined;

  // available hourly date range in user's timezone
  localeMinHourlyDate: Dayjs | undefined = undefined;
  localeMaxHourlyDate: Dayjs | undefined = undefined;

  scenario: Scenario | undefined = undefined;

  poiCategories: PoiCategoryItem[] = [];
  kpiConfig: KpiConfigItem[] = [];
  comparisonConfig: ComparisonConfigItem = COMPARISON_DEFAULT_CONFIG;
  bufferConfig: BufferConfig = [];

  nominatimUrl?: string = NOMINATIM_URL;
  basemapStyles: BaseMapStyle[] = [];

  displayMode: DisplayMode = 'map';

  activeTool: Tool = undefined;

  constructor() {
    makeAutoObservable(this);

    this.initialize()
      .then(() => {
        this._updateLocalDate();
        this.setIsReady(true);
      })
      .catch((er) => {
        this.setIsFailed(true);
        console.log(er);
      });
  }

  get kpiSelectItems(): SelectOption[] {
    return this.kpiConfig
      .filter((config) => config.scope !== 'competitor')
      .map((kpi) => ({
        label: kpi.label,
        value: kpi.identity,
      }));
  }

  get competitorsKpiSelectItems(): SelectOption[] {
    return this.kpiConfig
      .filter((config) => config.scope === 'competitor')
      .map((kpi) => ({
        label: kpi.label,
        value: kpi.identity,
      }));
  }

  async initialize() {
    return Promise.all([
      this.fetchConfig(),
      mapStore.fetchMapExtent(),
      this.fetchUserInfo(),
      this.fetchPoiCategories(),
    ]);
  }

  get serializableParams(): SerializableParam[] {
    const params: SerializableParam[] = [
      {
        key: 'display_mode',
        value: this.displayMode !== 'map' ? this.displayMode : undefined,
        serialize: () => this.displayMode,
        deserialize: (value) => {
          this.displayMode = value as DisplayMode;
        },
      },
      {
        key: 'tool',
        value: this.activeTool,
        serialize: () => this.activeTool || '',
        deserialize: (value) => {
          this.setActiveTool(value as Tool);
        },
      },
    ];
    return params;
  }

  async fetchUserInfo() {
    try {
      const u = await connector.getUserInfo();
      this.setUser(u);
    } catch {
      this.logout();
    }
  }

  async fetchConfig() {
    const {
      buffers,
      kpi,
      time_ranges,
      basemap_style_url,
      nominatim_url,
      comparison,
    } = await getConfig();
    const kpiSorted = [...kpi].sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    const kpiConfigWithColoringRules: KpiConfigItem[] = kpiSorted.map(
      (kpiConfig) => {
        const categoryConfigsFormatted: CategoryConfigItem[] =
          kpiConfig.categories.map(([label, color]) => [
            label.toLowerCase(),
            color,
          ]);
        return {
          ...kpiConfig,
          categories: categoryConfigsFormatted,
          labelDetailed: getKpiDetailedLabel(kpiConfig),
          description:
            kpiConfig.description && sanitizeHtml(kpiConfig.description),
          coloringRules: makeColoringRules(
            kpiConfig.levels,
            categoryConfigsFormatted,
          ),
          categoryColoringRules: makeCategoryColoringRules(
            categoryConfigsFormatted,
          ),
        };
      },
    );
    const comparisonConfig = {
      ...this.comparisonConfig,
      categoryColoringRules: makeComparisonColoringRules(comparison),
    };
    if (basemap_style_url) {
      this.setBasemapStyles([
        {
          label: 'OSM',
          url: basemap_style_url,
        },
      ]);
    }
    if (nominatim_url) {
      this.setNominatinUrl(nominatim_url);
    }
    this.setTimeRanges(time_ranges);
    this.setBufferConfig(buffers);
    this.setKpiConfig(kpiConfigWithColoringRules);
    this.setComparisonConfig(comparisonConfig);
  }

  async fetchPoiCategories() {
    try {
      const poiCategories = await getPoiCategories();
      return this.setPoiCategories(poiCategories);
    } catch {
      throw new Error('Can not load POI categories');
    }
  }

  setDisplayMode = (mode: DisplayMode) => {
    if (DISPLAY_MODES.find((m) => m.value === mode)) {
      this.displayMode = mode;
    } else {
      throw new Error(`The '${mode}' mode is not available`);
    }
  };

  setBasemapStyles(styles: BaseMapStyle[]) {
    this.basemapStyles = styles;
  }

  setNominatinUrl(nominatimUrl: string) {
    this.nominatimUrl = nominatimUrl;
  }

  setActiveTool = (newTool: Tool) => {
    this.activeTool = newTool;
  };

  setPoiCategories(newPoiCategories: PoiCategoryItem[]) {
    this.poiCategories = newPoiCategories;
  }

  setIsLoading = (val: boolean) => {
    this.isLoading = val;
  };

  setIsReady = (val: boolean) => {
    this.isReady = val;
  };

  setIsFailed = (val: boolean) => {
    this.isFailed = val;
  };

  setScenario = (newScenario: Scenario) => {
    this.scenario = newScenario;
  };

  setKpiConfig = (newKpiConfig: KpiConfigItem[]) => {
    this.kpiConfig = newKpiConfig;
  };

  setComparisonConfig = (newComparisonConfig: ComparisonConfigItem) => {
    this.comparisonConfig = newComparisonConfig;
  };

  setTimeRanges = (newTimeRanges: TimeRanges) => {
    this.timeRanges = newTimeRanges;
  };

  setBufferConfig = (newBufferConfig: BufferConfig) => {
    this.bufferConfig = newBufferConfig;
  };

  getKpiConfigById(kpiId: string | number): KpiConfigItem | undefined {
    return this.kpiConfig.find((kpiConfig) => kpiConfig.identity === kpiId);
  }

  getConfigById(
    id: string | number,
  ): KpiConfigItem | ComparisonConfigItem | undefined {
    if (id === COMPARISON_ID) return this.comparisonConfig;
    return this.getKpiConfigById(id);
  }

  setLocaleMinDate(newDate: Dayjs | undefined) {
    this.localeMinDate = newDate;
  }

  setLocaleMaxDate(newDate: Dayjs | undefined) {
    this.localeMaxDate = newDate;
  }

  setLocaleMinHourlyDate(newDate: Dayjs | undefined) {
    this.localeMinHourlyDate = newDate;
  }

  setLocaleMaxHourlyDate(newDate: Dayjs | undefined) {
    this.localeMaxHourlyDate = newDate;
  }

  setUser = (user: UserInfo) => {
    this.isLogging = false;
    if (user.keyname !== 'guest') {
      runInAction(() => {
        this.user = user;
      });
    } else {
      this.user = null;
    }
  };

  logout() {
    this.isLogging = false;
    this.user = null;
    window.open(NGW_URL + '/logout', '_self');
  }

  private _updateLocalDate() {
    const dailyRange = this.timeRanges.daily;
    const hourlyRange = this.timeRanges.hourly;
    this.setLocaleMinDate(
      dayjs.utc(dailyRange[0], DATE_FORMAT).tz(USER_TIMEZONE).startOf('day'),
    );
    this.setLocaleMaxDate(
      dayjs.utc(dailyRange[1], DATE_FORMAT).tz(USER_TIMEZONE).endOf('day'),
    );
    this.setLocaleMinHourlyDate(
      dayjs.utc(hourlyRange[0], DATE_FORMAT).tz(USER_TIMEZONE).startOf('day'),
    );
    this.setLocaleMaxHourlyDate(
      dayjs
        .utc(hourlyRange[1], DATE_FORMAT)
        .tz(USER_TIMEZONE)
        .endOf('day')
        .subtract(1, 'day'), // remove it, if server returns correct range
    );
  }
}

const appStore = managerStore.register(new AppStore());

export type { AppStore };

export default appStore;

// Not in the storage constructor,
// because the connection event can be emitted anywhere
connector.emitter.on('login', (user) => {
  appStore.setUser(user);
});
connector.emitter.on('logout', () => {
  appStore.logout();
});
connector.emitter.on('login:error', () => {
  appStore.logout();
});
