import { Menu } from 'antd';

import type { SidebarValue } from '../../interfaces';
import type { MenuItemType } from 'antd/es/menu/hooks/useItems';
import type { MenuClickEventHandler } from 'rc-menu/lib/interface';

interface AppSidebarMenuProps {
  items: MenuItemType[];
  value?: SidebarValue;
  onChange?: (value: SidebarValue) => void;
}

export function AppSidebarMenu({
  items,
  value,
  onChange,
}: AppSidebarMenuProps) {
  const localItems: MenuItemType[] = items.map((item) => {
    const activeClass =
      item.key === value?.key && value.isActive ? 'active' : '';
    return {
      ...item,
      label: (
        <div className={`app-sidebar-menu__item ${activeClass}`}>
          {item.label}
        </div>
      ),
    };
  });

  const onClick: MenuClickEventHandler = ({ key }) => {
    if (onChange) {
      onChange({
        key,
        isActive: key !== value?.key || !value?.isActive,
      });
    }
  };

  return (
    <Menu
      className="sidebar-menu"
      mode="vertical"
      items={localItems}
      selectedKeys={value ? [value.key] : undefined}
      onClick={onClick}
      style={{ borderInlineEnd: 'none' }}
    />
  );
}
