import {
  COMPARISON_DELTA_ID,
  COMPARISON_DELTA_LABEL,
  COMPARISON_ID,
  COMPARISON_LABEL,
  MY_POI_LABEL,
  MY_POI_VALUE,
} from '../config';
import appStore from '../store/app';
import { getComparisonDataKeys } from '../utils/comparison';

import type { KpiConfigItem, PoiProperties } from '../interfaces';
import type { ColumnType } from 'antd/es/table';

export type Columns = ColumnType<PoiProperties>[];

export const CHECK_FIELDS: (keyof PoiProperties)[] = ['SUBTYPE', 'ADDRESS'];

const COMPARISON_COLUMNS_SETTINGS: Record<string, ColumnType<PoiProperties>> = {
  [COMPARISON_ID]: {
    title: COMPARISON_LABEL,
    dataIndex: COMPARISON_ID,
  },
  [COMPARISON_DELTA_ID]: {
    title: COMPARISON_DELTA_LABEL,
    dataIndex: COMPARISON_DELTA_ID,
    sorter: (a, b) =>
      Number(a[COMPARISON_DELTA_ID]) - Number(b[COMPARISON_DELTA_ID]),
  },
};

export function getTableColumns({
  poiKpis,
  hiddenFields,
}: {
  poiKpis: string[];
  hiddenFields: (keyof PoiProperties)[];
}) {
  const kpiConfigs = poiKpis.map((kpi) => appStore.getConfigById(kpi));
  const kpiColumns: Columns = poiKpis.map((key, index) => ({
    title: kpiConfigs[index]?.labelDetailed || key,
    dataIndex: key,
    sorter: (a, b) => Number(a[key]) - Number(b[key]),
  }));

  if (appStore.scenario === 'critical-areas' && poiKpis.length > 0) {
    getComparisonDataKeys(kpiConfigs as [KpiConfigItem, KpiConfigItem]).forEach(
      (dataKey) => {
        kpiColumns.push(COMPARISON_COLUMNS_SETTINGS[dataKey]);
      },
    );
  }

  const cols: Columns = [
    { title: 'Name', dataIndex: 'NAME' },
    { title: 'Address', dataIndex: 'ADDRESS' },
    {
      title: 'Category',
      dataIndex: 'TYPE',
      render: (val) => (val === MY_POI_VALUE ? MY_POI_LABEL : val),
    },
    { title: 'Subcategory', dataIndex: 'SUBTYPE' },
    ...kpiColumns,
    { title: 'Commentary', dataIndex: 'COMMENTARY' },
  ];

  const tableColumns: Columns = [];

  for (const col of cols) {
    if ('dataIndex' in col) {
      const notHidden = !hiddenFields.includes(
        col.dataIndex as keyof PoiProperties,
      );
      if (notHidden) {
        const dataIndex = String(col.dataIndex);
        if (!col.sorter) {
          col.sorter = (a, b) =>
            String(a[dataIndex]).localeCompare(String(b[dataIndex]));
        }
        tableColumns.push(col);
      }
    }
  }
  return tableColumns;
}

export function getTableHiddenFields({ data }: { data: PoiProperties[] }) {
  const checkFields: (keyof PoiProperties)[] = [...CHECK_FIELDS];

  for (const props of data) {
    for (const f of checkFields) {
      if (props[f]) {
        checkFields.splice(checkFields.indexOf(f), 1);
      }
      if (!checkFields.length) {
        break;
      }
    }
  }

  return checkFields;
}
