import { useState } from 'react';

export const useCopyToClipboard = (text: string) => {
  const [isCopied, setIsCopied] = useState(false);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(text).then(() => {
      setIsCopied(true);
    });
  };
  return { isCopied, setIsCopied, copyToClipboard };
};
