import { observer } from 'mobx-react-lite';
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import type { BarChartItem } from '../../interfaces';
import type { CategoricalChartFunc } from 'recharts/types/chart/generateCategoricalChart';
import type {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import type { ContentType } from 'recharts/types/component/Tooltip';
import type { CSSProperties } from 'styled-components';

interface AppBarChartProps {
  data: BarChartItem[];
  width: number | string;
  height: number | string;
  xKey: string;
  yKey: string;
  isXRange?: boolean;
  barColor?: string;
  xTickAangle?: number;
  style: CSSProperties;
  tooltip?: ContentType<ValueType, NameType>;
  onChartClick?: CategoricalChartFunc;
  tickFormatter?: (tick: number | null) => string;
}

export const AppBarChart = observer(
  ({
    data,
    width,
    height,
    xKey,
    yKey,
    barColor,
    xTickAangle,
    isXRange = false,
    // range,
    onChartClick,
    style,
    tooltip,
    tickFormatter,
  }: AppBarChartProps) => {
    // const ticks = isXRange ? createTicksFromRanges(data) : undefined;
    // const dataFormatted: BarChartItem[] = useMemo(
    //   () => (isXRange ? addMiddleRangeX(data as BarChartItem[], xKey)
    // : data),
    //   [data, xKey, isXRange],
    // );

    return (
      <ResponsiveContainer width={width} height={height}>
        <BarChart
          // width={width}
          // height={height}
          data={data}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          style={{ ...style, fontSize: '10px' }}
          barCategoryGap={isXRange ? 0 : 4}
          onClick={onChartClick}
        >
          {isXRange ? (
            <XAxis
              dataKey="middleX"
              stroke="#8692A2"
              type="number"
              // domain={range}
              // ticks={ticks}
              tickFormatter={tickFormatter}
              padding={{ left: 4, right: 12 }}
              tickLine={false}
              minTickGap={6}
            />
          ) : (
            <XAxis
              dataKey={xKey}
              stroke="#8692A2"
              interval={0}
              angle={xTickAangle}
              padding={{ left: 4, right: 4 }}
            ></XAxis>
          )}
          <YAxis
            width={32}
            stroke="#8692A2"
            tickLine={false}
            tickFormatter={tickFormatter}
          />
          {tooltip && <Tooltip content={tooltip} />}
          <Bar dataKey={yKey}>
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={'color' in entry ? entry.color : barColor}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  },
);
