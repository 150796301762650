import type { PoiProperties } from '../../../interfaces';
import type { Columns } from '../../../utils/getTableColumns';

function processRow(row: PoiProperties, columns: Columns) {
  const rowCols = [];
  for (const col of columns) {
    if ('dataIndex' in col) {
      const key = String(col.dataIndex);
      let val = row[key] == null ? '' : String(row[key]);

      if (val.includes(',')) {
        val = `"${val}"`;
      }

      rowCols.push(val);
    }
  }
  return rowCols.join(',');
}

export function downloadCsv({
  data,
  columns,
}: {
  data: PoiProperties[];
  columns: Columns;
}) {
  const headerRow = columns.map((col) => col.title).join(',');

  const csvContent = data.map((row) => processRow(row, columns));

  const blob = new Blob([[headerRow, ...csvContent].join('\n')], {
    type: 'text/csv;charset=utf-8;',
  });

  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.setAttribute('download', 'export.csv');
  document.body.appendChild(link);
  link.click();

  // Clean up
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}
