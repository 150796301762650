import connector from './connector';

import type { ConfigurationApi } from '../interfaces';

const getConfig = () =>
  connector.makeQuery<ConfigurationApi>(
    '/api/component/viavi/configuration',
    null,
    { cache: true },
  );

export { getConfig };
