import { type Expression, evaluate } from '@nextgis/expression';

import type {
  PoiItemCategoryObject,
  PoiItemObject,
  PoiItemValueObject,
} from '../interfaces';

export function filterPoiObjects({
  poiObjects,
  filter,
  targetKey,
}: {
  filter: Expression;
  poiObjects: PoiItemObject[];
  targetKey?: 'values' | 'categories';
}) {
  return poiObjects.filter((poiObject) => {
    const objectToFilter = targetKey ? poiObject[targetKey] : poiObject;
    if (typeof objectToFilter === 'object') {
      return evaluate(
        filter,
        objectToFilter as PoiItemValueObject | PoiItemCategoryObject,
      );
    } else {
      throw new Error('objectToFilter is not an object');
    }
  });
}
