import { AnalysisService } from './AnalysisService';

import type { UIAnalysisCriteria } from '../domain/criteria';

export class PoiAnalysisService extends AnalysisService {
  isAllowedToExecute(uiCriteria: UIAnalysisCriteria) {
    return (
      super.isAllowedToExecute(uiCriteria) &&
      uiCriteria.kpi.length > 0 &&
      !!uiCriteria.poiCategory
    );
  }
}
