import { useCallback, useEffect, useRef } from 'react';

export function useAbortController() {
  const controllers = useRef<AbortController[]>([]);

  const makeSignal = useCallback(() => {
    const abortController = new AbortController();
    controllers.current = [...controllers.current, abortController];
    return abortController.signal;
  }, []);

  const abort = useCallback(() => {
    for (const a of controllers.current) {
      a.abort();
    }
    controllers.current = [];
  }, []);

  useEffect(() => {
    return () => {
      abort();
    };
  }, [abort]);

  return { makeSignal, abort };
}
