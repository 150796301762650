import { getComparisonLabel } from '../utils/comparison';

import type {
  ComparisonConfigItem,
  PoiItem,
  PoiItemObject,
  PoiItemWithComparison,
} from '../interfaces';

export function createPoiObjects({
  poi,
  poiKpis,
  withComparison,
  comparisonConfig,
}: {
  poi: (PoiItem | PoiItemWithComparison)[];
  poiKpis: string[];
  withComparison?: boolean;
  comparisonConfig?: ComparisonConfigItem;
}): PoiItemObject[] {
  return poi.map((poiItem) => {
    const poiCategoriesEntries = (poiItem[2] as (number | null)[]).map(
      (poiValue, index) => [poiKpis[index], poiValue],
    );
    const categoriesObject = {
      ...Object.fromEntries(poiCategoriesEntries),
    };
    if (withComparison && comparisonConfig)
      categoriesObject.comparison = poiItem[3];

    const poiValuesEntries = (poiItem[1] as (number | null)[]).map(
      (poiValue, index) => [poiKpis[index], poiValue],
    );
    const valuesObject = {
      ...Object.fromEntries(poiValuesEntries),
    };
    if (withComparison && comparisonConfig)
      valuesObject.comparison = getComparisonLabel(
        poiItem[3],
        comparisonConfig,
      );
    return {
      id: poiItem[0],
      values: valuesObject,
      categories: categoriesObject,
    };
  });
}
