import { theme } from 'antd';
import { styled } from 'styled-components';

import type { SummaryValue } from '../../hooks/useAnalysisSummary';
import type { CSSProperties } from 'react';

const { useToken } = theme;

const SummaryCard = styled.div`
  font-size: 12px;
  background-color: white;
  padding: 12px 8px;
  border-radius: 4px;
`;

const AnalysisSummary = ({
  value,
  style,
  className,
}: {
  value: SummaryValue[];
  style?: CSSProperties;
  className?: string;
}) => {
  const { token } = useToken();
  return (
    <SummaryCard style={style} className={className}>
      {value.map(
        (value) =>
          value.value && (
            <div key={value.id} style={{ marginBottom: '2px' }}>
              <strong
                style={{
                  color: token.colorPrimary,
                  marginRight: '8px',
                }}
              >
                {value.label}
              </strong>
              <span style={value.style}>{value.value}</span>
            </div>
          ),
      )}
    </SummaryCard>
  );
};

export { AnalysisSummary };
