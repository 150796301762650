import {
  createLegendLabel,
  makeRangeStringByColoringRule,
  shortenNumber,
} from '../../utils/chartUtils';

import type { ExtendedBarChartItem } from '../../interfaces';
import type { TooltipProps } from 'recharts';
import type {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

interface AnalysisBarChartTooltipProps
  extends TooltipProps<ValueType, NameType> {
  showAvg?: boolean;
  showRule?: boolean;
}

const AnalysisBarChartTooltip = ({
  active,
  payload,
  showAvg = true,
  showRule = true,
}: AnalysisBarChartTooltipProps) => {
  if (active && payload && payload.length) {
    const barData: ExtendedBarChartItem = payload[0].payload;
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#000',
          padding: '3px 6px 6px',
          borderRadius: '4px',
          color: '#fff',
        }}
      >
        <span
          className="text-sm"
          style={{
            fontWeight: 500,
            marginBottom: '4px',
            marginRight: '4px',
            textTransform: 'capitalize',
          }}
        >
          {createLegendLabel(barData.color, barData.rangeLabel, {})}
        </span>{' '}
        {showRule && barData.valueRule && (
          <span className="text-sm" style={{ opacity: 0.85 }}>
            {makeRangeStringByColoringRule(barData.valueRule)}
          </span>
        )}
        <table
          border={0}
          cellSpacing={0}
          cellPadding={0}
          style={{ width: '100%' }}
        >
          <tbody>
            <tr>
              <td style={{ paddingRight: '8px' }}>Number of POIs</td>
              <td style={{ textAlign: 'right' }}>{barData?.value}</td>
            </tr>
            {showAvg && (
              <tr>
                <td style={{ paddingRight: '8px' }}>Average value</td>
                <td style={{ textAlign: 'right' }}>
                  {barData.avg !== null ? shortenNumber(barData.avg) : '-'}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
  return null;
};

export { AnalysisBarChartTooltip };
