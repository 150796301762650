import { App as AntdApp, ConfigProvider } from 'antd';
import enUs from 'antd/lib/locale/en_US';
import { RouterProvider } from 'react-router-dom';

import router from './router';

const App = () => {
  return (
    <ConfigProvider
      locale={enUs}
      theme={{
        token: {
          colorText: '#1e2027',
          colorPrimary: '#3371B2',
          colorLink: '#3371B2',
          fontFamily: "'Roboto', sans-serif",
          fontWeightStrong: 500,
          borderRadius: 4,
          controlHeight: 44,
          fontSizeHeading1: 32,
          fontSizeHeading2: 20,
          fontSizeHeading3: 18,
          colorBorder: '#e5ecf5',
          colorBgTextHover: '#fafafa',
          colorBgTextActive: '#fafafa',
        },
        components: {
          Button: {
            controlHeight: 30,
            paddingContentHorizontal: 26,
            paddingContentVertical: 0,
            colorPrimaryBgHover: '#81b9f3',
            colorPrimaryActive: '#193858',
            lineHeight: 1,
            defaultColor: '#3371b2',
          },
          Collapse: {
            borderRadius: 4,
          },
          Select: {
            borderRadius: 0,
          },
          Input: {
            borderRadius: 0,
            paddingBlock: 10,
            paddingBlockSM: 10,
            paddingBlockLG: 10,
            hoverBorderColor: '#1e2027',
            activeBorderColor: '#1e2027',
          },
          InputNumber: {
            borderRadius: 0,
          },
          DatePicker: {
            borderRadius: 0,
            colorTextDisabled: '#8692a2',
          },
          Menu: {
            itemColor: '#3371b2',
            itemHoverColor: '#3371b2',
          },
          Tooltip: {
            controlHeight: 32,
          },
          Badge: {
            dotSize: 7,
          },
          Radio: {
            colorBorder: '#9EA8B7',
            radioSize: 14,
            dotSize: 10,
          },
          Alert: {
            defaultPadding: '10px 12px',
          },
        },
      }}
    >
      <AntdApp>
        <RouterProvider router={router} />
      </AntdApp>
    </ConfigProvider>
  );
};

export default App;
