import { notification } from 'antd';
import isEqual from 'lodash-es/isEqual';
import union from 'lodash-es/union';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { SCENARIOS } from '../config';
import analysisStore from '../store/analysis';
import appStore from '../store/app';
import chartsStore from '../store/charts';
import filterStore from '../store/filter';
import mapStore from '../store/map';
import myPoiStore from '../store/myPoi';
import {
  generateFilterValuesForAnalysis,
  getNonCriteriaFilterIds,
  mergeFilterValues,
} from '../utils/filterUtils';

import { useAnalysisApi } from './useAnalysisApi';
import { useMyPoi } from './useMyPoi';
import { usePrevious } from './usePrevious';

import type { UIAnalysisCriteria } from '../domain/criteria';
import type { FilterItem, PoiAnalysisApiKpi } from '../interfaces';

export function useAnalysis(criteria: UIAnalysisCriteria) {
  const previousCriteria = usePrevious(criteria);
  useMyPoi();

  const { scenario, isReady } = appStore;
  if (!scenario) throw new Error('Scenario is undefined');

  const scenarioTitle = scenario && SCENARIOS[scenario].title;
  const { isPaused, coordinates } = myPoiStore;
  const { isMyPoi, previousAnalysisExtent } = analysisStore;

  const { analysisService, abort } = useAnalysisApi({});

  const isAllowedToExecute = useMemo(
    () => analysisService.isAllowedToExecute(criteria),
    [criteria, analysisService],
  );

  const stopAnalysis = useCallback(() => {
    abort();
    analysisService.clear(analysisStore);
    analysisStore.setPreviousAnalysisExtent(null);

    mapStore.setNeedAnalysisFit(false);
    appStore.setIsLoading(false);
    myPoiStore.setPaused(false);
  }, [abort, analysisService]);

  const onAnyCriteriaChanged = useCallback(
    (newCriteria: UIAnalysisCriteria) => {
      filterStore.resetFilterValues();
      chartsStore.clearCharts();
      stopAnalysis();
      analysisStore.setCriteria(newCriteria);
    },
    [stopAnalysis],
  );

  const runAnalysis = useCallback(async () => {
    if (!isAllowedToExecute) {
      return;
    }
    if (isMyPoi && coordinates.length === 0) {
      notification.warning({
        duration: 1000 * 5,
        placement: 'top',
        message: 'Place markers on the map',
      });
      return;
    }
    stopAnalysis();
    mapStore.setNeedAnalysisFit(true);
    myPoiStore.setPaused(true);
    appStore.setIsLoading(true);

    try {
      const extent = criteria.useExtent ? mapStore.extent : null;
      const requestCriteria = analysisService.createCriteria({
        ...criteria,
        kpi: union(criteria.kpi, filterStore.filterValueIds),
        extent,
        coordinates,
      });
      const resp = await analysisService.loadAnalysedData(
        requestCriteria,
        analysisStore,
      );
      analysisStore.setPreviousAnalysisExtent(extent);

      const respPoi = resp && resp.poi;
      const respKpi = resp && resp.kpi;
      if (respPoi && respKpi && respKpi.length > 0) {
        const updatedFilterValue = generateFilterValuesForAnalysis(
          filterStore.filterValues,
          respKpi,
          criteria.kpi,
        );

        filterStore.resetFilterValues();
        filterStore.addFilterValues(updatedFilterValue);
      }
    } catch (e) {
      notification.error({
        duration: 1000 * 5,
        placement: 'top',
        message: (e as Error).message,
      });
    } finally {
      appStore.setIsLoading(false);
    }
  }, [
    isMyPoi,
    coordinates,
    stopAnalysis,
    criteria,
    isAllowedToExecute,
    analysisService,
  ]);

  // additional analysis. Extend poi data with new kpi values
  const onNewFilterAdded = useCallback(
    async (newKPIs: string[]) => {
      const newKpiDomainRanges = newKPIs.reduce((acc, newKPI) => {
        if (newKPI in analysisStore.poiKpiDomainRanges)
          return [...acc, analysisStore.poiKpiDomainRanges[newKPI]];
        return acc;
      }, [] as PoiAnalysisApiKpi[]);

      const noDomainRangeKpis = newKPIs.filter(
        (kpi) => !(kpi in analysisStore.poiKpiDomainRanges),
      );
      if (noDomainRangeKpis.length > 0) {
        appStore.setIsLoading(true);
        try {
          const requestCriteriaToExtend = analysisService.createCriteria({
            ...analysisStore.criteria,
            // poiKpis may differ from criteria.kpi because of filters
            kpi: analysisStore.poiKpis,
            coordinates,
            extent: previousAnalysisExtent,
          });

          const resp = await analysisService.extendAnalysedDataByKPI({
            newKPIs: noDomainRangeKpis,
            criteriaToExtend: requestCriteriaToExtend,
            poiToExtend: analysisStore.poi,
            poiStore: analysisStore,
          });
          if (resp && resp.kpi)
            resp.kpi.forEach((respKpi) => newKpiDomainRanges.push(respKpi));
        } catch (e) {
          notification.error({
            duration: 1000 * 5,
            placement: 'top',
            message: (e as Error).message,
          });
        } finally {
          appStore.setIsLoading(false);
        }
      }

      // update filterValues with domain ranges of new kpi
      const filterValuesToUpdate = mergeFilterValues(
        filterStore.filterValues,
        generateFilterValuesForAnalysis(
          filterStore.filterValues,
          newKpiDomainRanges,
          criteria.kpi,
        ),
      );
      filterStore.changeFilterValues(filterValuesToUpdate);
    },
    [criteria, previousAnalysisExtent, coordinates, analysisService],
  );

  // This effect clears the map of analysis result points when switching back to edit mode while working with myPoi
  useEffect(() => {
    if (!isPaused) {
      stopAnalysis();
    }
  }, [stopAnalysis, isPaused]);

  useEffect(() => {
    if (previousCriteria && !isEqual(previousCriteria, criteria)) {
      onAnyCriteriaChanged(criteria);
    }
  }, [criteria, onAnyCriteriaChanged, previousCriteria]);

  useEffect(() => {
    const unbindAdd = filterStore.onAdd((newFilterValues: FilterItem[]) => {
      onNewFilterAdded(newFilterValues.map((filterValue) => filterValue.id));
    });

    const unbindRemove = filterStore.onRemove(
      (filterValuesToRemove: FilterItem[]) => {
        const nonCriteriaFilterValueIdsToRemove: string[] =
          getNonCriteriaFilterIds(filterValuesToRemove);
        if (nonCriteriaFilterValueIdsToRemove.length > 0)
          // remove unused data from analysisStore.poi
          analysisService.removeKPIFromAnalysedData({
            poiToReduce: analysisStore.poi,
            kpisToRemove: nonCriteriaFilterValueIdsToRemove,
            currentKPIs: analysisStore.poiKpis,
            poiStore: analysisStore,
          });
      },
    );

    return () => {
      unbindAdd();
      unbindRemove();
    };
  }, [onNewFilterAdded, analysisService]);

  useEffect(() => {
    return () => {
      stopAnalysis();
      filterStore.resetFilterValues();
      chartsStore.clearCharts();
    };
  }, [stopAnalysis]);

  const launchedAtMount = useRef(false);
  // Try to run analysis if all required criteria are initialized from app start
  useEffect(() => {
    if (isReady && !launchedAtMount.current) {
      launchedAtMount.current = true;

      // This workaround ensures the analysis runs at start-up with the correct extent.
      // Ensures analysis at start-up uses the saved map extent to prevent inconsistencies due to potential map shifts after analysis.
      if (previousAnalysisExtent) {
        mapStore.setExtent(previousAnalysisExtent);
      }

      runAnalysis();
    }
  }, [runAnalysis, previousAnalysisExtent, isReady]);

  return {
    abort,
    runAnalysis,
    stopAnalysis,
    isAllowedToExecute,
    onNewFilterAdded,
    scenarioTitle,
  };
}
