import { fetchNgwLayerItems } from '@nextgis/ngw-kit';

import { POI_POINT_LAYER_RESOURCE } from '../config';

import connector from './connector';

import type { PoiCategoryItem, PoiProperties } from '../interfaces';
import type { Point, Polygon } from 'geojson';

const getPoiCategories = async (
  signal?: AbortSignal,
): Promise<PoiCategoryItem[]> => {
  const resp = await Promise.all(
    [POI_POINT_LAYER_RESOURCE].map((resource) =>
      connector.getResourceIdOrFail(resource).then((resourceId) =>
        fetchNgwLayerItems<Point | Polygon, PoiProperties>({
          connector,
          resourceId,
          signal,
          fields: ['TYPE', 'SUBTYPE'],
          geom: false,
          extensions: false,
          limit: Infinity,
        }),
      ),
    ),
  );
  const categoriesHash: Record<string, PoiCategoryItem> = {};
  const items = resp.flat();
  for (const item of items) {
    const { TYPE: type, SUBTYPE: subtype } = item.fields;
    const existCategory: PoiCategoryItem = categoriesHash[type] || {
      label: type || '',
      value: type || '',
      children: [],
    };
    if (
      subtype &&
      existCategory.children &&
      !existCategory.children.includes(subtype)
    ) {
      existCategory.children.push(subtype || null);
    }
    categoriesHash[type] = existCategory;
  }

  const categories = Object.values(categoriesHash)
    .sort((a, b) => {
      return a.label.localeCompare(b.label);
    })
    .map((c) => {
      return c;
    });
  return categories;
};

export { getPoiCategories };
