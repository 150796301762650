import { makeAutoObservable } from 'mobx';

import { getQueryParams } from '../utils/getQueryParams';

import type { RegistrableStore } from './interface';

export class ManagerStore {
  registeredStores: RegistrableStore[] = [];

  constructor() {
    makeAutoObservable(this, {
      registeredStores: false,
    });
  }

  get serializableParams() {
    return this.registeredStores.map((x) => x.serializableParams).flat();
  }

  get queryParams() {
    return getQueryParams(this.serializableParams);
  }

  register<T extends RegistrableStore>(store: T): T {
    this.registeredStores.push(store);
    return store;
  }
}

export default new ManagerStore();
