import { createElement } from 'react';
import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';

import { BASE_PATH } from '../config';
import { AnalysisView } from '../views/AnalysisView';
import { PrintView } from '../views/PrintView';
import Root from '../views/Root';

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Root />,
      children: [
        {
          path: '/',
          element: <Navigate to="/poi-analysis" replace />,
        },
        {
          path: '/:scenario',
          element: createElement(() => <Outlet></Outlet>),
          children: [
            {
              path: '',
              element: <AnalysisView />,
            },
            {
              path: 'print',
              element: <PrintView />,
            },
          ],
        },
      ],
    },
  ],
  { basename: BASE_PATH },
);

export default router;
