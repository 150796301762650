import { type CSSProperties, useEffect, useState } from 'react';

export function InlineSVG({
  url,
  style,
  currentColor,
}: {
  url: string;
  currentColor?: boolean;
  style?: CSSProperties;
}) {
  const [svgContent, setSvgContent] = useState('');

  useEffect(() => {
    fetch(url)
      .then((response) => response.text())
      .then((content) => {
        const contentToSet = currentColor
          ? content.replace(/fill="[^"]*"/g, 'fill="currentColor"')
          : content;
        setSvgContent(contentToSet);
      })
      .catch((error) => console.error('SVG loading error:', error));
  }, [currentColor, url]);

  return (
    <span style={style} dangerouslySetInnerHTML={{ __html: svgContent }} />
  );
}
