import { evaluate } from '@nextgis/expression';
import { fetchNgwLayerFeatures } from '@nextgis/ngw-kit';

import connector from '../api/connector';
import { MY_POI_VALUE, POI_POINT_LAYER_RESOURCE } from '../config';
import myPoiApi from '../store/myPoi';

import { chunkedPromiseExecutor } from './chunkedPromiseExecutor';
import { createFeatureProperties } from './featureProperties';

import type {
  ComparisonConfigItem,
  PoiItem,
  PoiItemWithComparison,
  PoiProperties,
} from '../interfaces';
import type { Expression } from '@nextgis/expression';
import type { Feature, FeatureCollection, Point } from 'geojson';

interface PoiFeatureCollectionProps {
  poiIds: number[];
  signal?: AbortSignal;
  filters?: Expression;
  poi: (PoiItem | PoiItemWithComparison)[];
  poiKpis: string[];
  isMyPoi: boolean;
  comparisonConfig: ComparisonConfigItem;
}

function createCustomFeaturesFromCoordinates(
  coordinates: number[][],
): Feature<Point, PoiProperties>[] {
  return coordinates.map((coord, i) => ({
    type: 'Feature',
    id: i,
    properties: {
      fid: i,
      NAME: `Custom #${i}`,
      TYPE: MY_POI_VALUE,
      SUBTYPE: null,
    },
    geometry: {
      type: 'Point',
      coordinates: coord,
    },
  }));
}

export async function getPoiFeatureCollection({
  poi,
  signal,
  poiIds,
  filters,
  poiKpis,
  isMyPoi,
  comparisonConfig,
}: PoiFeatureCollectionProps): Promise<
  FeatureCollection<Point, PoiProperties>
> {
  const resourceId = await connector.getResourceIdOrFail(
    POI_POINT_LAYER_RESOURCE,
  );

  let allFeatures: Feature<Point, PoiProperties>[] = [];

  if (!poiIds.length) {
    return {
      type: 'FeatureCollection',
      features: [],
    };
  }

  if (isMyPoi && myPoiApi.coordinates) {
    const coordinates: number[][] = [];
    if (poiIds) {
      for (const index of poiIds) {
        const coord = myPoiApi.coordinates[index];
        if (coord) {
          coordinates.push(coord);
        }
      }
    }
    allFeatures = createCustomFeaturesFromCoordinates(myPoiApi.coordinates);
  } else {
    try {
      allFeatures = await chunkedPromiseExecutor<Feature<Point, PoiProperties>>(
        {
          ids: [...poiIds].sort(),
          promiseFunction: (idChunk) => {
            return fetchNgwLayerFeatures({
              connector,
              resourceId,
              filters: [['id', 'in', idChunk]],
              signal,
              cache: true,
            });
          },
          signal,
        },
      );
    } catch (e) {
      if ((e as Error).message !== 'Aborted') {
        throw e;
      }
    }
  }

  const filteredFeatures = allFeatures.flat().filter((feature) => {
    const poiExist = poi.find((p) => p[0] === feature.id);
    feature.properties = {
      ...feature.properties,
      ...createFeatureProperties({
        id: Number(feature.id),
        poi: poiExist,
        kpis: poiKpis,
        comparisonConfig,
      }),
    };
    return filters ? evaluate(filters, feature.properties) : true;
  });

  return {
    type: 'FeatureCollection',
    features: filteredFeatures,
  };
}
