import { ExportSidebar } from '../components/toolSidebars/ExportSidebar';
import { ShareSidebar } from '../components/toolSidebars/ShareSidebar';

import type { ReactNode } from 'react';

const TOOL_KEYS = ['share', 'export'];
const TOOL_COMPONENTS: Record<string, ReactNode> = {
  share: <ShareSidebar />,
  export: <ExportSidebar />,
};

const useTools = (toolValue: string | undefined) => {
  const currentToolKey = toolValue;
  const toolComponent: ReactNode | undefined =
    currentToolKey && TOOL_COMPONENTS[currentToolKey];

  return { toolComponent, currentToolKey };
};
export { useTools, TOOL_KEYS };
