import { Button, Checkbox, Divider, Form, Segmented, Space } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';

import { PeakHoursControl } from '../../components/ PeakHoursControl';
import { BufferInput } from '../../components/BufferInput';
import { AppDateRangePicker } from '../../components/shared/AppDateRangePicker';
import { PoiFormItem } from '../../components/shared/PoiFormItem';
import {
  DISPLAY_MODES,
  MIDNIGHT,
  SECOND_BEFORE_MIDNIGHT,
  TIME_FORMAT,
  USER_TIMEZONE,
} from '../../config';
import { useAnalysis } from '../../hooks/useAnalysis';
import { usePeakHours } from '../../hooks/usePeakHours';
import analysisStore from '../../store/analysis';
import appStore from '../../store/app';
import {
  convertLocalHourRangesToUtcStrings,
  getTimezoneWithOffset,
} from '../../utils/datetime';
import { MapSidebar } from '../shared/MapSidebar';

import type { DateRange, DisplayMode } from '../../interfaces';

export const AnalysisForm = observer(
  ({ kpi, children }: { kpi: string[]; children: React.ReactNode }) => {
    const {
      scenario,
      bufferConfig,
      displayMode,
      localeMinDate,
      localeMaxDate,
    } = appStore;

    const { criteria: critInit } = analysisStore;
    const [timeRange, setTimeRange] = useState<DateRange | undefined>(
      critInit.time_range,
    );

    const defaultTimeRange = critInit.time_range
      ? critInit.peak_hours.map((range) => {
          if (range) {
            return range.map((t) => {
              if (t === SECOND_BEFORE_MIDNIGHT)
                return dayjs.utc(MIDNIGHT, `${TIME_FORMAT}`).tz(USER_TIMEZONE);
              return dayjs.utc(t, `${TIME_FORMAT}`).tz(USER_TIMEZONE);
            });
          }
        })
      : [];

    const {
      peakHours,
      setPeakHours,
      notNullPeakHours,
      peakHoursString,
      isPeakHoursActive,
    } = usePeakHours({ defaultValue: defaultTimeRange, timeRange });
    const [poiCategory, setPoiCategory] = useState<string | null>(
      critInit.poiCategory,
    );
    const [poiSubcategory, setPoiSubcategory] = useState<string[]>(
      critInit.poiSubcategory,
    );

    const [buffer, setBuffer] = useState<number | undefined>(
      () => critInit.buffer,
    );
    const [useExtent, setUseExtent] = useState<boolean>(
      () => critInit.useExtent,
    );

    const criteria = useMemo(
      () => ({
        kpi,
        poiCategory,
        poiSubcategory,
        buffer,
        time_range: timeRange && [timeRange[0], timeRange[1]],
        peak_hours: convertLocalHourRangesToUtcStrings(
          notNullPeakHours,
          TIME_FORMAT,
        ),
        useExtent,
      }),
      [
        buffer,
        kpi,
        notNullPeakHours,
        poiCategory,
        poiSubcategory,
        timeRange,
        useExtent,
      ],
    );

    const { runAnalysis, isAllowedToExecute, scenarioTitle } =
      useAnalysis(criteria);

    return (
      scenario && (
        <MapSidebar title={scenarioTitle || ''}>
          <Form layout="vertical">
            <Form.Item>
              <AppDateRangePicker
                value={timeRange}
                format="YYYY-MM-DD"
                allowedRange={[localeMinDate, localeMaxDate]}
                onChange={setTimeRange}
                renderFooter={
                  <>
                    <Divider style={{ margin: '12px 0' }} />
                    <PeakHoursControl
                      values={peakHours}
                      onChange={setPeakHours}
                      isDisabled={!isPeakHoursActive}
                    />
                    <div
                      className="text-xs text-muted"
                      style={{ marginTop: '8px' }}
                    >
                      {getTimezoneWithOffset(USER_TIMEZONE)}
                    </div>
                  </>
                }
              />
              {peakHoursString.length > 0 && (
                <div className="text-muted" style={{ marginTop: '2px' }}>
                  {peakHoursString}
                </div>
              )}
            </Form.Item>
            {children}
            <PoiFormItem
              selectedCategory={poiCategory}
              selectedSubcategory={poiSubcategory}
              onChange={({ newCategory, newSubcategory }) => {
                setPoiCategory(newCategory);
                setPoiSubcategory(newSubcategory);
              }}
            />
            <Form.Item label="POI buffer radius">
              <BufferInput
                value={buffer || 0}
                onChange={setBuffer}
                items={bufferConfig}
              />
            </Form.Item>
            <Form.Item>
              <Checkbox
                checked={useExtent}
                onChange={(e) => setUseExtent(e.target.checked)}
              >
                Use map extent
              </Checkbox>
            </Form.Item>
            <Space>
              <Button
                disabled={!isAllowedToExecute}
                type="primary"
                onClick={() => {
                  runAnalysis();
                }}
              >
                Run Analysis
              </Button>

              <Segmented
                value={displayMode}
                size="small"
                onChange={(e) => {
                  appStore.setDisplayMode(e as DisplayMode);
                }}
                options={DISPLAY_MODES}
              />
            </Space>
          </Form>
        </MapSidebar>
      )
    );
  },
);
