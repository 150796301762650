import { Drawer } from 'antd';

import type { ComparisonConfigItem, KpiConfigItem } from '../../interfaces';

interface ChartInfoDrawerProps {
  isOpen: boolean;
  chartConfig: KpiConfigItem | ComparisonConfigItem;
  onClose: () => void;
}

const ChartInfoDrawer = ({
  isOpen,
  chartConfig,
  onClose,
}: ChartInfoDrawerProps) => {
  return (
    <Drawer
      open={isOpen}
      title={chartConfig.labelDetailed}
      placement="right"
      onClose={onClose}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: chartConfig.description,
        }}
        style={{ lineHeight: '1.4' }}
      ></div>
    </Drawer>
  );
};

export { ChartInfoDrawer };
