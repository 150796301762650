import { useCallback, useEffect, useState } from 'react';

import analysisStore from '../store/analysis';
import appStore from '../store/app';
import chartsStore from '../store/charts';
import { createKpiFilter } from '../utils/createKpiFilter';
import { getKpiPaint } from '../utils/getKpiPaint';
import { getPoiFeatureCollection } from '../utils/getPoiFeatureCollection';

import { useAbortController } from './useAbortController';

import type { PoiProperties } from '../interfaces';
import type { Feature, FeatureCollection, Point } from 'geojson';

export function usePoiFeatureCollection() {
  const { poi, isMyPoi, filteredPoiIds } = analysisStore;
  const { poiKpis } = analysisStore;
  const { activeChartId, selectedBars } = chartsStore;
  const { comparisonConfig } = appStore;

  const [features, setFeatures] = useState<Feature<Point, PoiProperties>[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { makeSignal, abort } = useAbortController();

  const getColorExpression = useCallback(
    () => getKpiPaint({ kpi: poiKpis, activeChartId }),
    [activeChartId, poiKpis],
  );

  const getFeatureCollection = useCallback(async (): Promise<
    FeatureCollection<Point, PoiProperties> | undefined
  > => {
    abort();
    if (filteredPoiIds.length) {
      const filters = createKpiFilter(selectedBars);

      setIsLoading(true);
      try {
        const collection = await getPoiFeatureCollection({
          poi,
          poiIds: filteredPoiIds,
          signal: makeSignal(),
          filters,
          poiKpis,
          isMyPoi: isMyPoi,
          comparisonConfig,
        });
        setFeatures(collection.features);
        return collection;
      } catch {
        return undefined;
      } finally {
        setIsLoading(false);
      }
    }
  }, [
    poi,
    abort,
    poiKpis,
    isMyPoi,
    makeSignal,
    selectedBars,
    filteredPoiIds,
    comparisonConfig,
  ]);

  useEffect(() => {
    return abort;
  }, [abort]);

  return {
    getColorExpression,
    getFeatureCollection,
    features,
    isLoading,
  };
}
