import { mdiCollapseAllOutline, mdiExpandAllOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Button, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';

import chartsStore from '../../../store/charts';

export const ToggleChartsButton = observer(() => {
  const { collapsedCharts, chartIds } = chartsStore;
  const allCollapsed = collapsedCharts.length === chartIds.length;

  const onClick = () => {
    chartsStore.setCollapsed(allCollapsed ? [] : [...chartIds]);
  };

  return (
    <Tooltip title={allCollapsed ? 'Expand all charts' : 'Collapse all charts'}>
      <Button
        onClick={onClick}
        icon={
          <Icon
            size="16px"
            path={allCollapsed ? mdiExpandAllOutline : mdiCollapseAllOutline}
          />
        }
      ></Button>
    </Tooltip>
  );
});
