import {
  createChart,
  updateChartRuleByBarSelection,
} from '../utils/chartUtils';
import { checkValueForRule } from '../utils/coloringRules';

import type {
  Chart,
  ChartRule,
  PoiItemCategoryObject,
  PoiItemValueObject,
  RuleExpression,
  SelectedBarChartItem,
} from '../interfaces';

interface UseDependentChartArgs {
  id: number | string;
  values: Record<string | number, unknown>[];
  chartRules: ChartRule[];
  allChartSelectedBars: SelectedBarChartItem[];
  targetKey?: 'categories' | 'values';
}

interface UseDependentChartResult {
  chart: Chart;
  selectedBarIds: (string | number)[];
  total: number;
  totalSelected: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const nullAndNumberCompareFunc = (a: any, b: any) => {
  if (a !== null && b !== null) return a - b;
  if (a === null && b !== null) return 1;
  if (a !== null && b === null) return -1;
  return 0;
};

export function useDependentChart({
  id,
  values,
  chartRules,
  allChartSelectedBars,
  targetKey,
}: UseDependentChartArgs): UseDependentChartResult {
  const selectedBarIds = allChartSelectedBars
    .filter((bar) => bar.chartId === id)
    .map((bar) => bar.id);

  const selectedBarsExceptCurrent = allChartSelectedBars.filter(
    (bar) => bar.chartId !== id,
  );

  const selectedBarRulesObject: Record<string | number, RuleExpression[]> =
    selectedBarsExceptCurrent.reduce((acc, selectedBar) => {
      const currentRuleArray = (
        acc as Record<string | number, RuleExpression[]>
      )[selectedBar.chartId];
      if (currentRuleArray) {
        currentRuleArray.push(selectedBar.rule);
      } else {
        acc = { ...acc, [selectedBar.chartId]: [selectedBar.rule] };
      }
      return acc;
    }, {});

  const filteredValues = values.filter((valueObject) => {
    return Object.entries(selectedBarRulesObject).every(([chartId, rules]) => {
      return rules.some((rule) => {
        const objectToFilter: Record<string | number, unknown> = targetKey
          ? (valueObject[targetKey] as Record<string | number, unknown>)
          : valueObject;
        return checkValueForRule(
          objectToFilter[chartId] as number | null,
          rule,
        );
      });
    });
  });

  const chartRulesWithBarSelection = updateChartRuleByBarSelection(
    chartRules,
    selectedBarIds,
  );

  const chart = createChart({
    id,
    values: filteredValues as Record<
      string | number,
      number | null | PoiItemValueObject | PoiItemCategoryObject
    >[],
    targetKey,
    chartRules: chartRulesWithBarSelection,
    isSorted: false,
    sortingFunction: nullAndNumberCompareFunc,
    shouldSaveItems: true,
  });

  let total = 0;
  let totalSelected = 0;

  chart.bars.forEach((bar) => {
    total += bar.value;
    if (selectedBarIds.includes(bar.id)) totalSelected += bar.value;
  });

  return { chart, selectedBarIds, total, totalSelected };
}
