import { useCallback, useEffect, useState } from 'react';

import { NULL_LABEL } from '../config';
import analysisStore from '../store/analysis';
import appStore from '../store/app';
import geocoderStore from '../store/geocoder';
import mapStore from '../store/map';
import { addPoiLayers } from '../utils/addPoiLayers';
import { removePoiLayers } from '../utils/addPoiLayers';
import { getComparisonDataKeys } from '../utils/comparison';
import { getFeaturePropertiesForCaptions } from '../utils/featureProperties';

import { usePoiFeatureCollection } from './usePoiFeatureCollection';
import { useTableColumns } from './useTableColumns';

import type { KpiConfigItem, PoiPropertiesFeature } from '../interfaces';

export const usePoiLayer = () => {
  const { scenario } = appStore;
  const { ngwMap } = mapStore;
  const { poiKpis, isMyPoi } = analysisStore;
  const columns = useTableColumns({ hiddenFields: [] });
  const [poiCount, setPoiCount] = useState<number | undefined>(undefined);

  const { getFeatureCollection, getColorExpression } =
    usePoiFeatureCollection();

  const remove = useCallback(() => {
    if (ngwMap) {
      removePoiLayers(ngwMap);
      mapStore.setFeatureCount(undefined);
    }
  }, [ngwMap]);

  const createPropertyRowHtml = useCallback(
    (prop: string, featureProperties: Record<string, unknown>) => {
      const featurePropertiesForCaption =
        getFeaturePropertiesForCaptions(featureProperties);
      const label = columns.find((c) => c.dataIndex === prop)?.title;
      const value = featurePropertiesForCaption[prop] ?? NULL_LABEL;
      return `<tr style="line-height: 1.1;">
              <td style="vertical-align: top; padding: 3px 0;">${label}:</td>
              <td style="text-align:right; vertical-align: top; padding: 3px 0;">${value}</td>
            </tr>`;
    },
    [columns],
  );

  const createPopupContent = useCallback(
    ({ feature }: { feature: PoiPropertiesFeature }) => {
      const element = document.createElement('div');

      const updatePopupElement = () => {
        const { NAME, TYPE, COMMENTARY, ADDRESS } = feature.properties;
        const propertyRowsHtml = [
          ...poiKpis,
          ...(scenario === 'critical-areas'
            ? getComparisonDataKeys(
                poiKpis.map((kpi) => appStore.getConfigById(kpi)) as [
                  KpiConfigItem,
                  KpiConfigItem,
                ],
              )
            : []),
        ]
          .map((prop) => createPropertyRowHtml(prop, feature.properties))
          .join('');
        const title = [TYPE, NAME].filter(Boolean).join(': ');
        const adressNode = ADDRESS
          ? `<div class="text-muted" style="margin-top: 4px; margin-bottom: 8px;">${ADDRESS}</div>`
          : '';
        const commentaryNode = COMMENTARY
          ? ` <hr class="text-muted" style="margin: 8px 0; background-color: #f0f0f0"/>
          <div style="margin-top: 8px;">
          Commentary:<br>
          <div class="text-muted" style="margin-top: 4px;">${COMMENTARY}</div>
          </div>`
          : '';
        element.innerHTML = `
          <div style="font-size: 12px; line-height: 1.2;">
            <h4>${title}</h4>
            ${adressNode}
            <hr class="text-muted" style="margin: 8px 0; background-color: #f0f0f0"/>
            <table class="table-noborder" style="width: 100%;">
              <tbody>
                ${propertyRowsHtml}
              </tbody>
            </table>
            ${commentaryNode}
            </div>
          </div>
        `;
      };

      if (isMyPoi) {
        if (!feature.properties.ADDRESS) {
          feature.properties.ADDRESS = '...';
          updatePopupElement();
          geocoderStore
            .appendReversResult(feature.geometry.coordinates)
            .then((geocoderResult) => {
              feature.properties.ADDRESS = geocoderResult;
              updatePopupElement();
            })
            .catch(() => {
              updatePopupElement();
            });
        }
      } else {
        updatePopupElement();
      }

      return element;
    },
    [isMyPoi, poiKpis, createPropertyRowHtml, scenario],
  );

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      remove();
      if (ngwMap) {
        getFeatureCollection()
          .then((data) => {
            if (data) {
              const fit = mapStore.needAnalysisFit;
              mapStore.setNeedAnalysisFit(false);
              setPoiCount(data.features.length);
              mapStore.setFeatureCount(poiCount);
              addPoiLayers({
                colorExpression: getColorExpression(),
                ngwMap,
                data,
                fit,
                createPopupContent: ({ feature }) =>
                  createPopupContent({
                    feature: feature as PoiPropertiesFeature,
                  }),
              });
            }
          })
          .catch((er) => {
            if (er.name !== 'CancelError') {
              throw er;
            }
          });
      }
    }, 150);

    return () => {
      clearTimeout(delayDebounce);
    };
  }, [
    ngwMap,
    remove,
    getFeatureCollection,
    getColorExpression,
    createPopupContent,
    poiCount,
  ]);

  return { poiCount };
};
