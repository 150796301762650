interface ChunkedPromiseExecutorProps<T> {
  ids: number[];
  promiseFunction: (
    idChunk: number[],
    opt: { signal?: AbortSignal },
  ) => Promise<T[]>;
  chunkSize?: number;
  signal?: AbortSignal;
  maxConcurrent?: number;
}

export async function chunkedPromiseExecutor<T>({
  ids,
  promiseFunction,
  chunkSize = 100,
  maxConcurrent = 10,
  signal,
}: ChunkedPromiseExecutorProps<T>): Promise<T[]> {
  let results: T[] = [];

  const executeChunk = async (idChunk: number[]): Promise<T[]> => {
    if (signal?.aborted) {
      throw new Error('Aborted');
    }
    return promiseFunction(idChunk, { signal });
  };

  let running = 0;
  let currentIndex = 0;

  return new Promise((resolve, reject) => {
    const run = async () => {
      if (currentIndex >= ids.length) return;

      const currentChunk = ids.slice(currentIndex, currentIndex + chunkSize);
      currentIndex += chunkSize;

      running++;

      try {
        const result = await executeChunk(currentChunk);
        results = results.concat(result);
      } catch (error) {
        reject(error);
        return;
      }

      running--;
      if (currentIndex >= ids.length && running === 0) {
        resolve(results);
        return;
      }

      run();
    };

    for (
      let i = 0;
      i < Math.min(maxConcurrent, Math.ceil(ids.length / chunkSize));
      i++
    ) {
      run();
    }
  });
}
