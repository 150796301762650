interface SvgIconProps {
  svgContent: string;
  size: [width: number, height: number];
  color?: string;
  fillOpacity?: number;
  iconScaleFactor?: number;
}

export function createSvgIcon({
  svgContent,
  size,
  color = '#FF0000',
  fillOpacity = 0.5,
  iconScaleFactor = 0.65,
}: SvgIconProps): string {
  const [width, height] = size;

  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(svgContent, 'image/svg+xml');

  const svg = xmlDoc.getElementsByTagName('svg')[0];
  if (!svg) {
    throw new Error('Invalid SVG content');
  }

  const viewBox = svg.getAttribute('viewBox');
  const [viewBoxX, viewBoxY, viewBoxWidth, viewBoxHeight] = viewBox
    ? viewBox.split(' ').map(Number)
    : [0, 0, width, height];

  svg.setAttribute('width', `${width}px`);
  svg.setAttribute('height', `${height}px`);

  const scale = iconScaleFactor;
  const scaledWidth = viewBoxWidth * scale;
  const scaledHeight = viewBoxHeight * scale;
  const offsetX = (viewBoxWidth - scaledWidth) / 2;
  const offsetY = (viewBoxHeight - scaledHeight) / 2;
  for (const child of svg.children) {
    child.setAttribute(
      'transform',
      `translate(${offsetX}, ${offsetY}) scale(${scale})`,
    );
  }

  const circle = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'circle',
  );
  circle.setAttribute('cx', (viewBoxX + viewBoxWidth / 2).toString());
  circle.setAttribute('cy', (viewBoxY + viewBoxHeight / 2).toString());
  circle.setAttribute(
    'r',
    (Math.min(viewBoxWidth, viewBoxHeight) / 2).toString(),
  );
  circle.setAttribute('style', `fill: ${color}; fill-opacity: ${fillOpacity};`);

  svg.insertBefore(circle, svg.firstChild);

  return new XMLSerializer().serializeToString(svg);
}
