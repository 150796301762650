// import { useState } from 'react';
import { Button, Form, Input, Tooltip } from 'antd';
import { type BaseSyntheticEvent, useEffect } from 'react';

import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import { useSharedLink } from '../../hooks/useSharedLink';
import { MapSidebar } from '../shared/MapSidebar';

interface ShareBlockProps {
  value: string;
}

const { TextArea } = Input;

const onFocus = (e: BaseSyntheticEvent) => {
  e.target.select();
};

const ShareBlock = ({ value }: ShareBlockProps) => {
  const { isCopied, setIsCopied, copyToClipboard } = useCopyToClipboard(value);
  const isTooltipOpen = isCopied;
  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setIsCopied(false), 1000);
    }
  }, [isCopied, setIsCopied]);
  return (
    <>
      <TextArea
        autoSize={{ minRows: 2 }}
        value={value}
        onFocus={onFocus}
      ></TextArea>
      <Tooltip title={'Copied'} open={isTooltipOpen} placement="right">
        <Button
          type="primary"
          style={{ marginTop: '12px' }}
          onClick={copyToClipboard}
        >
          Copy link
        </Button>
      </Tooltip>
    </>
  );
};

export const ShareSidebar = () => {
  const { linkToMap, linkToTable } = useSharedLink();

  return (
    <MapSidebar title="Share">
      <Form layout="vertical">
        <Form.Item label="Link to the map">
          <ShareBlock value={linkToMap}></ShareBlock>
        </Form.Item>
        <Form.Item label="Link to the table">
          <ShareBlock value={linkToTable}></ShareBlock>
        </Form.Item>
      </Form>
    </MapSidebar>
  );
};
