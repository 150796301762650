import { ClearOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Flex, Modal, Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';

import analysisStore from '../../store/analysis';
import appStore from '../../store/app';
import filterStore from '../../store/filter';
import mapStore from '../../store/map';
import { ControlMultiplicator } from '../shared/ControlMultiplicator';

import { FilterControl } from './FilterControl';
import { FilterStyleTag } from './FilterStyleTag';
import { FilterTag } from './FilterTag';

import type { FilterItem, SelectOption } from '../../interfaces';
import type { ControlMultiplicatorOnChangeDetails } from '../shared/ControlMultiplicator';

const appFilterCSS = {
  padding: '6px 22px',
  backgroundColor: 'white',
};
const appFilterValuesCSS = {
  flexGrow: 1,
  marginTop: '-8px',
};
const appFilterCaptionCSS = { marginTop: '5px', marginRight: '16px' };

const AppFilter = observer(() => {
  const { scenario, isLoading } = appStore;
  const { filterValues } = filterStore;
  const { poi, criteria } = analysisStore;
  const { featureCount } = mapStore;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [localFilterValues, setLocalFilterValues] =
    useState<(FilterItem | undefined)[]>(filterValues);

  useEffect(() => {
    setLocalFilterValues(filterValues);
  }, [filterValues]);

  const markKpiOptionsAsDisabled = useCallback(
    (kpiOptions: SelectOption[]) =>
      kpiOptions.map((kpiOption) => ({
        ...kpiOption,
        disabled: localFilterValues
          .map((filterValue) => filterValue?.id)
          .includes(kpiOption.value),
      })),
    [localFilterValues],
  );

  const filterKpiOptions = useMemo(
    () =>
      scenario === 'critical-areas'
        ? markKpiOptionsAsDisabled(
            [
              ...appStore.kpiSelectItems,
              ...appStore.competitorsKpiSelectItems,
            ].filter((kpiOption) => criteria.kpi.includes(kpiOption.value)),
          )
        : markKpiOptionsAsDisabled(appStore.kpiSelectItems),
    [criteria.kpi, scenario, markKpiOptionsAsDisabled],
  );

  const onChange = (
    updatedFilterValues: (FilterItem | undefined)[],
    onChangeDetails: ControlMultiplicatorOnChangeDetails<
      FilterItem | undefined
    >,
  ) => {
    setLocalFilterValues(updatedFilterValues);

    if (onChangeDetails.changed.length > 0) {
      const newFilterValues: FilterItem[] = [];
      const existedFilterValues: FilterItem[] = [];

      updatedFilterValues.forEach((filterValue) => {
        if (filterValue) {
          if (filterStore.filterValueIds.includes(filterValue.id))
            existedFilterValues.push(filterValue);
          else newFilterValues.push(filterValue);
        }
      });
      if (existedFilterValues.length > 0) {
        filterStore.changeFilterValues(existedFilterValues);
      }
      if (newFilterValues.length > 0) {
        filterStore.addFilterValues(newFilterValues);
      }
    }
    if (onChangeDetails.removed.length > 0) {
      const valuesToRemove = Object.values(
        Object.fromEntries(onChangeDetails.removed),
      ).filter((value) => value !== undefined) as FilterItem[];
      filterStore.removeFilterValues(valuesToRemove);
    }
  };

  const addFilterValue = () => {
    setLocalFilterValues([...localFilterValues, undefined]);
  };

  const clearFilter = () => {
    filterStore.removeFilterValues(filterValues);
  };

  return (
    <>
      <Flex className="app-filter" style={appFilterCSS}>
        <div className="app-filter__caption nowrap" style={appFilterCaptionCSS}>
          Filter{' '}
          <Tag style={{ marginRight: '4px' }}>
            {featureCount && featureCount !== poi.length ? (
              <>
                {featureCount}
                <span className="text-muted">/{poi.length}</span>
              </>
            ) : (
              poi.length
            )}
          </Tag>
          :
        </div>
        <div className="app-filter__values" style={appFilterValuesCSS}>
          {filterValues.length === 0 && (
            <FilterStyleTag onClick={() => setIsModalOpen(true)}>
              <span style={{ color: '#3371B2' }}>
                <PlusOutlined /> ADD FILTER
              </span>
            </FilterStyleTag>
          )}
          {filterValues.map((filterValue) => (
            <FilterTag
              key={filterValue.id}
              value={filterValue}
              isCLosable
              onClose={() => filterStore.removeFilterValues([filterValue])}
              onClick={() => setIsModalOpen(true)}
            />
          ))}
        </div>
        <div className="app-filter__actions">
          <Button type="primary" onClick={() => setIsModalOpen(true)}>
            Edit filter
          </Button>
        </div>
      </Flex>
      <Modal
        title={
          <Flex align="center" style={{ marginTop: '-8px' }}>
            <h3>Filter</h3>
            {localFilterValues && localFilterValues.length > 0 && (
              <Button
                type="link"
                onClick={clearFilter}
                className="font-weight-regular"
                icon={<ClearOutlined />}
                style={{
                  paddingLeft: '4px',
                  paddingRight: '4px',
                  marginLeft: '8px',
                }}
              >
                Clear filter
              </Button>
            )}
          </Flex>
        }
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        okText="Apply filter"
        footer={() => <></>}
        width={750}
      >
        <Divider style={{ margin: '4px 0 24px' }} />
        {localFilterValues && localFilterValues.length > 0 ? (
          <Flex style={{ marginBottom: '8px' }} gap={16}>
            <div style={{ width: '210px' }}>
              <strong>KPI</strong>
            </div>
            <div>
              <strong>Value</strong>
            </div>
            {filterKpiOptions.length > localFilterValues.length && (
              <div style={{ flexGrow: 1, textAlign: 'right' }}>
                <Button
                  type="link"
                  onClick={addFilterValue}
                  className="font-weight-regular"
                  icon={<PlusOutlined />}
                  style={{
                    paddingLeft: '8px',
                    paddingRight: '8px',
                  }}
                >
                  Add another filter
                </Button>
              </div>
            )}
          </Flex>
        ) : (
          <Button
            type="primary"
            onClick={addFilterValue}
            className="font-weight-regular"
            icon={<PlusOutlined />}
            style={{
              paddingLeft: '8px',
              paddingRight: '8px',
            }}
          >
            Add filter
          </Button>
        )}
        <ControlMultiplicator<FilterItem | undefined>
          values={localFilterValues}
          controlValueProp="value"
          controlValuePropKey="id"
          controlOnChangeProp="onChange"
          isLastRemovable={true}
          onChange={onChange}
          style={{ width: '100%' }}
        >
          <FilterControl
            options={filterKpiOptions}
            isAnalysisLoading={isLoading}
          />
        </ControlMultiplicator>
      </Modal>
    </>
  );
});

export { AppFilter };
