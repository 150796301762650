import {
  COMPARISON_DELTA_ID,
  COMPARISON_ID,
  POI_PROP_ID_FIELD,
} from '../config';

import { getComparisonLabel } from './comparison';

import type {
  ComparisonConfigItem,
  PoiItem,
  PoiItemWithComparison,
  PoiProperties,
} from '../interfaces';

type PoiValueProperties = Omit<PoiProperties, 'NAME' | 'TYPE' | 'SUBTYPE'>;

const getFeatureValues = (
  kpis: string[],
  poi: PoiItem | PoiItemWithComparison | undefined,
  comparisonConfig: ComparisonConfigItem,
) => {
  const poiValues = Object.fromEntries(
    kpis.map((key, index) => [key, poi && poi[1][index]]),
  );
  return {
    ...poiValues,
    [COMPARISON_ID]: getComparisonLabel(poi && poi[3], comparisonConfig),
    [COMPARISON_DELTA_ID]: poi && poi[4],
  };
  return poiValues;
};

const createFeatureProperties = ({
  id,
  poi,
  kpis,
  comparisonConfig,
}: {
  id: number;
  poi: PoiItem | PoiItemWithComparison | undefined;
  kpis: string[];
  comparisonConfig: ComparisonConfigItem;
}) => {
  const properties: PoiValueProperties = {};
  kpis.forEach((key, index) => {
    const val = poi && poi[2][index];
    properties[key] = val !== undefined ? val : null;
    properties.__sort = val ? 1 : 0;
  });
  properties[POI_PROP_ID_FIELD] = Number(id);
  properties[COMPARISON_ID] = poi && poi[3];
  properties[COMPARISON_DELTA_ID] = poi && poi[4];
  properties.values = getFeatureValues(kpis, poi, comparisonConfig);
  return properties;
};

const getFeaturePropertiesForCaptions = (
  featureProperties: Record<string, unknown>,
): Record<string, unknown> => {
  const { values, ...featurePropertiesNoValues } = featureProperties;
  const feautureValues =
    typeof values === 'string' ? JSON.parse(values as string) : values;
  return {
    ...featurePropertiesNoValues,
    ...feautureValues,
  };
};

export {
  getFeatureValues,
  createFeatureProperties,
  getFeaturePropertiesForCaptions,
};
