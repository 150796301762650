import { Button, Flex } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { createGlobalStyle, styled } from 'styled-components';

import { ViaviMap } from '../components/ViaviMap';
import { AnalysisSummary } from '../components/analysis/AnalysisSummary';
import { ChartList } from '../components/charts/ChartList';
import { AppSelect } from '../components/shared/AppSelect';
import { useAnalysis } from '../hooks/useAnalysis';
import { useAnalysisSummary } from '../hooks/useAnalysisSummary';
import { useSharedLink } from '../hooks/useSharedLink';
import analysisStore from '../store/analysis';
import appStore from '../store/app';
import { getDateRangeString } from '../utils/datetime';

import type { SelectOption } from '../interfaces';

const GlobalStyle = createGlobalStyle<{ mode: string }>`
  @page {
    size: ${(props) => `A4 ${props.mode}`};
  }
`;

const PrintLayout = styled.div`
  margin: 32px auto;
  padding-bottom: 80px;

  &.portrait {
    width: 21cm;
  }

  &.landscape {
    width: 29.7cm;
  }

  @media print {
    margin: 0;
    padding: 0;

    .noprint {
      display: none;
    }
    .print {
      display: block;
    }
    summary.maplibregl-ctrl-attrib-button {
      display: none;
    }
  }
`;

const PrintSheet = styled.div`
  display: block;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.04);
  background-color: #e4edf2;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
  padding: 32px 23px 23px;
  margin: 0 auto;
  box-sizing: border-box;

  &.portrait {
    width: 21cm;
  }

  &.landscape {
    width: 29.7cm;
  }
`;

const PrintSheetInner = styled.div`
  &.landscape {
    display: flex;
    gap: 12px;
  }
`;

// 340px
const ViaviMapForPrint = styled(ViaviMap)`
  flex-grow: 1;
  height: 230px;

  &.landscape {
    flex-grow: 0 !important;
    width: 60% !important;
    margin-bottom: 12px;
  }
`;

const ChartListForPrint = styled(ChartList)`
  max-height: none;
  margin-left: -6px;
  margin-right: -6px;

  @-moz-document url-prefix() {
    .chart-list {
      scrollbar-gutter: auto;
    }
  }

  .chart-list__item {
    display: inline-block;
    vertical-align: top;
    break-inside: avoid;
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom: 0 !important;
    padding-top: 12px;
  }

  .ant-collapse-expand-icon {
    display: none !important;
  }

  .ant-collapse-header-text,
  .ant-radio-wrapper {
    width: 100%;
  }

  .ant-radio-wrapper > span {
    overflow: hidden;
  }

  .ant-radio {
    display: none;
    & + span {
      padding-inline-start: 0;
      padding-inline-end: 0;
    }
  }

  .analysis-bar-chart-card__info-icon {
    display: none;
  }

  /* .analysis-bar-chart-card__title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  } */
`;

const AnalysisSummaryForPrint = styled(AnalysisSummary)`
  &.portrait {
    margin-top: 12px;
  }
  &.landscape {
    flex-grow: 1;
  }
`;

const PRINT_MODE_OPTIONS: SelectOption[] = [
  { label: 'Portrait', value: 'portrait' },
  { label: 'Landscape', value: 'landscape' },
];

const PrintView = observer(() => {
  const { criteria, status } = analysisStore;
  const { isLoading } = appStore;
  const { scenarioTitle } = useAnalysis(criteria);
  const { summaryValues } = useAnalysisSummary({ criteria });

  const [printMode, setPrintMode] = useState<'portrait' | 'landscape'>(
    'portrait',
  );

  const dateString =
    criteria.time_range && getDateRangeString(criteria.time_range, 'YYYY-MM-D');
  const { linkToMap } = useSharedLink();

  return (
    <>
      <GlobalStyle mode={printMode}></GlobalStyle>
      <PrintLayout className={`print-layout ${printMode}`}>
        <Flex
          className="noprint"
          justify="space-between"
          align="center"
          style={{ marginBottom: '23px' }}
        >
          <h2>Print report</h2>
          <AppSelect
            options={PRINT_MODE_OPTIONS}
            onChange={setPrintMode}
            value={printMode}
            style={{ width: '200px' }}
          ></AppSelect>
          <Button
            type="primary"
            onClick={() => {
              window.print();
            }}
          >
            Print
          </Button>
        </Flex>
        <PrintSheet className={`A4 print ${printMode}`}>
          <header>
            <Flex justify="space-between">
              <h3 style={{ marginBottom: '8px' }}>{scenarioTitle}</h3>
              {dateString}
            </Flex>
          </header>
          <PrintSheetInner className={printMode}>
            <ViaviMapForPrint
              className={printMode}
              isLoading={isLoading}
              mapOptions={{ preserveDrawingBuffer: true }}
              showGeocoder={false}
            />
            <AnalysisSummaryForPrint
              value={summaryValues}
              className={printMode}
            />
          </PrintSheetInner>
          {status === 'success' && (
            <ChartListForPrint
              className={printMode}
              itemWidth={
                printMode === 'portrait'
                  ? 'calc(33.333% - 12px)'
                  : 'calc(25% - 12px)'
              }
            />
          )}
          <footer className="text-sm" style={{ marginTop: '24px' }}>
            <a
              href={linkToMap}
              target="_blank"
              rel="noreferrer"
              style={{ wordBreak: 'break-all' }}
            >
              Link to the map
            </a>
            {/* Report date: {dayjs().format('YYYY-MM-DD')} */}
          </footer>
        </PrintSheet>
      </PrintLayout>
    </>
  );
});

export { PrintView };
