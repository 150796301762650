import { CloseOutlined } from '@ant-design/icons';
import { Button, Flex, Space } from 'antd';
import { cloneElement, useCallback } from 'react';

import type { ReactElement } from 'react';
import type { CSSProperties } from 'styled-components';

interface ControlMultiplicatorOnChangeDetails<T> {
  changed: [number, T][];
  removed: [number, T][];
}

interface ControlMultiplicatorProps<T> {
  title?: string;
  children: ReactElement;
  values: T[];
  controlValueProp: string;
  controlValuePropKey?: string;
  isLastRemovable?: boolean;
  isRemovable?: boolean;
  onChange: (
    newValues: T[],
    details: ControlMultiplicatorOnChangeDetails<T>,
  ) => void;
  controlOnChangeProp: string;
  style?: CSSProperties;
}

const generateKey = (value: unknown): string => {
  return btoa(JSON.stringify(value));
};

const ControlMultiplicator = <T,>({
  children,
  values,
  controlValueProp,
  controlValuePropKey,
  onChange,
  controlOnChangeProp,
  isLastRemovable = false,
  isRemovable = true,
  style,
}: ControlMultiplicatorProps<T>) => {
  const control = children;
  const removeControl = (indexToRemove: number) => {
    onChange(
      values.filter((_, index) => index !== indexToRemove),
      {
        changed: [],
        removed: [[indexToRemove, values[indexToRemove]]],
      },
    );
  };
  const isRemoveButtonShown =
    isRemovable && (values.length > 1 || isLastRemovable);

  const onControlChange = useCallback(
    (newValue: T, indexToUpdate: number) => {
      onChange(
        values.map((value, index) =>
          index === indexToUpdate ? newValue : value,
        ),
        {
          changed: [[indexToUpdate, values[indexToUpdate]]],
          removed: [],
        },
      );
    },
    [values, onChange],
  );

  return (
    <Space
      className="control-multiplicator"
      direction="vertical"
      onClick={(e) => e.stopPropagation()}
      style={style}
    >
      {values.map((value, index) => (
        <Flex
          key={generateKey(
            controlValuePropKey && value
              ? (value as never)[controlValuePropKey]
              : index,
          )}
          align="center"
          gap="small"
        >
          {cloneElement(control, {
            [controlValueProp]: value,
            [controlOnChangeProp]: (newValue: T) =>
              onControlChange(newValue, index),
            [controlOnChangeProp]: (newValue: T) =>
              onControlChange(newValue, index),
            style: { flexGrow: 1 },
          })}
          {isRemoveButtonShown && (
            <Button
              icon={<CloseOutlined />}
              type="text"
              onClick={() => removeControl(index)}
            />
          )}
        </Flex>
      ))}
    </Space>
  );
};

export { ControlMultiplicator };
export type { ControlMultiplicatorOnChangeDetails };
