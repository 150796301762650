import { Slider } from 'antd';
import { observer } from 'mobx-react-lite';

// import appStore from '../store/app';
// import analysisStore from '../store/analysis';

import type { SliderMarks } from 'antd/es/slider';

interface BufferInputProps {
  value: number;
  items: number[];
  onChange: (newValue: number) => void;
}

export const BufferInput = observer(
  ({ value, items, onChange }: BufferInputProps) => {
    // const { bufferConfig } = appStore;
    // const { criteria } = analysisStore;
    // const { buffer } = criteria;
    const marks: SliderMarks = Object.fromEntries(
      items.map((x) => [x, `${x}m`]),
    );

    return (
      <Slider
        defaultValue={value}
        marks={marks}
        step={null}
        onChange={onChange}
        min={items[0]}
        max={items[items.length - 1]}
        tooltip={{ formatter: null }}
      />
    );
  },
);
