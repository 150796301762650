import dayjs from 'dayjs';

import { createRequestCriteria } from '../domain/criteria';
import { mergePoiWith, removeValuesFromPoi } from '../domain/poiList';

import type { AnalysisDataFetcher } from '../api/analysisApi';
import type {
  AnalysisCriteria,
  CreateRequestCriteriaProps,
  SystemPoiCriteria,
  UIAnalysisCriteria,
  UserPoiCriteria,
} from '../domain/criteria';
import type { PoiItem } from '../interfaces';
import type { AnalysisStore } from '../store/analysis';

export class AnalysisService {
  request: AnalysisDataFetcher;

  constructor(request: AnalysisDataFetcher) {
    this.request = request;
  }

  createCriteria(props: CreateRequestCriteriaProps) {
    return createRequestCriteria(props);
  }

  // load POI List with analysed KPI value and save them to store
  async loadAnalysedData(
    criteria: AnalysisCriteria<SystemPoiCriteria | UserPoiCriteria>,
    poiStore: AnalysisStore,
  ) {
    try {
      poiStore.setStatus('loading');
      const response = await this.request(criteria);
      const poi = response && response.poi;
      const kpi = response && response.kpi;
      if (poi && poi.length === 0) {
        throw new Error('POI could not be found for this criteria.');
      }
      if (poi && kpi) {
        const kpiList = kpi.map((kpi) => kpi.identity);
        if (kpi.length > 0) poiStore.setPoiKpiDomainRanges(kpi);
        poiStore.savePoiData(poi, kpiList);
        poiStore.setStatus('success');
      }
      return response;
    } catch (error) {
      poiStore.setStatus('failed');
      throw error;
    }
  }

  async extendAnalysedDataByKPI({
    newKPIs,
    criteriaToExtend,
    poiToExtend,
    poiStore,
  }: {
    newKPIs: string[];
    criteriaToExtend: AnalysisCriteria<SystemPoiCriteria | UserPoiCriteria>;
    poiToExtend: PoiItem[];
    poiStore: AnalysisStore;
  }) {
    try {
      poiStore.setStatus('loading');
      const response = await this.request({
        ...criteriaToExtend,
        kpi: newKPIs,
      });
      const poi = response && response.poi;
      const kpi = response && response.kpi;
      if (poi && poi.length > 0) {
        const mergedKPIs = [...criteriaToExtend.kpi, ...newKPIs];
        const mergedPois = mergePoiWith(poiToExtend, poi);
        if (kpi && kpi.length > 0) poiStore.setPoiKpiDomainRanges(kpi);
        poiStore.savePoiData(mergedPois, mergedKPIs);
      }
      poiStore.setStatus('success');
      return response;
    } catch (error) {
      poiStore.setStatus('failed');
      throw error;
    }
  }

  removeKPIFromAnalysedData({
    currentKPIs,
    kpisToRemove,
    poiStore,
    poiToReduce,
  }: {
    poiToReduce: PoiItem[];
    kpisToRemove: string[];
    currentKPIs: string[];
    poiStore: AnalysisStore;
  }) {
    const kpisToRemoveIndexes: number[] = kpisToRemove.map((kpiToRemoveItem) =>
      currentKPIs.indexOf(kpiToRemoveItem),
    );
    const shrinkedPois = removeValuesFromPoi(poiToReduce, kpisToRemoveIndexes);
    const shrinkedKPIs = currentKPIs.filter(
      (kpi) => !kpisToRemove.includes(kpi),
    );
    poiStore.removePoiKpiDomainRanges(kpisToRemove);
    poiStore.savePoiData(shrinkedPois, shrinkedKPIs);
  }

  isAllowedToExecute(uiCriteria: UIAnalysisCriteria): boolean {
    const { time_range } = uiCriteria;
    return (
      !!time_range &&
      time_range.filter((t) => t && dayjs(t).isValid()).length === 2
    );
  }

  clear(store: AnalysisStore) {
    store.clearPoiData();
    store.clearPoiKpiDomainRanges();
    store.setStatus('idle');
  }
}
