import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { styled } from 'styled-components';

import { COMPARISON_ID } from '../../config';
import analysisStore from '../../store/analysis';
import appStore from '../../store/app';
import chartsStore from '../../store/charts';

import { AnalysisBarChartCard } from './AnalysisBarChartCard';
import { ChartInfoDrawer } from './ChartInfoDrawer';

import type { ComparisonConfigItem, KpiConfigItem } from '../../interfaces';
import type { CSSProperties } from 'styled-components';

const ChartListItem = styled.div<{ $width: string }>`
  margin-bottom: 8px;
  width: ${(props) => props.$width};
`;

const ChartList = observer(
  ({
    style,
    className,
    itemWidth,
  }: {
    style?: CSSProperties;
    className?: string;
    itemWidth?: string;
  }) => {
    const { scenario } = appStore;
    const { criteria } = analysisStore;
    const { chartIds, activeChartId } = chartsStore;
    useEffect(() => {
      const chartsToShow =
        scenario === 'critical-areas'
          ? [COMPARISON_ID, ...criteria.kpi]
          : criteria.kpi;
      chartsStore.setChartIds(chartsToShow);
      if (activeChartId === null) chartsStore.setActiveChartId(chartsToShow[0]);
    }, [criteria.kpi, scenario, activeChartId]);

    const [drawerConfig, setDrawerConfig] = useState<
      KpiConfigItem | ComparisonConfigItem | null
    >(null);

    const showChartInfoDrawer = (
      config: KpiConfigItem | ComparisonConfigItem,
    ) => {
      setDrawerConfig(config);
    };

    const hideChartInfoDrawer = () => {
      setDrawerConfig(null);
    };

    return (
      <>
        <div className={`chart-list ${className || ''}`} style={style}>
          {chartIds.map((chartId) => {
            const config = appStore.getConfigById(chartId);
            if (config)
              return (
                <ChartListItem
                  className="chart-list__item"
                  key={chartId}
                  $width={itemWidth || 'auto'}
                >
                  <AnalysisBarChartCard
                    config={config} // move to card
                    values={analysisStore.filteredPoiObjects}
                    onInfoClick={showChartInfoDrawer}
                  />
                </ChartListItem>
              );
          })}
        </div>
        {drawerConfig && (
          <ChartInfoDrawer
            isOpen={!!drawerConfig}
            chartConfig={drawerConfig}
            onClose={hideChartInfoDrawer}
          />
        )}
      </>
    );
  },
);

export { ChartList };
