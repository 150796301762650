import { Flex } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { ViaviMap } from '../components/ViaviMap';
import { ViaviTable } from '../components/ViaviTable/ViaviTable';
import { ChartList } from '../components/charts/ChartList';
import { AppFilter } from '../components/filter/AppFilter';
import { AppLayout } from '../components/layout/AppLayout';
import { usePrevious } from '../hooks/usePrevious';
import analysisStore from '../store/analysis';
import appStore from '../store/app';

import { CriticalAreaSidebar } from './CriticalAreasSidebar';
import { PoiAnalysisSidebar } from './PoiAnalysisSidebar';

import type { ReactNode } from 'react';

const SIDEBAR_PANELS: Record<string, ReactNode> = {
  'poi-analysis': <PoiAnalysisSidebar />,
  'critical-areas': <CriticalAreaSidebar />,
};

const AnalysisView = observer(() => {
  const { isLoading, displayMode, scenario } = appStore;
  const previousScenario = usePrevious(scenario);
  const [sidebarPanel, setSidebarPanel] = useState<ReactNode>();

  useEffect(() => {
    if (previousScenario && scenario !== previousScenario) {
      analysisStore.resetCriteria();
      analysisStore.clearPoiData();
      analysisStore.clearPoiKpiDomainRanges();
    }

    setSidebarPanel(scenario && SIDEBAR_PANELS[scenario]);
  }, [scenario, previousScenario]);

  return (
    <AppLayout renderSidebar={sidebarPanel}>
      <Flex vertical style={{ width: '100%' }}>
        {analysisStore.poi.length > 0 && <AppFilter />}
        {displayMode === 'map' ? (
          <ViaviMap
            isLoading={isLoading}
            style={{
              flexGrow: 1,
            }}
          >
            {analysisStore.poi.length > 0 && (
              <ChartList key="charts" itemWidth={'330px'} />
            )}
          </ViaviMap>
        ) : (
          <ViaviTable
            isLoading={isLoading}
            style={{
              flexGrow: 1,
            }}
          ></ViaviTable>
        )}
      </Flex>
    </AppLayout>
  );
});

export { AnalysisView };
