import { observer } from 'mobx-react-lite';
import { useRef } from 'react';

import { POI_PROP_ID_FIELD } from '../../config';
import { useTable } from '../../hooks/useTable';
import { FullHeightTable } from '../FullHeightTable';

import { useTableHeight } from './hooks/useTableHeight';

import type { ViaviDisplayComponentProps } from '../../interfaces';
import type { Table } from 'antd';

import './ViaviTable.css';

export type AntdColumns = NonNullable<Parameters<typeof Table>[0]['columns']>;

export const ViaviTable = observer(
  ({ isLoading, style }: ViaviDisplayComponentProps) => {
    const { columns, data } = useTable({ immediate: true });

    const ref = useRef<HTMLDivElement>(null);
    const tableHeight = useTableHeight({ ref });

    return (
      <div className="viavi-table-wrapper" ref={ref}>
        <FullHeightTable
          loading={isLoading}
          style={style}
          columns={columns as AntdColumns}
          dataSource={data}
          virtual
          scroll={{ y: tableHeight }}
          rowKey={POI_PROP_ID_FIELD}
          pagination={false}
        ></FullHeightTable>
      </div>
    );
  },
);
