import { keyBy } from 'lodash-es';

import type { FilterItem, PoiAnalysisApiKpi } from '../interfaces';
import type { Expression } from '@nextgis/expression';

const filterItemToExpression = (
  filterItem: FilterItem,
  shouldNullInclude: boolean,
): Expression | undefined => {
  const expression: Expression | undefined = filterItem.range && [
    'all',
    ['>=', ['get', filterItem.id], filterItem.range[0]],
    ['<=', ['get', filterItem.id], filterItem.range[1]],
  ];
  if (!expression) return undefined;

  return shouldNullInclude
    ? filterItem.range && [
        'any',
        ['==', ['get', filterItem.id], null],
        expression,
      ]
    : expression;
};

const roundRange = (range: [number, number]): [number, number] => {
  return [
    Math.floor(range[0]),
    range[1] % 1 === 0 ? range[1] : Math.floor(range[1]) + 1,
  ];
};

const getNonCriteriaFilterIds = (filterValues: FilterItem[]): string[] =>
  // TODO: remove isFromAnalysisCriteria, pass criteria kpi as parameter
  filterValues.reduce<string[]>((acc, filterValue) => {
    if (!filterValue.isFromAnalysisCriteria) return [...acc, filterValue.id];
    return acc;
  }, []);

const generateFilterValuesForAnalysis = (
  previousFilterValues: FilterItem[],
  kpiItems: PoiAnalysisApiKpi[],
  criteriaKpis: string[],
): FilterItem[] => {
  return kpiItems.reduce<FilterItem[]>((acc, kpi) => {
    const previousFiltersObject =
      previousFilterValues.length > 0
        ? keyBy(previousFilterValues, 'id')
        : null;
    const prevFilterRange =
      previousFiltersObject &&
      previousFiltersObject[kpi.identity] &&
      previousFiltersObject[kpi.identity].range
        ? previousFiltersObject[kpi.identity].range
        : undefined;
    const isFromAnalysisCriteria = criteriaKpis.includes(kpi.identity);
    if (kpi.min !== null && kpi.max !== null && kpi.min !== kpi.max) {
      return [
        ...acc,
        {
          id: kpi.identity,
          range: prevFilterRange || roundRange([kpi.min, kpi.max]),
          domainRange: roundRange([kpi.min, kpi.max]),
          isFromAnalysisCriteria,
        },
      ];
    }
    return [
      ...acc,
      {
        id: kpi.identity,
        range: undefined,
        domainRange: undefined,
        isFromAnalysisCriteria,
      },
    ];
  }, []);
};

//updating source values by new values. absolutely newValues will not be added
const mergeFilterValues = (
  sourceValues: FilterItem[],
  newValues: FilterItem[],
) =>
  sourceValues.map((sourceValue) => {
    const index = newValues.findIndex(
      (newValue) => sourceValue.id === newValue.id,
    );
    if (index === -1) return sourceValue;
    return newValues[index];
  });

const isFilterItemAnalysed = (filterValue: FilterItem | undefined) =>
  filterValue && 'domainRange' in filterValue;

export {
  filterItemToExpression,
  roundRange,
  getNonCriteriaFilterIds,
  generateFilterValuesForAnalysis,
  mergeFilterValues,
  isFilterItemAnalysed,
};
