import { getIcon } from '@nextgis/icons';
import { Form, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';

// import poiStore from '../../store/poi';
import { useAbortController } from '../../hooks/useAbortController';
import appStore from '../../store/app';
import { getPoiLogos } from '../../utils/poiLogo';

import { AppSelect } from './AppSelect';

import type { PoiLogo, SelectOption } from '../../interfaces';

type CatOptProps = SelectOption & {
  find: (logo: PoiLogo, val: unknown) => boolean;
};

export interface PoiFormItemProps {
  selectedCategory: string | null;
  selectedSubcategory: string[];
  onChange: ({
    newCategory,
    newSubcategory,
  }: {
    newCategory: string | null;
    newSubcategory: string[];
  }) => void;
}

export const PoiFormItem = observer(
  ({ selectedCategory, selectedSubcategory, onChange }: PoiFormItemProps) => {
    const { isLoading, poiCategories } = appStore;

    const { makeSignal } = useAbortController();
    const [poiLogos, setPoiLogos] = useState<PoiLogo[]>([]);

    // TODO: move it to analyse store
    // useEffect(() => {
    //   if (onSubcategoryChange) onSubcategoryChange([]);
    // }, [selectedCategory, onSubcategoryChange]);

    useEffect(() => {
      getPoiLogos({ signal: makeSignal() }).then(setPoiLogos);
    }, [makeSignal]);

    const poiSubcategories = useMemo(() => {
      return (
        selectedCategory &&
        poiCategories &&
        poiCategories
          .find((x) => x.value === selectedCategory)
          ?.children?.map((subcatecory) => ({
            label: subcatecory || '',
            value: subcatecory || '',
          }))
      );
    }, [poiCategories, selectedCategory]);

    const createCatOptions = (
      cat: SelectOption[],
      find: (logo: PoiLogo, val: unknown) => boolean,
    ) => cat.map((p) => createCatOption({ ...p, find }));

    const createCatOption = useCallback(
      ({ label, value, find }: CatOptProps) => {
        const Logo = () => {
          const exist = poiLogos.find((l) => find(l, value));
          if (exist) {
            const svg = getIcon({ svg: exist.logo }).svg;
            return (
              <span
                className="svg-container"
                style={{
                  width: '12px',
                  height: '12px',
                  marginRight: '3px',
                }}
                dangerouslySetInnerHTML={{ __html: svg ? svg.outerHTML : '' }}
              ></span>
            );
          }
        };

        return (
          <Select.Option key={value} value={value} label={label}>
            <Logo />
            <span>{label}</span>
          </Select.Option>
        );
      },
      [poiLogos],
    );

    return (
      <>
        <Form.Item label="POI category">
          <AppSelect
            value={selectedCategory}
            disabled={isLoading}
            onChange={(newCategory) => {
              onChange({
                newCategory,
                newSubcategory: [],
              });
            }}
            placeholder="Please select POI category"
            showSearch
            allowClear
          >
            {createCatOptions(poiCategories, (l, v) => l.type === v)}
          </AppSelect>
        </Form.Item>
        {poiSubcategories && poiSubcategories.length > 0 && (
          <Form.Item label="POI subcategory">
            <AppSelect
              mode="multiple"
              disabled={!selectedCategory}
              value={selectedSubcategory}
              onChange={(newSubcategory) =>
                onChange({ newCategory: selectedCategory, newSubcategory })
              }
              placeholder="Please select POI subcategory"
              showSearch
              allowClear
            >
              {createCatOptions(
                poiSubcategories,
                (l, v) => l.type === selectedCategory && l.subtype === v,
              )}
            </AppSelect>
          </Form.Item>
        )}
      </>
    );
  },
);
