import { Spin } from 'antd';

import { SpinIndicator } from './SpinIndicator';

import type { CSSProperties } from 'react';

export function ViaviLoader() {
  const loaderCss: CSSProperties = {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 21, 41, 0.6)',
    zIndex: 10,
  };

  return (
    <div className="viavi-map__loader" style={loaderCss}>
      <Spin className="light" size="large" indicator={<SpinIndicator />}></Spin>
    </div>
  );
}
