import type { ComparisonConfigItem, KpiConfigItem } from '../interfaces';
import type { Expression } from '@nextgis/expression';

export function createKpiPaint(config: KpiConfigItem | ComparisonConfigItem) {
  const cases: (Expression | string)[] = [];
  const name = config.identity;
  let fallback: string = 'gray';
  for (const item of config.categoryColoringRules) {
    if (item.rule[0] === 'isBetween') {
      const [from, to] = item.rule[1] as [number, number];
      if (!isFinite(from)) {
        cases.push(['<', ['get', name], to]);
      } else if (!isFinite(to)) {
        cases.push(['>', ['get', name], from]);
      } else {
        cases.push([
          'all',
          ['>=', ['get', name], from],
          ['<=', ['get', name], to],
        ]);
      }
      cases.push(item.color);
    } else if (item.rule[0] === 'isSameAs') {
      const val = item.rule[1] as number;
      cases.push(['==', ['get', name], val]);
      cases.push(item.color);
      if (val === null) {
        fallback = item.color;
      }
    }
  }
  const expression: Expression = [
    'case',
    ['==', ['typeof', ['get', name]], 'null'],
    fallback,
    ...cases,
    fallback,
  ];

  return expression;
}
