import { Table } from 'antd';

import './FullHeightTable.less';

type TableProps = Parameters<typeof Table>[0];

export interface FullHeightTableProps extends TableProps {
  parentHeight?: boolean;
}

export function FullHeightTable({
  className,
  parentHeight = true,
  pagination = false,
  ...props
}: FullHeightTableProps) {
  if (parentHeight) {
    className = (className ? className.split(' ') : [])
      .concat('ant-table-parent-height')
      .join(' ');
  }

  const tableProps: TableProps = { ...props, pagination, className };

  return <Table {...tableProps} />;
}
