import { Form } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { AnalysisForm } from '../components/analysis/AnalysisSidebar';
import { AppSelect } from '../components/shared/AppSelect';
import analysisStore from '../store/analysis';
import appStore from '../store/app';

export const PoiAnalysisSidebar = observer(() => {
  const { kpiSelectItems } = appStore;
  const { criteria } = analysisStore;
  const [kpi, setKpi] = useState<string[]>(() => criteria.kpi || []);

  return (
    <AnalysisForm kpi={kpi}>
      <Form.Item label="KPI">
        <AppSelect
          mode="multiple"
          value={kpi}
          onChange={setKpi}
          options={kpiSelectItems}
          placeholder="Select KPI"
          showSearch
          allowClear
        />
      </Form.Item>
    </AnalysisForm>
  );
});
