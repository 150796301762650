import { MY_POI_VALUE } from '../config';

import type { DateRange, ValidationStatus } from '../interfaces';
import type { LngLatArray } from '@nextgis/utils';
import type { Position } from 'geojson';

export interface SystemPoiCriteria {
  collection: 'system';
  category: string | null;
  subcategory: string[];
}
export interface UserPoiCriteria {
  collection: 'user';
  points: Position[];
}

export interface AnalysisCriteria<P> {
  kpi: string[];
  poi: P;
  buffer?: number;
  time_range?: DateRange;
  peak_hours: Array<string[] | undefined>;
  extent: LngLatArray | null;
  compare?: boolean;
}

export interface UIAnalysisCriteria {
  kpi: string[];
  poiCategory: string | null;
  poiSubcategory: string[];
  buffer?: number;
  time_range?: DateRange;
  peak_hours: Array<string[] | undefined>;
  useExtent: boolean;
}

export interface CreateRequestCriteriaProps extends UIAnalysisCriteria {
  extent: LngLatArray | null;
  coordinates: Position[] | null;
}

const createSystemPoiCriteria = (
  poiCategory: string,
  poiSubcategory: string[],
): SystemPoiCriteria => {
  return {
    collection: 'system',
    category: poiCategory,
    subcategory: poiSubcategory,
  };
};

const createUserPoiCriteria = (coordiantes: Position[]): UserPoiCriteria => {
  return {
    collection: 'user',
    points: coordiantes,
  };
};

const validateUICriteria = ({
  kpi,
  poiCategory,
  useExtent,
  extent,
  coordinates,
}: CreateRequestCriteriaProps): ValidationStatus => {
  const errors = [];
  if (kpi.length === 0) errors.push('KPI list is empty');
  if (!poiCategory) errors.push('POI Category is undefined');
  if (
    poiCategory === MY_POI_VALUE &&
    (!coordinates || coordinates.length === 0)
  )
    errors.push('My POI list is empty');
  if (useExtent && !extent) errors.push('Extent is undefined');
  if (errors.length > 0) return { isValid: false, errors };
  return { isValid: true };
};

const createRequestCriteria = (
  props: CreateRequestCriteriaProps,
): AnalysisCriteria<SystemPoiCriteria | UserPoiCriteria> => {
  const { errors } = validateUICriteria(props);
  if (errors) throw new Error(errors.join(', '));
  const {
    kpi,
    poiCategory,
    poiSubcategory,
    buffer,
    time_range,
    peak_hours,
    extent,
    coordinates,
  } = props;
  const isMyPoi = poiCategory === MY_POI_VALUE;
  const commonParams = {
    kpi,
    buffer,
    time_range,
    peak_hours,
    extent,
  };

  return {
    ...commonParams,
    poi: isMyPoi
      ? createUserPoiCriteria(coordinates as Position[])
      : createSystemPoiCriteria(poiCategory as string, poiSubcategory),
  };
};

export {
  createRequestCriteria,
  createSystemPoiCriteria,
  createUserPoiCriteria,
  validateUICriteria,
};
