import { ClearOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import cn from 'classnames';
import { Marker } from 'maplibre-gl';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import CreateMyPoiCursor from '../../../assets/cursors/create-my-poi.svg';
import DeleteMyPoiCursor from '../../../assets/cursors/delete-my-poi.svg';
import MyPoiDeleteIcon from '../../../assets/icons/myPoiDelete.svg';
import MyPoiEditIcon from '../../../assets/icons/myPoiEdit.svg';
import MyPoiMarkerIcon from '../../../assets/poi_logos/mypoi.svg';
import { InlineSVG } from '../../../components/InlineSvg';
import myPoiStore from '../../../store/myPoi';

import type { NgwMap } from '../../../interfaces';
import type { Position } from 'geojson';
import type { MapMouseEvent } from 'maplibre-gl';

import './MyPoiMapDraw.css';

export interface DrawControlProps {
  ngwMap: NgwMap;
}

type Mode = 'draw' | 'delete' | 'none';

const setCursorStyle = ({
  map,
  mode = 'none',
}: {
  map: NgwMap['mapAdapter']['map'];
  mode?: Mode;
}) => {
  if (map) {
    map.getCanvas().style.cursor =
      mode === 'draw' ? `url(${CreateMyPoiCursor}) 10 10, auto` : '';
  }
};

export const MyPoiMapDraw = observer(({ ngwMap }: DrawControlProps) => {
  const map = useMemo(() => ngwMap.mapAdapter.map, [ngwMap]);

  const { isPaused, coordinates } = myPoiStore;

  const [mode, setMode] = useState<Mode>('draw');
  const modeRef = useRef<Mode>(mode);
  const markersRef = useRef<Marker[]>([]);

  const onMarkerClick = useCallback((marker: Marker) => {
    if (modeRef.current !== 'delete') return;

    const markerIndex = markersRef.current.indexOf(marker);
    if (markerIndex !== -1) {
      marker.remove();
      markersRef.current.splice(markerIndex, 1);
      const newCoords = [...myPoiStore.coordinates];
      newCoords.splice(markerIndex, 1);
      myPoiStore.setCoordinates(newCoords);
    }
  }, []);

  const createMarker = useCallback(
    (coord: Position) => {
      if (!map) return;

      const markerIcon = document.createElement('div');
      markerIcon.innerHTML = `<img src="${MyPoiMarkerIcon}">`;

      const marker = new Marker({ draggable: true, element: markerIcon })
        .setLngLat(coord as [number, number])
        .addTo(map);

      marker.on('dragstart', () => {
        map.getCanvas().style.cursor = 'none';
        marker.getElement().style.opacity = '0.5';
      });
      marker.on('dragend', () => {
        setCursorStyle({ map, mode: modeRef.current });
        marker.getElement().style.opacity = '1';
        const { lng, lat } = marker.getLngLat();
        const newCoords = [...myPoiStore.coordinates];
        const index = newCoords.findIndex(
          (c) => c[0] === coord[0] && c[1] === coord[1],
        );
        if (index !== -1) {
          newCoords.splice(index, 1, [lng, lat]);
        } else {
          newCoords.push([lng, lat]);
        }
        myPoiStore.setCoordinates(newCoords);
      });

      marker.getElement().addEventListener('click', () => {
        onMarkerClick(marker);
      });

      markersRef.current = [...markersRef.current, marker];
      return marker;
    },
    [map, onMarkerClick],
  );

  const clearAllMarkers = useCallback(() => {
    markersRef.current.forEach((marker) => marker.remove());
    markersRef.current = [];
  }, []);

  const onMapClick = useCallback(
    (e: MapMouseEvent) => {
      if (modeRef.current === 'draw') {
        const { lng, lat } = e.lngLat;
        const marker = createMarker([lng, lat]);
        if (marker) {
          myPoiStore.setCoordinates([...myPoiStore.coordinates, [lng, lat]]);
        }
      }
    },
    [createMarker],
  );

  const addMapClickListener = useCallback(() => {
    if (map) {
      map.on('click', onMapClick);
    }
  }, [map, onMapClick]);

  const removeMapClickListener = useCallback(() => {
    if (map) {
      map.off('click', onMapClick);
    }
  }, [map, onMapClick]);

  const cleanupMap = useCallback(() => {
    removeMapClickListener();
    clearAllMarkers();
  }, [removeMapClickListener, clearAllMarkers]);

  const setupMap = useCallback(() => {
    if (!isPaused) {
      cleanupMap();
      myPoiStore.coordinates.forEach((coord) => {
        createMarker(coord);
      });
      addMapClickListener();
    }
  }, [cleanupMap, addMapClickListener, createMarker, isPaused]);

  const restore = useCallback(() => {
    if (isPaused) {
      myPoiStore.setPaused(false);
      setupMap();
    }
  }, [setupMap, isPaused]);

  useEffect(() => {
    if (isPaused) {
      clearAllMarkers();
      setMode('none');
    } else if (!markersRef.current.length) {
      setupMap();
      if (modeRef.current === 'none') {
        setMode('draw');
      }
    }
  }, [clearAllMarkers, setupMap, isPaused]);

  useEffect(() => {
    modeRef.current = mode;
    if (!map) return;

    setCursorStyle({
      map,
      mode,
    });

    markersRef.current.forEach((marker) => {
      marker.getElement().style.cursor =
        mode === 'delete' ? `url(${DeleteMyPoiCursor}) 10 10, auto` : 'pointer';
    });

    markersRef.current.forEach((marker) => {
      marker.setDraggable(mode === 'draw');
    });
    return () => setCursorStyle({ map, mode: 'none' });
  }, [map, mode]);

  useEffect(() => {
    setupMap();
    return cleanupMap;
  }, [setupMap, cleanupMap]);

  return (
    <div className="my-poi-map-draw-control">
      <button
        className={cn('poi-btn poi-btn--draw', {
          active: mode === 'draw',
        })}
        onClick={() => {
          restore();
          setMode('draw');
        }}
      >
        <InlineSVG url={MyPoiEditIcon} />
      </button>
      <button
        className={cn('poi-btn poi-btn--delete', {
          active: mode === 'delete',
        })}
        onClick={() => {
          restore();
          setMode('delete');
        }}
      >
        <InlineSVG url={MyPoiDeleteIcon} />
        {mode === 'delete' && !!coordinates.length && (
          <div
            className="delete-all-block"
            onClick={(e) => {
              e.stopPropagation();
              clearAllMarkers();
              myPoiStore.setCoordinates([]);
              setMode('draw');
            }}
          >
            <Space>
              <ClearOutlined />
              Clear all
            </Space>
          </div>
        )}
      </button>
    </div>
  );
});
