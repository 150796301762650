import {
  BlockOutlined,
  EnvironmentFilled,
  ExportOutlined,
  PrinterOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useTools } from './useTools';

import type { SidebarValue, Tool } from '../interfaces';
import type { AppStore } from '../store/app';
import type { ManagerStore } from '../store/manager';

const useSidebarMenu = ({
  managerStore,
  appStore,
}: {
  managerStore: ManagerStore;
  appStore: AppStore;
}) => {
  const { serializableParams } = managerStore;
  const queryParams = serializableParams.filter(
    (x) => x.value !== undefined && x.value !== null,
  );
  const queryParamsString = queryParams
    .map((x) => `${x.key}=${x.serialize()}`)
    .join('&');

  const { pathname } = useLocation();
  const pathNameNormalized =
    pathname.slice(-1) === '/' ? pathname.slice(0, -1) : pathname;

  const { toolComponent, currentToolKey } = useTools(
    queryParams.find((x) => x.key === 'tool')?.value as string | undefined,
  );

  const mainMenuItems = [
    {
      key: '/poi-analysis',
      icon: <EnvironmentFilled />,
      label: <Link to="/poi-analysis">POI Analysis</Link>,
    },
    {
      key: '/critical-areas',
      icon: <BlockOutlined />,
      label: <Link to="/critical-areas">Critical Areas</Link>,
    },
  ];
  const [mainMenuValue, setMainMenuValue] = useState<SidebarValue | undefined>({
    key: pathNameNormalized,
    isActive: currentToolKey === undefined,
  });

  const updateMainMenuValue = (value: SidebarValue) => {
    setMainMenuValue(value);
    setToolMenuValue(undefined);
    appStore.setActiveTool(undefined);
  };

  const toolMenuItems = [
    {
      key: 'share',
      icon: <ShareAltOutlined />,
      label: <div>Share</div>,
    },
    {
      key: 'export',
      icon: <ExportOutlined />,
      label: <div>Export</div>,
    },
    {
      key: 'print',
      icon: <PrinterOutlined />,
      label: (
        <Link
          to={`${pathNameNormalized}/print?${queryParamsString}`}
          target="_blank"
        >
          Print
        </Link>
      ),
    },
  ];
  const [toolMenuValue, setToolMenuValue] = useState<SidebarValue | undefined>(
    currentToolKey === undefined
      ? currentToolKey
      : {
          key: currentToolKey,
          isActive: true,
        },
  );

  const updateToolMenuValue = (value: SidebarValue) => {
    if (value && value.key === 'print') return;
    if (value.isActive) {
      appStore.setActiveTool(value.key as Tool);
      setToolMenuValue(value);
      setMainMenuValue(
        mainMenuValue && {
          ...mainMenuValue,
          isActive: false,
        },
      );
    } else {
      appStore.setActiveTool(undefined);
      setToolMenuValue(undefined);
    }
  };

  const isSidebarMenuOpened =
    (!!mainMenuValue && mainMenuValue.isActive) ||
    (!!toolMenuValue && toolMenuValue.isActive);

  return {
    mainMenuItems,
    mainMenuValue,
    updateMainMenuValue,
    toolMenuItems,
    toolMenuValue,
    updateToolMenuValue,
    toolComponent,
    isSidebarMenuOpened,
  };
};

export { useSidebarMenu };
