import { Geocoder, NominatimProvider } from '@nextgis/geocoder';
import { Alert } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { ViaviLoader } from '../components/ViaviLoader';
import { NGW_URL } from '../config';
import appStore from '../store/app';
import geocoderStore from '../store/geocoder';
import managerStore from '../store/manager';

import type { Scenario } from '../interfaces';

const Root = observer(() => {
  const { isReady, isFailed, user, nominatimUrl } = appStore;
  const { queryParams } = managerStore;
  const [isReadyToRender, setIsReadyToRender] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (isReady) {
      const params = new URLSearchParams(window.location.search);
      managerStore.serializableParams.forEach((param) => {
        const value = params.get(param.key);
        if (value !== null) {
          param.deserialize(value);
        }
      });
    }
  }, [isReady]);

  useEffect(() => {
    if (isReady) {
      navigate(`?${queryParams}`, { replace: true });
      if (!isReadyToRender) setIsReadyToRender(true);
    }
  }, [navigate, queryParams, isReady, isReadyToRender]);

  const { scenario } = useParams();

  useEffect(() => {
    if (appStore.scenario !== scenario) {
      appStore.setScenario(scenario as Scenario);
    }
  }, [scenario]);

  useEffect(() => {
    const geocoder = new Geocoder({
      providers: [new NominatimProvider({ searchUrl: nominatimUrl })],
    });
    geocoderStore.setGeocoder(geocoder);
  }, [nominatimUrl]);

  if (isFailed) {
    if (!user) {
      window.open(`${NGW_URL}/login?next=viavi/${queryParams}`, '_self');
      console.log('no user set');
      return <>Login error</>;
    }
    return (
      <Alert
        message="Something went wrong. Try to reload the page."
        type="error"
        style={{ margin: '24px', display: 'inline-block' }}
      />
    );
  }

  if (!isReadyToRender) {
    return <ViaviLoader></ViaviLoader>;
  }

  return <Outlet></Outlet>;
});

export default Root;
