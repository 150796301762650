import { Col, Flex, Layout, Row } from 'antd';
import { observer } from 'mobx-react-lite';

import { AppSidebarMenu } from '../../components/layout/AppSidebarMenu';
import { useSidebarMenu } from '../../hooks/useSidebarMenu';
import appStore from '../../store/app';
import managerStore from '../../store/manager';

import type { ReactNode } from 'react';

const { Sider } = Layout;

interface AppSidebarProps {
  children?: ReactNode;
}
const AppSidebar = observer(({ children }: AppSidebarProps) => {
  const {
    mainMenuItems,
    mainMenuValue,
    toolMenuItems,
    toolMenuValue,
    updateToolMenuValue,
    updateMainMenuValue,
    toolComponent,
    isSidebarMenuOpened,
  } = useSidebarMenu({ managerStore, appStore });

  return (
    <Sider
      width={isSidebarMenuOpened ? 440 : 70}
      theme="light"
      style={{ transition: 'none' }}
    >
      <Row style={{ height: '100%', flexWrap: 'nowrap' }}>
        <Col flex="70px" style={{ borderRight: '1px solid rgba(5,5,5,.06)' }}>
          <Flex vertical style={{ height: '100%' }}>
            <AppSidebarMenu
              items={mainMenuItems}
              value={mainMenuValue}
              onChange={updateMainMenuValue}
            />
            <div style={{ flexGrow: 1 }}></div>
            <AppSidebarMenu
              items={toolMenuItems}
              value={toolMenuValue}
              onChange={updateToolMenuValue}
            />
          </Flex>
        </Col>
        <Col
          flex="auto"
          style={{
            display: isSidebarMenuOpened && !toolComponent ? 'block' : 'none',
          }}
        >
          {children}
        </Col>
        {/* show toolbar sidebar without unmounting main sidebar */}
        {toolComponent && isSidebarMenuOpened && (
          <Col flex="auto">{toolComponent}</Col>
        )}
      </Row>
    </Sider>
  );
});

export { AppSidebar };
