import { makeAutoObservable, reaction, runInAction } from 'mobx';

import { MY_POI_VALUE } from '../config';
import { createCriteriaSerializableParam } from '../utils/createCriteriaSerializableParam';
import { createPoiObjects } from '../utils/cretePoiObjects';
import { filterPoiObjects } from '../utils/filterPoiObjects';
import { stringifyCoordinate } from '../utils/stringifyCoordinate';

import appStore from './app';
import filterStore from './filter';
import managerStore from './manager';

import type { FilterStore } from './filter';
import type { RegistrableStore, SerializableParam } from './interface';
import type { UIAnalysisCriteria } from '../domain/criteria';
import type { PoiAnalysisApiKpi, PoiItem, PoiItemObject } from '../interfaces';
import type { LngLatBoundsArray } from '@nextgis/utils';

type AnalysisStoreStatus = 'idle' | 'loading' | 'success' | 'failed';

export class AnalysisStore implements RegistrableStore {
  status: AnalysisStoreStatus;
  defaultCriteria: UIAnalysisCriteria = {
    kpi: [],
    poiCategory: null,
    poiSubcategory: [],
    buffer: appStore.isReady ? appStore.bufferConfig[0] : 0,
    time_range: appStore.isReady
      ? [appStore.localeMinHourlyDate, appStore.localeMaxHourlyDate]
      : undefined,
    peak_hours: [],
    useExtent: false,
  };
  criteria: UIAnalysisCriteria = this.defaultCriteria;
  poi: PoiItem[] = [];
  poiKpis: string[] = [];
  poiKpiDomainRanges: Record<string, PoiAnalysisApiKpi> = {};
  useExtent: boolean = false;
  previousAnalysisExtent: LngLatBoundsArray | null = null;

  filterStore: FilterStore;

  constructor(filterStore: FilterStore) {
    makeAutoObservable(this);
    this.status = 'idle';
    this.filterStore = filterStore;
    reaction(
      () => appStore.isReady,
      () => {
        this.initCriteria();
      },
    );
  }

  get poiObjects(): PoiItemObject[] {
    return createPoiObjects({
      poi: this.poi,
      poiKpis: this.poiKpis,
      withComparison: appStore.scenario === 'critical-areas',
      comparisonConfig: appStore.comparisonConfig,
    });
  }

  get filteredPoiObjects(): PoiItemObject[] {
    return filterPoiObjects({
      poiObjects: this.poiObjects,
      filter: this.filterStore.filterExpression,
      targetKey: 'values',
    });
  }

  get filteredPoiIds(): number[] {
    return this.filteredPoiObjects.map((poiObject) => poiObject.id as number);
  }

  get isMyPoi() {
    return this.criteria.poiCategory === MY_POI_VALUE;
  }

  get serializableParams(): SerializableParam[] {
    const params: SerializableParam[] = [
      {
        key: 'analysis-extent',
        value:
          this.criteria.useExtent && this.previousAnalysisExtent
            ? this.previousAnalysisExtent
            : undefined,
        serialize: () =>
          this.previousAnalysisExtent
            ? stringifyCoordinate(this.previousAnalysisExtent)
            : '',
        deserialize: (value) => {
          this.setPreviousAnalysisExtent(value.split('x').map(Number));
        },
      },
      {
        key: 'use-extent',
        value: this.criteria.useExtent ? this.useExtent : undefined,
        serialize: () => (this.criteria.useExtent ? 'yes' : 'no'),
        deserialize: (value) => {
          runInAction(() => {
            this.criteria.useExtent = value === 'yes' ? true : false;
          });
        },
      },
      ...createCriteriaSerializableParam(this.criteria).map((c) => {
        const originalDeserialize = c.deserialize;
        c.deserialize = (val) =>
          runInAction(() => {
            originalDeserialize(val);
          });
        return c;
      }),
    ];
    return params;
  }

  private initCriteria() {
    this.defaultCriteria = {
      kpi: [],
      poiCategory: null,
      poiSubcategory: [],
      buffer: appStore.isReady ? appStore.bufferConfig[0] : 0,
      time_range: appStore.isReady
        ? [appStore.localeMinHourlyDate, appStore.localeMaxHourlyDate]
        : undefined,
      peak_hours: [],
      useExtent: false,
    };
    this.setCriteria({
      ...this.criteria,
      buffer: this.criteria.buffer || this.defaultCriteria.buffer,
      time_range: this.criteria.time_range || this.defaultCriteria.time_range,
    });
  }

  setStatus(newStatus: AnalysisStoreStatus) {
    this.status = newStatus;
  }

  setCriteria(newCriteria: UIAnalysisCriteria) {
    this.criteria = newCriteria;
  }

  setPoi(poi: PoiItem[]) {
    this.poi = poi;
  }

  setPoiKpis(newPoiKpis: string[]) {
    this.poiKpis = newPoiKpis;
  }

  setPoiKpiDomainRanges(newPoiPoiKpiDomainRanges: PoiAnalysisApiKpi[]) {
    newPoiPoiKpiDomainRanges.forEach((poiKpiDomainRange) => {
      this.poiKpiDomainRanges[poiKpiDomainRange.identity] = poiKpiDomainRange;
    });
  }

  removePoiKpiDomainRanges(poiKpisToRemove: string[]) {
    poiKpisToRemove.forEach((poiKpi) => {
      if (poiKpi in this.poiKpiDomainRanges)
        delete this.poiKpiDomainRanges[poiKpi];
    });
  }

  clearPoiKpiDomainRanges() {
    this.poiKpiDomainRanges = {};
  }

  setUseExtent(newUseExtent: boolean) {
    this.useExtent = newUseExtent;
  }

  setPreviousAnalysisExtent(newExtent: LngLatBoundsArray | null) {
    this.previousAnalysisExtent = newExtent;
  }

  savePoiData(newPoi: PoiItem[], newPoiKpis: string[]) {
    this.setPoi(newPoi);
    this.setPoiKpis(newPoiKpis);
  }

  clearPoiData() {
    this.setPoi([]);
    this.setPoiKpis([]);
  }

  resetCriteria() {
    this.setCriteria(this.defaultCriteria);
  }

  reset() {
    //reset all analysis data
  }
}

export default managerStore.register(new AnalysisStore(filterStore));
