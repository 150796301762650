import { CriticalAreaService } from './CriticalAreaService';
import { PoiAnalysisService } from './PoiAnalysisService';

import type { AnalysisDataFetcher } from '../api/analysisApi';
import type { Scenario } from '../interfaces';

const createAnalysisService = (
  scenario: Scenario,
  request: AnalysisDataFetcher,
): PoiAnalysisService | CriticalAreaService => {
  return scenario === 'critical-areas'
    ? new CriticalAreaService(request)
    : new PoiAnalysisService(request);
};

export { createAnalysisService };
