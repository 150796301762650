import { useCallback, useMemo } from 'react';

import { createAnalysisService } from '../services/analysisServiceFactory';
import appStore from '../store/app';
import { makeApiRequest } from '../utils/makeApiRequest';

import { useAbortController } from './useAbortController';

import type {
  AnalysisCriteria,
  SystemPoiCriteria,
  UserPoiCriteria,
} from '../domain/criteria';

interface UseAnalysisApiProps {
  onAbort?: () => void;
  onRequestExecuted?: () => void;
}

export const useAnalysisApi = ({
  onAbort,
  onRequestExecuted,
}: UseAnalysisApiProps) => {
  const { makeSignal, abort: requestAbort } = useAbortController();

  const { scenario } = appStore;

  const abort = useCallback(() => {
    if (onAbort) onAbort();
    requestAbort();
  }, [requestAbort, onAbort]);

  const request = useCallback(
    async (criteria: AnalysisCriteria<SystemPoiCriteria | UserPoiCriteria>) => {
      abort();
      return makeApiRequest({
        criteria,
        signal: makeSignal(),
        onRequestExecuted,
      });
    },
    [makeSignal, abort, onRequestExecuted],
  );

  const analysisService = useMemo(() => {
    abort();
    return createAnalysisService(scenario || 'poi-analysis', request);
  }, [scenario, request, abort]);

  return { request, abort, analysisService };
};
