import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import type { DateRange } from '../interfaces';
import type { Dayjs, ManipulateType, OpUnitType } from 'dayjs';
import type { RangeValue } from 'rc-picker/lib/interface';

interface DurationObject {
  durationNum: number;
  durationName: ManipulateType;
}

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const parseDuration = (duration: string): DurationObject => {
  const [durationNum, durationName] = duration.split(' ');
  return {
    durationNum: Number(durationNum),
    durationName: durationName as ManipulateType,
  };
};

const createDateTimeRange = (
  duration: string,
  dateUntil: Dayjs,
): RangeValue<Dayjs> => {
  const { durationNum, durationName } = parseDuration(duration);
  const dateFrom = dayjs(dateUntil).subtract(
    Number(durationNum),
    durationName as ManipulateType,
  );

  return dateFrom && [dateFrom, dateUntil];
};

const isDateInRange = (
  date: Dayjs,
  domainRange: DateRange,
  unit?: OpUnitType,
): boolean => {
  return (
    !!domainRange &&
    !!domainRange[0] &&
    !!domainRange[1] &&
    date.isSameOrAfter(domainRange[0], unit) &&
    date.isSameOrBefore(domainRange[1], unit)
  );
};

const createUniqueArray = (array: unknown[]) =>
  array.filter((item: unknown, index: number) => array.indexOf(item) === index);

const getRange = (start: number, end: number, step = 1) =>
  [...Array(end - start + 1).keys()].map((i) => i * step + start);

const getUnavailableHoursForDateInRange = (
  date: Dayjs,
  range: RangeValue<Dayjs>,
): number[] => {
  let unavailableHours: number[] = [];
  if (range && range[0] && date.isSame(range[0], 'day')) {
    unavailableHours = getRange(0, range[0].hour() - 1);
  }
  if (range && range[1] && date.isSame(range[1], 'day')) {
    unavailableHours = [
      ...unavailableHours,
      ...getRange(range[1].hour() + 1, 24),
    ];
  }
  return createUniqueArray(unavailableHours) as number[];
};

const getUnavailableMinutesForDateInRange = (
  date: Dayjs,
  range: RangeValue<Dayjs>,
): number[] => {
  let unavailableMinutes: number[] = [];
  if (
    range &&
    range[0] &&
    date.isSame(range[0], 'day') &&
    date.isSame(range[0], 'hour')
  ) {
    unavailableMinutes = getRange(0, range[0].minute() - 1);
  }
  if (
    range &&
    range[1] &&
    date.isSame(range[1], 'day') &&
    date.isSame(range[1], 'hour')
  ) {
    unavailableMinutes = [
      ...unavailableMinutes,
      ...getRange(range[1].minute() + 1, 60),
    ];
  }
  return createUniqueArray(unavailableMinutes) as number[];
};

const splitTimeRangeByMidnight = (
  hourRange: [Dayjs, Dayjs],
): [Dayjs, Dayjs][] => {
  const secondBeforeMidnight = setSecondBeforeMidnight(dayjs(hourRange[0]));
  const midnight = nullifyTime(dayjs(hourRange[1]));
  if (hourRange[0].hour() === 0) return [[hourRange[0], secondBeforeMidnight]];
  if (hourRange[1].hour() === 0) return [[hourRange[0], secondBeforeMidnight]];
  return [
    [hourRange[0], secondBeforeMidnight],
    [midnight, hourRange[1]],
  ];
};

const convertLocalHourRangesToUtcStrings = (
  hourRanges: [Dayjs, Dayjs][],
  format: string,
): string[][] => {
  const result = hourRanges.reduce<string[][]>((result, hourRange) => {
    const utcFrom = hourRange[0].utc();
    // replace 23:59:59 by 00:00:00,
    // since it's not necessary to remove last minute in utc
    const utcTo =
      hourRange[1].hour() === 23 && hourRange[1].minute() === 59
        ? nullifyTime(hourRange[1]).utc()
        : hourRange[1].utc();
    if (utcFrom.hour() >= utcTo.hour()) {
      const currentHourRanges = splitTimeRangeByMidnight([utcFrom, utcTo]).map(
        (hourRange) => {
          return hourRange.map((time) => time.format(format));
        },
      );
      return [...result, ...currentHourRanges];
    }
    return [...result, [utcFrom.format(format), utcTo.format(format)]];
  }, []);
  return result;
};

const getDateRangeString = (range: DateRange, format: string) => {
  return (
    range[0] &&
    range[1] &&
    `${range[0].format(format)} – ${range[1].format(format)}`
  );
};

const setSecondBeforeMidnight = (date: Dayjs): Dayjs => {
  return date.hour(23).minute(59).second(59);
};

const nullifyTime = (date: Dayjs) => {
  return date.hour(0).minute(0).second(0);
};

const nullifyMinutesAndSeconds = (date: Dayjs) => {
  return date.minute(0).second(0);
};

const isSecondBeforeMidnight = (date: Dayjs): boolean => {
  return date.hour() === 23 && date.minute() === 59 && date.second() === 59;
};
const isMidnight = (date: Dayjs): boolean => {
  return date.hour() === 0 && date.minute() === 0 && date.second() === 0;
};

const getTimezoneWithOffset = (timezone: string): string => {
  const timezoneOffset = dayjs().tz(timezone).utcOffset() / 60;
  return `${timezone} (UTC ${timezoneOffset < 0 ? '' : '+'}${timezoneOffset})`;
};

export {
  createDateTimeRange,
  isDateInRange,
  parseDuration,
  getUnavailableHoursForDateInRange,
  getUnavailableMinutesForDateInRange,
  getRange,
  convertLocalHourRangesToUtcStrings,
  getDateRangeString,
  splitTimeRangeByMidnight,
  setSecondBeforeMidnight,
  nullifyMinutesAndSeconds,
  nullifyTime,
  isSecondBeforeMidnight,
  isMidnight,
  getTimezoneWithOffset,
};
