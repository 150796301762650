import memoize from 'lodash-es/memoize';
import { createElement } from 'react';
import tinycolor from 'tinycolor2';

import { checkValueForRule } from '../utils/coloringRules';

import type {
  BarChartItem,
  Chart,
  ChartRule,
  ColoringRule,
  ComparisonConfigItem,
  ExtendedBarChartItem,
  ExtendedChart,
  KpiConfigItem,
  PoiItemObject,
} from '../interfaces';
import type { HTMLAttributes, ReactNode } from 'react';

const createChart = ({
  id,
  values,
  targetKey,
  chartRules,
  isSorted,
  sortingFunction,
  shouldSaveItems = false,
}: {
  id: string | number;
  values: Record<
    string | number,
    number | null | Record<string | number, number | null>
  >[];
  targetKey?: string | number;
  chartRules: ChartRule[];
  isSorted: boolean;
  sortingFunction?: (a: unknown, b: unknown) => number;
  shouldSaveItems?: boolean;
}): Chart => {
  const bars: BarChartItem[] = chartRules.map((rule, index) => {
    const barItem: BarChartItem = {
      key: index,
      id: rule.label || '',
      rule,
      rangeLabel: rule.label || '',
      color: rule.color,
      value: 0,
    };
    if (shouldSaveItems) barItem.items = [];
    return barItem;
  });

  const sortedValues =
    !isSorted && sortingFunction
      ? values.sort((a, b) => {
          const aToSort = targetKey
            ? (a[targetKey] as Record<string | number, number | null>)[id]
            : a[id];
          const bToSort = targetKey
            ? (b[targetKey] as Record<string | number, number | null>)[id]
            : b[id];
          return sortingFunction(aToSort, bToSort);
        })
      : values;

  let currentBarIndex = 0;
  sortedValues.forEach((value) => {
    const valueToEvaluate = targetKey
      ? (value[targetKey] as Record<string | number, number | null>)
      : value;

    if (targetKey && typeof value[targetKey] !== 'object')
      throw new Error('valueToEvaluate is not an object');

    while (
      currentBarIndex < chartRules.length &&
      !checkValueForRule(
        valueToEvaluate[id] as number | null,
        chartRules[currentBarIndex].rule,
      )
    ) {
      currentBarIndex += 1;
    }
    if (bars[currentBarIndex]) {
      bars[currentBarIndex].value += 1;
      if (shouldSaveItems) bars[currentBarIndex].items?.push(value);
    }
  });
  return { id, bars };
};

const inverseBars = ({
  bars,
  withNull,
}: {
  bars: BarChartItem[];
  withNull: boolean;
}): BarChartItem[] => {
  if (withNull) {
    const nullBar = bars[bars.length - 1];
    const barsWithoutNullBar = bars.slice(0, bars.length - 1);
    return [...[...barsWithoutNullBar].reverse(), nullBar];
  }
  return [...bars].reverse();
};

const updateChartRuleByBarSelection = (
  rules: ChartRule[],
  selectedBarIds: (string | number)[],
): ChartRule[] => {
  if (selectedBarIds.length === 0) return rules;
  return rules.map((rule) => ({
    ...rule,
    color: selectedBarIds.includes(rule.label)
      ? rule.color
      : tinycolor(rule.color).setAlpha(0.15).toRgbString(),
  }));
};

// const createTicksFromRanges = (data: BarChartItem[]) => {
//   return [
//     ...data.map((item) => Math.round(item.range[0])),
//     Math.round(data[data.length - 1].range[1]),
//   ];
// };

const round = (value: number, digitsAfterPoint: number) => {
  const multiplier = Math.pow(10, digitsAfterPoint);
  return Math.round(value * multiplier) / multiplier;
};

const shortenNumber = (number: number, digitsAfterPoint = 1): string => {
  interface Shorthand {
    threshold: number;
    name: string;
  }
  const shorthands: Shorthand[] = [
    { threshold: 1e3, name: 'K' },
    { threshold: 1e6, name: 'M' },
    { threshold: 1e9, name: 'B' },
    { threshold: 1e12, name: 'T' },
    { threshold: 1e15, name: 'P' },
    { threshold: 1e18, name: 'E' },
  ];

  if (number < 1000) return String(round(number, digitsAfterPoint));

  const currentShorthand = shorthands.find(
    ({ threshold }: Shorthand, index: number) =>
      number >= threshold &&
      (index === shorthands.length - 1,
      number < shorthands[index + 1].threshold),
  );
  if (currentShorthand) {
    return `${round(number / currentShorthand.threshold, digitsAfterPoint)}${
      currentShorthand.name
    }`;
  }
  return String(round(number, digitsAfterPoint));
};

const makeRangeString = (from: number, to: number): string => {
  if (from === Number.NEGATIVE_INFINITY) return `< ${to}`;
  if (to === Number.POSITIVE_INFINITY) return `≥ ${from}`;
  return `${from} — ${to}`;
};

function memoizeHashFunction() {
  // not really an efficient hash function
  // eslint-disable-next-line prefer-rest-params
  return JSON.stringify(arguments);
}

const makeRangeStringByColoringRule = memoize(
  (
    rule: ColoringRule,
    { nullValue }: { nullValue: string } = { nullValue: '' },
  ): string => {
    return rule.rule[0] === 'isBetween' && Array.isArray(rule.rule[1])
      ? makeRangeString(rule.rule[1][0], rule.rule[1][1])
      : nullValue;
  },
  memoizeHashFunction,
);

const createLegendLabel = memoize(
  (
    color: string,
    label: string,
    options: HTMLAttributes<HTMLDivElement>,
  ): ReactNode => {
    return createElement(
      'span',
      options,
      createElement('span', {
        className: 'circle',
        style: {
          display: 'inline-block',
          width: '8px',
          height: '8px',
          backgroundColor: color,
          marginRight: '4px',
          verticalAlign: 'middle',
        },
      }),
      createElement(
        'span',
        {
          style: {
            verticalAlign: 'middle',
          },
        },
        label,
      ),
    );
  },
  memoizeHashFunction,
);

const extendChart = (
  chart: Chart,
  chartConfig: KpiConfigItem | ComparisonConfigItem,
): ExtendedChart => {
  const extendedBars: ExtendedBarChartItem[] = chart.bars.map((bar, index) => {
    let areAllItemsNumbers = true;
    const barItemsSum = (bar.items as PoiItemObject[]).reduce((acc, item) => {
      const itemValue = item.values[chart.id];
      if (typeof itemValue !== 'number') areAllItemsNumbers = false;
      return itemValue ? acc + itemValue : acc;
    }, 0);
    const avg: number | null =
      areAllItemsNumbers && bar.items && bar.items.length > 0
        ? barItemsSum / bar.items.length
        : null;
    const valueRule =
      'coloringRules' in chartConfig ? chartConfig.coloringRules[index] : null;
    return {
      ...bar,
      avg,
      valueRule,
    };
  });
  return {
    ...chart,
    bars: extendedBars,
  };
};

export {
  createChart,
  shortenNumber,
  updateChartRuleByBarSelection,
  makeRangeString,
  createLegendLabel,
  makeRangeStringByColoringRule,
  inverseBars,
  extendChart,
};
