import isEqual from 'lodash-es/isEqual';
import { makeAutoObservable } from 'mobx';
import { createNanoEvents } from 'nanoevents';

import { filterItemToExpression } from '../utils/filterUtils';

import managerStore from './manager';

import type { SerializableParam } from './interface';
import type { FilterItem } from '../interfaces';
import type { Expression } from '@nextgis/expression';

const emitter = createNanoEvents();
const ADD_EVENT = 'add';
const REMOVE_EVENT = 'remove';

export class FilterStore {
  filterValues: FilterItem[];

  get filterExpression(): Expression {
    const filterExpressions: Expression[] = this.filterValues
      .map((filterValue) => {
        const filterExp = filterItemToExpression(filterValue, true);
        return filterExp;
      })
      .filter((expression) => expression !== undefined) as Expression[];
    return ['all', ...filterExpressions];
  }

  constructor() {
    makeAutoObservable(this);
    this.filterValues = [];
  }

  get filterValueIds(): string[] {
    return this.filterValues.map((value) => value.id);
  }

  get serializableParams(): SerializableParam[] {
    const params: SerializableParam[] = [
      {
        key: `filter`,
        value: this.filterValues.length === 0 ? undefined : this.filterValues,
        serialize: () => {
          const str = this.filterValues
            .map((filterValue) => {
              const { id, range } = filterValue;
              if (range) return `${id}[${range[0]}, ${range[1]}]`;
              return null;
            })
            .join('|');
          return str;
        },
        deserialize: (filterValues) => {
          const filterValuesForStore: FilterItem[] = [];
          filterValues.split('|').forEach((filterValue) => {
            if (filterValue) {
              const [id, ...dirtyRange] = filterValue.split('[');
              const range = dirtyRange
                .join()
                .replace(']', '')
                .split(',')
                .map(Number) as [number, number];
              filterValuesForStore.push({
                id,
                range,
                domainRange: undefined,
                isFromAnalysisCriteria: undefined,
              });
            }
          });
          this.resetFilterValues();
          this.updateFilterValues(filterValuesForStore);
        },
      },
    ];
    return params;
  }

  private updateFilterValues(newFilterValues: FilterItem[]) {
    this.filterValues = newFilterValues;
  }

  changeFilterValues(newFilterValues: FilterItem[]) {
    if (
      !isEqual(
        newFilterValues.map((filterValue) => filterValue.id).sort(),
        this.filterValueIds.sort(),
      )
    )
      throw new Error(
        "You've tried to add or remove filterValue. Do it with addFilterValues or removeFilterValues, otherwise onAdd and onRemove events will not be emitted",
      );
    this.updateFilterValues(newFilterValues);
  }

  addFilterValues(newValues: FilterItem[]) {
    this.updateFilterValues([...this.filterValues, ...newValues]);
    emitter.emit(ADD_EVENT, newValues);
  }

  removeFilterValues(filterValuesToRemove: FilterItem[]) {
    const newFilterValues = this.filterValues.filter(
      (filterValue) =>
        !filterValuesToRemove.some(
          (filterValueToRemove) => filterValueToRemove.id === filterValue.id,
        ),
    );
    this.updateFilterValues(newFilterValues);
    emitter.emit(REMOVE_EVENT, filterValuesToRemove);
  }

  onAdd(handler: (newFilterValues: FilterItem[]) => void) {
    const unbind = emitter.on(ADD_EVENT, handler);
    return unbind;
  }

  onRemove(handler: (removedFilterValues: FilterItem[]) => void) {
    const unbind = emitter.on(REMOVE_EVENT, handler);
    return unbind;
  }

  resetFilterValues() {
    this.filterValues = [];
  }
}

export default managerStore.register(new FilterStore());
