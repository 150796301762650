import type { SelectedBarChartItem } from '../interfaces';
import type { Expression } from '@nextgis/expression';

export function createKpiFilter(
  items: SelectedBarChartItem[],
): Expression | undefined {
  const nameCases: Record<string, Expression> = {};

  for (const item of items) {
    const name = item.chartId;
    const cases = nameCases[name] || ['any'];
    const rule = item.rule;
    if (rule[0] === 'isBetween') {
      const betweenCase: Expression = [
        'all',
        ['==', ['typeof', ['get', name]], 'number'],
      ];

      const [from, to] = rule[1] as [number, number];
      if (!isFinite(from)) {
        betweenCase.push(['<', ['to-number', ['get', name]], to]);
      } else if (!isFinite(to)) {
        betweenCase.push(['>', ['to-number', ['get', name]], from]);
      } else {
        betweenCase.push([
          'all',
          ['>=', ['to-number', ['get', name]], from],
          ['<=', ['to-number', ['get', name]], to],
        ]);
      }
      cases.push(betweenCase);
    } else if (rule[0] === 'isSameAs') {
      const val = rule[1] as number;
      cases.push(['==', ['get', name], val]);
    }
    nameCases[name] = cases;
  }
  const allCases = Object.values(nameCases);
  return allCases.length ? ['all', ...allCases] : undefined;
}
