import { useEffect, useMemo, useState } from 'react';

import appStore from '../store/app';
import {
  isDateInRange,
  isMidnight,
  isSecondBeforeMidnight,
} from '../utils/datetime';

import type { DateRange } from '../interfaces';
import type { Dayjs } from 'dayjs';
import type { Dispatch, SetStateAction } from 'react';

interface UsePeakHoursResult {
  peakHours: ((Dayjs | undefined)[] | undefined)[];
  setPeakHours: Dispatch<SetStateAction<((Dayjs | undefined)[] | undefined)[]>>;
  notNullPeakHours: [Dayjs, Dayjs][];
  peakHoursString: string;
  isPeakHoursActive: boolean;
}
export function usePeakHours({
  defaultValue,
  timeRange,
}: {
  defaultValue: ((Dayjs | undefined)[] | undefined)[];
  timeRange: DateRange | undefined;
}): UsePeakHoursResult {
  const { localeMinHourlyDate, localeMaxHourlyDate } = appStore;

  const allowedHourlyRange: DateRange = useMemo(
    () => [localeMinHourlyDate, localeMaxHourlyDate],
    [localeMinHourlyDate, localeMaxHourlyDate],
  );

  const [peakHours, setPeakHours] =
    useState<((Dayjs | undefined)[] | undefined)[]>(defaultValue);

  const notNullPeakHours = useMemo(
    () =>
      peakHours.filter(
        (peakHoursRange) =>
          peakHoursRange && peakHoursRange[0] && peakHoursRange[1],
      ) as [Dayjs, Dayjs][],
    [peakHours],
  );

  const peakHoursString =
    notNullPeakHours.length > 0
      ? notNullPeakHours.reduce((resultString, peakHoursRange, index) => {
          const from = peakHoursRange[0].hour();
          const to =
            isSecondBeforeMidnight(peakHoursRange[1]) ||
            isMidnight(peakHoursRange[1])
              ? 24
              : peakHoursRange[1].hour();
          return `${resultString} ${from}-${to}${
            index === notNullPeakHours.length - 1 ? '' : ','
          }`;
        }, 'Peak hours:')
      : '';

  const isPeakHoursActive = useMemo(
    () =>
      !!timeRange &&
      !!timeRange[0] &&
      !!timeRange[1] &&
      isDateInRange(timeRange[0], allowedHourlyRange, 'day') &&
      isDateInRange(timeRange[1], allowedHourlyRange, 'day'),
    [timeRange, allowedHourlyRange],
  );

  useEffect(() => {
    if (!isPeakHoursActive) setPeakHours([]);
  }, [isPeakHoursActive, setPeakHours]);

  return {
    peakHours,
    setPeakHours,
    notNullPeakHours,
    peakHoursString,
    isPeakHoursActive,
  };
}
