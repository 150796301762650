import { FilterTag } from '../components/filter/FilterTag';
import { MY_POI_LABEL } from '../config';
import appStore from '../store/app';
import filterStore from '../store/filter';

import type { UIAnalysisCriteria } from '../domain/criteria';
import type { FilterItem } from '../interfaces';
import type { CSSProperties, ReactNode } from 'react';

interface UseAnalysisSummaryProps {
  criteria: UIAnalysisCriteria;
  filterValues?: FilterItem[];
}

export interface SummaryValue {
  id: string;
  label?: string;
  value?: string | ReactNode;
  style?: CSSProperties;
}

const filterTagCss = {
  height: 'auto',
  backgroundColor: 'transparent',
  border: '0',
  padding: '0',
  color: '#8491A4',
  marginInlineEnd: '2px',
};

export const useAnalysisSummary = ({
  criteria, // filterValues,
}: UseAnalysisSummaryProps) => {
  const summaryValues: SummaryValue[] = [
    {
      id: 'poi.category',
      label: 'POI Category',
      value:
        criteria.poiCategory === 'mypoi'
          ? MY_POI_LABEL
          : criteria.poiCategory || '',
    },
    {
      id: 'poi.subcategory',
      label: 'POI Subcategory',
      value:
        criteria.poiCategory !== 'mypoi' && criteria.poiSubcategory
          ? criteria.poiSubcategory.join(', ')
          : '',
    },
    {
      id: 'kpi',
      label: 'KPI',
      value: criteria.kpi
        .map((kpi) => appStore.getKpiConfigById(kpi)?.label)
        .join(', '),
      style: { fontWeight: 700 },
    },
    {
      id: 'filters',
      label: 'Filters',
      value: filterStore.filterValues.map((filterValue, index) => (
        <>
          {index !== 0 && ', '}
          <FilterTag
            key={filterValue.id}
            value={filterValue}
            style={filterTagCss}
          />
        </>
      )),
    },
    {
      id: 'buffer',
      label: 'Buffer size',
      value:
        criteria.buffer !== null || criteria.buffer !== undefined
          ? `${criteria.buffer}m`
          : '',
    },
  ];
  return { summaryValues };
};
