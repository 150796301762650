import { makeAutoObservable, runInAction } from 'mobx';

import appStore from './app';
import managerStore from './manager';

import type { RegistrableStore, SerializableParam } from './interface';
import type { SelectedBarChartItem } from '../interfaces';

class ChartsStore implements RegistrableStore {
  chartIds: string[] = [];
  selectedBars: SelectedBarChartItem[] = [];
  activeChartId: string | number | null = null;
  areChartsShown: boolean = true;

  collapsedCharts: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get serializableParams(): SerializableParam[] {
    return [
      {
        key: 'active-chart-id',
        value: this.activeChartId || undefined,
        serialize: () => String(this.activeChartId),
        deserialize: (value) => {
          runInAction(() => {
            this.setActiveChartId(value);
          });
        },
      },
      {
        key: 'chartFilter',
        value: this.selectedBars.length === 0 ? undefined : this.selectedBars,
        serialize: () => {
          const selectedBarObject = this.selectedBars.reduce(
            (acc, bar) => {
              if (acc[bar.chartId]) {
                return {
                  ...acc,
                  [bar.chartId]: [...acc[bar.chartId], bar.id],
                };
              }
              return {
                ...acc,
                [bar.chartId]: [bar.id],
              };
            },
            {} as Record<string, (string | number)[]>,
          );
          return Object.entries(selectedBarObject)
            .map(([chartId, barIds]) => `${chartId}[${barIds}]`)
            .join('|');
        },
        deserialize: (chartFilterString) => {
          const selectedBars: SelectedBarChartItem[] = [];
          chartFilterString.split('|').forEach((chartFilterItem) => {
            const chartId = chartFilterItem.split('[')[0];
            const stringBetweenBrackets = chartFilterItem.match(/\[(.*?)\]/);
            const barIds =
              stringBetweenBrackets && stringBetweenBrackets[1].split(',');
            if (barIds && barIds.length > 0)
              barIds.forEach((barId) => {
                const rule = appStore
                  .getConfigById(chartId)
                  ?.categoryColoringRules.find((rule) => rule.label === barId);
                if (rule)
                  selectedBars.push({
                    chartId,
                    id: barId,
                    rule: rule.rule,
                  });
              });
          });
          this.updateSelectedBars(selectedBars);
        },
      },
    ];
  }

  updateSelectedBars(newSelectedBars: SelectedBarChartItem[]) {
    this.selectedBars = newSelectedBars;
  }

  toggleSelectedBars(newSelectedBar: SelectedBarChartItem) {
    const indexOfBar = this.selectedBars.findIndex(
      (selectedBar) =>
        selectedBar.chartId === newSelectedBar.chartId &&
        selectedBar.id === newSelectedBar.id,
    );
    if (indexOfBar === -1) {
      this.updateSelectedBars([...this.selectedBars, newSelectedBar]);
    } else {
      const newSelectedBars = [...this.selectedBars];
      newSelectedBars.splice(indexOfBar, 1);
      this.updateSelectedBars(newSelectedBars);
    }
  }

  setActiveChartId(newActiveChartId: string | number | null) {
    this.activeChartId = newActiveChartId;
  }

  setAreChartsShown(areShown: boolean) {
    this.areChartsShown = areShown;
  }

  setChartIds(newChartIds: string[]) {
    this.chartIds = newChartIds;
  }

  clearCharts() {
    this.updateSelectedBars([]);
    this.setChartIds([]);
    this.setAreChartsShown(true);
    this.setActiveChartId(null);
  }

  collapseChart(key: string) {
    if (!this.collapsedCharts.includes(key)) {
      this.collapsedCharts = [...this.collapsedCharts, key];
    }
  }

  expandChart(key: string) {
    this.collapsedCharts = this.collapsedCharts.filter(
      (chartKey) => chartKey !== key,
    );
  }

  toggleChart(key: string) {
    this.collapsedCharts = this.collapsedCharts.includes(key)
      ? this.collapsedCharts.filter((chartKey) => chartKey !== key)
      : [...this.collapsedCharts, key];
  }

  setCollapsed(collapsed: string[]) {
    this.collapsedCharts = collapsed;
  }
}

export default managerStore.register(new ChartsStore());
