import { InfoCircleFilled } from '@ant-design/icons';
import { Collapse, ConfigProvider, Flex, Radio, Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { styled } from 'styled-components';

import { COMPARISON_ID } from '../../config';
import { useDependentChart } from '../../hooks/useDependentChart';
import analysisStore from '../../store/analysis';
import chartStore from '../../store/charts';
import { extendChart, shortenNumber } from '../../utils/chartUtils';
import { AppBarChart } from '../shared/AppBarChart';

import { AnalysisBarChartLegend } from './AnalysisBarChartLegend';
import { AnalysisBarChartTooltip } from './AnalysisBarChartTooltip';

import type {
  ChartRule,
  ComparisonConfigItem,
  KpiConfigItem,
  PoiItemObject,
} from '../../interfaces';
import type { RadioChangeEvent } from 'antd';
import type { CollapseProps } from 'antd/lib';
import type {
  CategoricalChartFunc,
  CategoricalChartState,
} from 'recharts/types/chart/generateCategoricalChart';
import type { CSSProperties } from 'styled-components';

interface AnalysisBarChartCardProps {
  values: PoiItemObject[];
  config: KpiConfigItem | ComparisonConfigItem;
  style?: CSSProperties;
  onInfoClick?: (chartConfig: KpiConfigItem | ComparisonConfigItem) => void;
}

const AnalysisBarChartCardCollapse = styled(Collapse)`
  border-width: 1px;
  border-style: solid;
  background-color: white;

  .analysis-bar-chart-card__title {
    line-height: 1.1;
  }
  .ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon {
    padding-inline-start: 6px !important;
  }
`;

const InfoIcon = styled(InfoCircleFilled)`
  cursor: pointer;
  color: #8491a4;
  z-index: 2;
  font-size: 15px;
  position: relative;
  top: 1px;
`;

const TotalTag = styled(Tag)`
  background-color: rgb(228, 236, 246);
  margin-right: 12px;
`;

export const AnalysisBarChartCard = observer(
  ({
    values,
    config,
    style,
    onInfoClick,
  }: // range,
  AnalysisBarChartCardProps) => {
    const { collapsedCharts } = chartStore;
    const chartRules: ChartRule[] = useMemo(
      () => config.categoryColoringRules,
      [config.categoryColoringRules],
    );

    const onChartClick: CategoricalChartFunc = (
      nextState: CategoricalChartState,
    ) => {
      if (nextState) {
        const payload =
          nextState.activePayload && nextState.activePayload[0].payload;
        if (payload && payload.value !== 0)
          chartStore.toggleSelectedBars({
            id: payload.rangeLabel,
            rule: payload.rule.rule,
            chartId: config.identity,
          });
      }
    };
    const { chart, selectedBarIds, total, totalSelected } = useDependentChart({
      id: config.identity,
      values: values as unknown as Record<string | number, unknown>[],
      targetKey: 'categories',
      chartRules,
      allChartSelectedBars: chartStore.selectedBars,
    });

    const chartExtended = extendChart(chart, config);

    const key = useMemo(() => config.identity, [config.identity]);

    const isOpen = useMemo(
      () => !collapsedCharts.includes(key),
      [collapsedCharts, key],
    );

    const activeKey = useMemo(() => (isOpen ? [key] : []), [isOpen, key]);

    const isCardActivationNeeded = analysisStore.criteria.kpi.length > 1;
    const isChartActive = chartStore.activeChartId === config.identity;

    const onChartActivate = (e: RadioChangeEvent) => {
      e.nativeEvent.stopPropagation();
      chartStore.setActiveChartId(config.identity);
    };

    const unit = 'unit' in config ? config.unit : undefined;

    const cardTitle = (
      <h4 className="text-sm analysis-bar-chart-card__title">
        {config.labelDetailed}
        {unit && `, ${unit}`}
      </h4>
    );

    const cardHeader = (
      <div>
        <Flex justify="space-between" align="center">
          {isCardActivationNeeded && (
            <Radio
              checked={isChartActive}
              style={{ position: 'relative', zIndex: 100 }}
              onChange={onChartActivate}
            >
              {cardTitle}
            </Radio>
          )}
          {!isCardActivationNeeded && cardTitle}
          <div className="nowrap">
            <TotalTag>
              {totalSelected !== 0 ? (
                <>
                  {totalSelected}
                  <span className="text-muted">/{total}</span>
                </>
              ) : (
                total
              )}
            </TotalTag>
            {config.description && (
              <InfoIcon
                className="analysis-bar-chart-card__info-icon"
                onClick={() => onInfoClick && onInfoClick(config)}
              />
            )}
          </div>
        </Flex>
      </div>
    );

    const cardBody = (
      <div>
        <AppBarChart
          data={chartExtended.bars}
          width={'100%'}
          height={150}
          xKey="rangeLabel"
          yKey="value"
          onChartClick={onChartClick}
          style={{ marginLeft: '-8px' }}
          tooltip={
            <AnalysisBarChartTooltip
              showAvg={chartExtended.id !== COMPARISON_ID}
              showRule={chartExtended.id !== COMPARISON_ID}
            />
          }
          tickFormatter={(tick) => (tick ? shortenNumber(tick) : '0')}
        />
        <AnalysisBarChartLegend
          chartId={chartExtended.id}
          bars={chartExtended.bars}
          selectedBarIds={selectedBarIds}
        />
      </div>
    );

    const items: CollapseProps['items'] = [
      {
        key,
        label: cardHeader,
        children: cardBody,
      },
    ];

    const onChange = (key: string | string[]) => {
      const keys = Array.isArray(key) ? key : [key];
      for (const item of items) {
        const itemKey = String(item.key);
        if (keys.includes(itemKey)) {
          chartStore.expandChart(itemKey);
        } else {
          chartStore.collapseChart(itemKey);
        }
      }
    };

    return (
      <ConfigProvider
        theme={{
          components: {
            Collapse: {
              headerPadding: 8,
              contentPadding: 12,
            },
          },
        }}
      >
        <AnalysisBarChartCardCollapse
          className="analysis-bar-chart-card"
          activeKey={activeKey}
          onChange={onChange}
          expandIconPosition="end"
          style={{
            ...style,
            borderColor:
              isChartActive && isCardActivationNeeded
                ? '#3371b2'
                : 'transparent',
          }}
          items={items}
          collapsible="icon"
        />
      </ConfigProvider>
    );
  },
);
