import { evaluate } from '@nextgis/expression';

import { POI_POINT_LAYER_ID } from '../config';
import mapStore from '../store/map';

import { insertColorIcon } from './insertColorIcon';
import { getPoiLogos } from './poiLogo';

import type { NgwMap, PoiLogo, PoiProperties } from '../interfaces';
import type { Expression } from '@nextgis/expression';
import type { NgwLayerOptions } from '@nextgis/ngw-kit';
import type { CreatePopupContent, VectorAdapterOptions } from '@nextgis/webmap';
import type { Feature, FeatureCollection, Point } from 'geojson';

interface AddPoiLayersProps {
  createPopupContent?: CreatePopupContent;
  data: FeatureCollection<Point, PoiProperties>;
  ngwMap: NgwMap;
  adapterOptions?: NgwLayerOptions['adapterOptions'];
  colorExpression?: Expression;
  signal?: AbortSignal;
  fit?: boolean;
}

export function removePoiLayers(ngwMap: NgwMap) {
  ngwMap.removeLayer(POI_POINT_LAYER_ID);
  ngwMap.removeLayer(POI_POINT_LAYER_ID + 'min');
}

export async function addPoiLayers({
  fit,
  data,
  ngwMap,
  adapterOptions,
  colorExpression,
  createPopupContent,
}: AddPoiLayersProps) {
  const coloredIcons: Record<string, boolean> = mapStore.coloredIcons;

  function getIconName(properties: PoiProperties, color: string): string {
    let typeName = properties.TYPE || 'not_set';
    if (properties.SUBTYPE) {
      typeName += properties.SUBTYPE;
    }
    return typeName + color;
  }

  async function findPoiLogo(
    TYPE: string,
    SUBTYPE?: string | null,
  ): Promise<PoiLogo | undefined> {
    const logos = await getPoiLogos();
    if (SUBTYPE) {
      const matchedWithSubtype = logos.find(
        (logo) => logo.type === TYPE && logo.subtype === SUBTYPE,
      );
      if (matchedWithSubtype) {
        return matchedWithSubtype;
      }
    }

    return logos.find((logo) => logo.type === TYPE && !logo.subtype);
  }

  async function handleColorExpression(
    f: Feature<Point, PoiProperties>,
    colorExpression: Expression,
  ): Promise<void> {
    const color = evaluate(colorExpression, f.properties) as string;
    const icoName = getIconName(f.properties, color);
    f.properties.__icon = icoName;

    if (!coloredIcons[icoName]) {
      try {
        const poiLogo = await findPoiLogo(
          f.properties.TYPE,
          f.properties.SUBTYPE,
        );
        if (poiLogo) {
          const { logo, size } = poiLogo;
          await insertColorIcon({
            src: logo,
            name: icoName,
            size,
            color,
            ngwMap,
          });
          coloredIcons[icoName] = true;
        }
      } catch {
        //
      }
    }
  }

  if (colorExpression) {
    for (const feature of data.features) {
      await handleColorExpression(feature, colorExpression);
    }
  }

  const getPopupOptions: () => VectorAdapterOptions = () => {
    return createPopupContent
      ? {
          popupOnHover: true,
          popupOptions: {
            closeButton: false,
            createPopupContent,
          },
        }
      : {};
  };

  removePoiLayers(ngwMap);

  return Promise.all([
    ngwMap.addGeoJsonLayer({
      id: POI_POINT_LAYER_ID,
      fit,
      data,
      order: 20,
      minZoom: 10,
      ...adapterOptions,
      paint: {
        type: 'icon',
      },
      ...getPopupOptions(),
      interactive: true,
      nativePaint: true,
      layout: {
        'icon-image': [
          'coalesce',
          ['image', ['get', '__icon']],
          ['image', 'not_set'],
        ],
        'symbol-sort-key': [
          'case',
          ['has', '__sort'],
          ['-', ['get', '__sort']],
          ['has', 'SUBTYPE'],
          300,
          ['has', 'TYPE'],
          200,
          10,
        ],
        'icon-padding': -2,
      },
    }),
    ngwMap.addGeoJsonLayer({
      id: POI_POINT_LAYER_ID + 'min',
      data,
      order: 10,
      ...adapterOptions,
      ...getPopupOptions(),
      paint: {
        type: 'circle',
        color: colorExpression,
        stroke: false,
        radius: 3,
        opacity: 0.8,
      },
    }),
  ]);
}
