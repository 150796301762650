import appStore from '../../store/app';

import { FilterStyleTag } from './FilterStyleTag';

import type { FilterItem } from '../../interfaces';
import type { CSSProperties, ReactNode } from 'react';

const FilterTag = ({
  value,
  isCLosable = false,
  onClose,
  onClick,
  style,
}: {
  value: FilterItem;
  isCLosable?: boolean;
  onClose?: () => void;
  onClick?: () => void;
  style?: CSSProperties;
  children?: ReactNode;
}) => {
  const filterLabel = appStore.getKpiConfigById(value.id)?.labelDetailed;
  return (
    value.range && (
      <FilterStyleTag
        key={value.id}
        closable={isCLosable}
        style={style}
        onClose={onClose}
        onClick={onClick}
      >
        <span
          style={{
            fontWeight:
              value.range[0] === (value.domainRange && value.domainRange[0])
                ? '500'
                : 'normal',
          }}
        >{`${value.range[0]}`}</span>
        <span> ≤ </span>
        <span style={{ color: '#000', fontWeight: 500 }}>{filterLabel}</span>
        <span> ≤ </span>
        <span
          style={{
            fontWeight:
              value.range[1] === (value.domainRange && value.domainRange[1])
                ? '500'
                : 'normal',
          }}
        >
          {`${value.range[1]}`}
        </span>
      </FilterStyleTag>
    )
  );
};

export { FilterTag };
