import Cache from '@nextgis/cache';

import connector from '../api/connector';

import type { PoiAnalysisApiRequest } from '../api/analysisApi';
import type {
  AnalysisCriteria,
  SystemPoiCriteria,
  UserPoiCriteria,
} from '../domain/criteria';
import type { PoiAnalysisApi } from '../interfaces';

const cache = new Cache<Promise<PoiAnalysisApi> | undefined>();

export async function makeApiRequest({
  onRequestExecuted,
  criteria,
  signal,
}: {
  criteria: AnalysisCriteria<SystemPoiCriteria | UserPoiCriteria>;
  signal?: AbortSignal;
  onRequestExecuted?: () => void;
}) {
  const { kpi, poi, time_range, buffer, peak_hours, extent, compare } =
    criteria;
  const isPoiCategoryValid =
    poi.collection === 'user' || (poi.collection === 'system' && poi.category);
  if (kpi && isPoiCategoryValid && time_range) {
    try {
      const from = time_range[0] ? time_range[0].toISOString() : '';
      const to = time_range[1] ? time_range[1].toISOString() : '';
      const data: PoiAnalysisApiRequest<SystemPoiCriteria | UserPoiCriteria> = {
        kpi,
        poi,
        buffer,
        time_range: [from, to],
        peak_hours,
      };
      if (extent) data.extent = extent;
      if (compare) data.compare = compare;

      const resp = () => {
        try {
          return connector.makeQuery<PoiAnalysisApi>(
            `/api/component/viavi/analysis`,
            null,
            {
              data,
              method: 'POST',
              signal,
            },
          );
        } catch (er) {
          if (er instanceof Error && er.name === 'CancelError') {
            // ignore
          } else {
            // handle loading error
            throw er;
          }
        }
      };
      return await cache.add(
        `analysis${JSON.stringify(data)}`,
        resp,
        undefined,
        true,
      );
    } catch (er) {
      console.log(er);
    } finally {
      if (onRequestExecuted) {
        onRequestExecuted();
      }
    }
  }
}
