import type { PoiItem } from '../interfaces';

const mergePoiWith = (originalPoi: PoiItem[], newPoi: PoiItem[]): PoiItem[] => {
  // add new poiValues to original poi. new pois don't appear in original array
  const newPoiFormatted = newPoi.map(([key, ...tale]) => {
    return [key, tale];
  });
  const poiToMergeObj = Object.fromEntries(newPoiFormatted);
  const mergedPoi: PoiItem[] = originalPoi.map(
    ([poiKey, poiValues, poiCategories]) => [
      poiKey,
      [...poiValues, ...poiToMergeObj[poiKey][0]],
      [...poiCategories, ...poiToMergeObj[poiKey][1]],
    ],
  );
  return mergedPoi;
};

const removeValuesFromPoi = (
  poi: PoiItem[],
  indexesToRemove: number[],
): PoiItem[] => {
  return poi.map(([poiKey, poiValues, poiCategories]) => {
    const newPoiValues = poiValues.filter(
      (_, index) => !indexesToRemove.includes(index),
    );
    const newPoiCategories = poiCategories.filter(
      (_, index) => !indexesToRemove.includes(index),
    );
    return [poiKey, newPoiValues, newPoiCategories];
  });
};

export { mergePoiWith, removeValuesFromPoi };
