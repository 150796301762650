import dayjs from 'dayjs';

import { DATE_FORMAT } from '../config';

import type { UIAnalysisCriteria } from '../domain/criteria';
import type { SerializableParam } from '../store/interface';

const prepareArray = (v: unknown) =>
  Array.isArray(v) && v.length ? v : undefined;

export function createCriteriaSerializableParam(
  criteria: UIAnalysisCriteria,
): SerializableParam[] {
  return [
    {
      key: 'kpi',
      value: prepareArray(criteria.kpi),
      serialize: () => criteria.kpi?.join(','),
      deserialize: (value) => {
        criteria.kpi = value.split(',');
      },
    },
    {
      key: 'time-range',
      value:
        criteria.time_range && criteria.time_range.filter(Boolean).length === 2
          ? criteria.time_range
          : undefined,
      serialize: () =>
        criteria.time_range
          ? criteria.time_range.map((val) => val?.format(DATE_FORMAT)).join('x')
          : '',
      deserialize: (value) => {
        criteria.time_range = value
          .split('x')
          .map((str) => dayjs.utc(str, DATE_FORMAT));
      },
    },
    {
      key: 'peak-hours',
      value:
        criteria.peak_hours && criteria.peak_hours.length
          ? criteria.peak_hours
          : undefined,
      serialize: () =>
        criteria.peak_hours
          ? criteria.peak_hours.map((p) => p?.join('x')).join('_')
          : '',
      deserialize: (value) => {
        const peakHours = value.split('_').map((p) => p.split('x'));
        criteria.peak_hours = peakHours;
      },
    },
    {
      key: 'buffer',
      value:
        criteria.buffer && criteria.buffer > 0 ? criteria.buffer : undefined,
      serialize: () => String(criteria.buffer),
      deserialize: (value) => {
        criteria.buffer = Number(value);
      },
    },

    {
      key: 'poi[category]',
      value: criteria.poiCategory,
      serialize: () => criteria.poiCategory || '',
      deserialize: (value) => {
        criteria.poiCategory = value;
      },
    },
    {
      key: 'poi[subcategory]',
      value: prepareArray(criteria.poiSubcategory),
      serialize: () => criteria.poiSubcategory.join(','),
      deserialize: (value) => {
        criteria.poiSubcategory = value.split(',');
      },
    },
  ];
}
