import Cache from '@nextgis/cache';
import { makeObservable, observable, runInAction } from 'mobx';

import { USE_GEOCODER_LOCAL_STORAGE } from '../config';
import { stringifyCoordinate } from '../utils/stringifyCoordinate';

import type { Geocoder } from '@nextgis/geocoder';
import type { LngLatArray } from '@nextgis/utils';
import type { Position } from 'geojson';

class GeocoderStore {
  reverseResults: Record<string, string> = USE_GEOCODER_LOCAL_STORAGE
    ? JSON.parse(localStorage.getItem('reverseResults') || '{}')
    : false;
  reverseLoading: Record<string, boolean> = {};

  geocoder: Geocoder | null = null;

  private _cache: Cache<Promise<string>> = new Cache<Promise<string>>();

  constructor() {
    makeObservable(this, { reverseResults: observable });
  }

  setGeocoder(geocoder: Geocoder) {
    this.geocoder = geocoder;
  }

  appendReversResult(position: Position | string): Promise<string> {
    const geocoder = this.geocoder;
    if (!geocoder) {
      throw new Error('Geocoder is not ready');
    }
    const key = Array.isArray(position)
      ? stringifyCoordinate(position)
      : position;

    return this._cache.add(key, () => {
      this.reverseLoading[key] = true;
      return geocoder
        .reverseGeocodeFirstResult(position as LngLatArray)
        .then((res) => {
          if (res) {
            runInAction(() => {
              this.reverseResults = { ...this.reverseResults, [key]: res.text };
            });
            localStorage.setItem(
              'reverseResults',
              JSON.stringify(this.reverseResults),
            );
            return res.text;
          }
          return '';
        })
        .finally(() => {
          this.reverseLoading[key] = false;
        });
    });
  }
}

export default new GeocoderStore();
