import { Tag } from 'antd';
import { styled } from 'styled-components';

const FilterStyleTag = styled(Tag)`
  height: 32px;
  line-height: 30px;
  background-color: #e4ecf6;
  border-radius: 16px;
  padding: 0 10px;
  margin-top: 8px;
  cursor: pointer;
  color: #8491a4;
`;

export { FilterStyleTag };
