import { COMPARISON_ID } from '../config';
import {
  createLegendLabel,
  makeRangeStringByColoringRule,
  shortenNumber,
} from '../utils/chartUtils';

import type { ExtendedBarChartItem } from '../interfaces';
import type { ColumnsType } from 'antd/es/table';

const getTextClass = (
  barId: string | number,
  selectedBarIds: (number | string)[],
) => {
  if (selectedBarIds.length === 0) return '';
  return !selectedBarIds.includes(barId) ? 'text-muted' : '';
};

const useChartLegend = (chartId: string | number) => {
  const getKPILegendColumns = (
    selectedBarIds: (string | number)[],
  ): ColumnsType<ExtendedBarChartItem> => {
    return [
      {
        key: 'category',
        dataIndex: 'rangeLabel',
        render: (_, record) =>
          createLegendLabel(record.color, record.rangeLabel, {
            className: `text-sm ${getTextClass(record.id, selectedBarIds)}`,
          }),
      },
      {
        key: 'range',
        render: (_, record) => {
          return (
            <span className={getTextClass(record.id, selectedBarIds)}>
              {record.valueRule
                ? makeRangeStringByColoringRule(record.valueRule, {
                    nullValue: '-',
                  })
                : '-'}
            </span>
          );
        },
      },
      {
        key: 'total',
        dataIndex: 'value',
        render: (_, record) => (
          <strong
            className={`text-sm ${getTextClass(record.id, selectedBarIds)}`}
          >
            {record.value}
          </strong>
        ),
      },
      {
        key: 'average',
        render: (_, record) => (
          <span className="text-muted text-xs">
            {record.avg !== null ? `avg. ${shortenNumber(record.avg)}` : '-'}
          </span>
        ),
      },
    ];
  };
  const getComparisonLegendColumns = (
    selectedBarIds: (string | number)[],
  ): ColumnsType<ExtendedBarChartItem> => {
    return [
      {
        key: 'category',
        dataIndex: 'rangeLabel',
        render: (_, record) =>
          createLegendLabel(record.color, record.rangeLabel, {
            className: `text-sm ${getTextClass(record.id, selectedBarIds)}`,
          }),
      },
      {
        key: 'total',
        dataIndex: 'value',
        render: (_, record) => (
          <strong
            className={`text-sm ${getTextClass(record.id, selectedBarIds)}`}
          >
            {record.value}
          </strong>
        ),
      },
    ];
  };
  const getLegendColumns = (selectedBarIds: (string | number)[]) =>
    chartId === COMPARISON_ID
      ? getComparisonLegendColumns(selectedBarIds)
      : getKPILegendColumns(selectedBarIds);
  return { getLegendColumns };
};

export { useChartLegend };
