import { fetchNgwLayerExtent } from '@nextgis/ngw-kit';
import { NgwMap as NgwMap_ } from '@nextgis/ngw-map';
import { makeAutoObservable } from 'mobx';

import connector from '../api/connector';
import { EXTENT_RESOURCE } from '../config';
import { stringifyCoordinate } from '../utils/stringifyCoordinate';

import managerStore from './manager';

import type { RegistrableStore, SerializableParam } from './interface';
import type { NgwMap } from '../interfaces';
import type { LngLatArray, LngLatBoundsArray } from '@nextgis/utils';

class MapStore implements RegistrableStore {
  extent: LngLatBoundsArray | null = null;
  homeExtent: LngLatBoundsArray | null = null;
  ngwMapId: number | null = null;

  coloredIcons: Record<string, boolean> = {};
  featureCount: number | undefined;

  needAnalysisFit = false;

  constructor() {
    makeAutoObservable(this, { coloredIcons: false });
  }

  get ngwMap(): NgwMap | null {
    if (this.ngwMapId !== null) {
      return NgwMap_.get<NgwMap>(this.ngwMapId);
    }
    return null;
  }

  get serializableParams(): SerializableParam[] {
    return [
      {
        key: 'extent',
        value: Array.isArray(this.extent) ? this.extent : undefined,
        serialize: () => (this.extent ? stringifyCoordinate(this.extent) : ''),
        deserialize: (value) => {
          this.setExtent(value.split('x').map(Number));
        },
      },
    ];
  }

  setNgwMapId(ngwMapId: number | null) {
    this.ngwMapId = ngwMapId;
  }

  setExtent(extent: LngLatArray | null) {
    this.extent = extent;
  }

  setFeatureCount(featureCount: number | undefined) {
    this.featureCount = featureCount;
  }

  setNeedAnalysisFit(val: boolean) {
    this.needAnalysisFit = val;
  }

  async fetchMapExtent() {
    try {
      const resourceId = await connector.getResourceIdOrFail(EXTENT_RESOURCE);
      const extent = await fetchNgwLayerExtent({ connector, resourceId });
      if (extent) {
        this.homeExtent = extent;
        if (!this.extent) {
          this.setExtent(extent);
        }
      }
    } catch {
      //
    }
  }
}

export default managerStore.register(new MapStore());
