import { COMPARISON_DELTA_ID, COMPARISON_ID, NULL_LABEL } from '../config';

import type { ComparisonConfigItem, KpiConfigItem } from '../interfaces';

const getComparisonLabel = (
  category: number | null | undefined,
  comparisonConfig: ComparisonConfigItem,
) => {
  return category !== null && category !== undefined && comparisonConfig
    ? comparisonConfig.categoryColoringRules[category].label
    : NULL_LABEL;
};

const getComparisonDataKeys = ([kpiConfigA, kpiConfigB]: [
  KpiConfigItem,
  KpiConfigItem,
]): string[] => {
  if (kpiConfigA.name === kpiConfigB.name)
    return [COMPARISON_ID, COMPARISON_DELTA_ID];
  return [COMPARISON_ID];
};
export { getComparisonLabel, getComparisonDataKeys };
