import { useEffect, useMemo, useState } from 'react';

import analysisStore from '../store/analysis';
import {
  CHECK_FIELDS,
  getTableColumns,
  getTableHiddenFields,
} from '../utils/getTableColumns';

import type { PoiProperties } from '../interfaces';
import type { ColumnType } from 'antd/es/table';

type Columns = ColumnType<PoiProperties>[];

export function useTableColumns({
  data,
  hiddenFields: hiddenFieldsInit,
}: { data?: PoiProperties[]; hiddenFields?: string[] } = {}) {
  const { poiKpis } = analysisStore;

  const [hiddenFields, setHiddenFields] = useState<(keyof PoiProperties)[]>(
    hiddenFieldsInit ? hiddenFieldsInit : CHECK_FIELDS,
  );

  const columns = useMemo<Columns>(() => {
    return getTableColumns({ poiKpis, hiddenFields });
  }, [hiddenFields, poiKpis]);

  useEffect(() => {
    if (data) {
      const checkFields = getTableHiddenFields({ data });
      setHiddenFields(checkFields);
    }
  }, [data]);

  return columns;
}
