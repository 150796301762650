import { ConfigProvider, Table } from 'antd';

import { useChartLegend } from '../../hooks/useChartLegend';

import type { ExtendedBarChartItem } from '../../interfaces';

interface AnalysisBarChartLegendProps {
  chartId: string | number;
  bars: ExtendedBarChartItem[];
  selectedBarIds: (string | number)[];
}

const AnalysisBarChartLegend = ({
  chartId,
  bars,
  selectedBarIds,
}: AnalysisBarChartLegendProps) => {
  const { getLegendColumns } = useChartLegend(chartId);
  const columns = getLegendColumns(selectedBarIds);
  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            cellPaddingBlockSM: 2,
            cellPaddingInlineSM: 2,
          },
        },
      }}
    >
      <Table
        columns={columns}
        dataSource={bars}
        className="text-sm"
        size="small"
        showHeader={false}
        pagination={false}
        rowClassName="text-sm"
      />
    </ConfigProvider>
  );
};

export { AnalysisBarChartLegend };
