import connector from '../api/connector';
import NotSetLogo from '../assets/poi_logos/not_set.png';
import { POI_LOGO_SVG_RESOURCE } from '../config';

import type { PoiLogo } from '../interfaces';

const defaultPoiLogo: PoiLogo = {
  logo: NotSetLogo,
  size: [8, 8],
  type: 'not_set',
};

export async function getPoiLogos({
  signal,
}: {
  signal?: AbortSignal;
} = {}): Promise<PoiLogo[]> {
  const promises: Promise<PoiLogo>[] = [Promise.resolve(defaultPoiLogo)];
  const res = await connector.getResource(POI_LOGO_SVG_RESOURCE, {
    cache: true,
    signal,
  });
  if (res) {
    if (res.resource.cls !== 'svg_marker_library') {
      throw new Error(
        `Resource  ${POI_LOGO_SVG_RESOURCE} is not 'svg_marker_library' class`,
      );
    }
    for (const file of res.svg_marker_library.files) {
      promises.push(
        connector
          .get('resource.file_download', {
            params: { id: res.resource.id, name: file.name },
            cache: true,
            signal,
          })
          .then((src) => {
            const [type, subtype] = file.name.replace(/^\.\//, '').split('__');

            const logo: PoiLogo = {
              logo: src,
              type,
              subtype,
              size: [26, 26],
            };

            return logo;
          }),
      );
    }
    return Promise.all(promises);
  }
  throw new Error(`Resource ${POI_LOGO_SVG_RESOURCE} for SVG logos not found`);
}
