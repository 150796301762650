// import { createCanvasIcon } from './createCanvasIcon';
import { createSvgIcon } from './createIconSvg';

import type { NgwMap } from '../interfaces';
import type { Map } from 'maplibre-gl';

interface InsertColorIconProps {
  name: string;
  size: [width: number, height: number];
  color: string;
  src: string;
  ngwMap: NgwMap;
}

function svgToImage(
  svgContent: string,
  size: [width: number, height: number],
): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image(size[0], size[1]);
    img.onload = () => resolve(img);
    img.onerror = reject;

    const blob = new Blob([svgContent], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);
    img.src = url;
  });
}

export async function insertColorIcon({
  name,
  color,
  size,
  src,
  ngwMap,
}: InsertColorIconProps) {
  const map = ngwMap.mapAdapter.map as Map;

  const svg = createSvgIcon({ svgContent: src, size, color: String(color) });
  const img = await svgToImage(svg, size);

  if (!map.hasImage(name)) map.addImage(name, img);

  return name;
}
