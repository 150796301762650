import { theme } from 'antd';

import type { ReactNode } from 'react';

const { useToken } = theme;

interface MapSidebarProps {
  title: string;
  children?: ReactNode;
}

export function MapSidebar({ title, children }: MapSidebarProps) {
  const { token } = useToken();
  return (
    <div
      className="map-sidebar"
      style={{
        padding: '24px 16px 40px',
        backgroundColor: '#fff',
        height: 'calc(100vh - 56px)',
        overflow: 'auto',
        borderRight: `1px solid ${token.colorBorder}`,
      }}
    >
      <h3 style={{ marginBottom: '24px' }}>{title}</h3>
      {children}
    </div>
  );
}
