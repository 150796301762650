import { COMPARISON_LABELS, NULL_COLORING_RULE, NULL_LABEL } from '../config';

import type {
  CategoryConfigItem,
  ColoringRule,
  ComparisonPalleteItem,
  Rule,
} from '../interfaces';

const makeColoringRules = (
  levels: number[],
  categoryConfigs: CategoryConfigItem[],
): ColoringRule[] => {
  const isInversed = levels[levels.length - 1] < levels[0];
  const coloringRules: ColoringRule[] = [];
  const levelsFormatted = isInversed ? [...levels].reverse() : levels;
  const categoryConfigsFormatted = isInversed
    ? [...categoryConfigs].reverse()
    : categoryConfigs;

  levelsFormatted.forEach((level, index) => {
    if (index === 0) {
      coloringRules.push({
        rule: ['isBetween', [Number.NEGATIVE_INFINITY, level]],
        color: categoryConfigsFormatted[index][1],
        label: categoryConfigsFormatted[index][0],
      });
      coloringRules.push({
        rule: ['isBetween', [level, levelsFormatted[index + 1]]],
        color: categoryConfigsFormatted[index + 1][1],
        label: categoryConfigsFormatted[index + 1][0],
      });
    } else if (index === levels.length - 1) {
      coloringRules.push({
        rule: ['isBetween', [level, Number.POSITIVE_INFINITY]],
        color: categoryConfigsFormatted[index][1],
        label: categoryConfigsFormatted[index][0],
      });
    } else {
      coloringRules.push({
        rule: ['isBetween', [level, levelsFormatted[index + 1]]],
        color: categoryConfigsFormatted[index + 1][1],
        label: categoryConfigsFormatted[index + 1][0],
      });
    }
  });
  const coloringRulesFormatted = isInversed
    ? coloringRules.reverse()
    : coloringRules;
  coloringRulesFormatted.push({ ...NULL_COLORING_RULE, label: NULL_LABEL });
  return coloringRulesFormatted;
};

const makeCategoryColoringRules = (
  categoryConfigs: CategoryConfigItem[],
): ColoringRule[] => {
  const rules: ColoringRule[] = categoryConfigs.map((config, index) => ({
    rule: ['isSameAs', index],
    color: config[1],
    label: config[0],
  }));
  rules.push({ ...NULL_COLORING_RULE, label: NULL_LABEL });
  return rules;
};

const makeComparisonColoringRules = (
  pallete: ComparisonPalleteItem[],
): ColoringRule[] => {
  const rules: ColoringRule[] = pallete.map((palleteItem, index) => ({
    color: palleteItem.color,
    rule: ['isSameAs', index],
    label: COMPARISON_LABELS[index],
  }));
  rules.push({ ...NULL_COLORING_RULE, label: NULL_LABEL });
  return rules;
};

const checkValueForRule = (
  value: number | null,
  rule: [Rule, number | null | [number, number]],
): boolean => {
  if (rule[0] === 'isSameAs') {
    return value === rule[1];
  }
  if (
    rule[0] === 'isBetween' &&
    value !== null &&
    Array.isArray(rule[1]) &&
    rule[1].length > 0
  )
    return value >= rule[1][0] && value < rule[1][1];

  return false;
};

export {
  makeColoringRules,
  makeCategoryColoringRules,
  makeComparisonColoringRules,
  checkValueForRule,
};
