import { useCallback, useEffect, useState } from 'react';

import { usePoiFeatureCollection } from '../hooks/usePoiFeatureCollection';
import { useTableColumns } from '../hooks/useTableColumns';
import analysisStore from '../store/analysis';
import geocoderStore from '../store/geocoder';
import { getFeaturePropertiesForCaptions } from '../utils/featureProperties';
import { stringifyCoordinate } from '../utils/stringifyCoordinate';

import type { PoiProperties } from '../interfaces';
import type { Feature, Point } from 'geojson';

export const useTable = ({
  immediate = false,
}: { immediate?: boolean } = {}) => {
  const { getFeatureCollection } = usePoiFeatureCollection();
  const [features, setFeatures] = useState<Feature<Point, PoiProperties>[]>([]);
  const [data, setData] = useState<PoiProperties[]>();

  const { reverseResults, reverseLoading } = geocoderStore;

  const columns = useTableColumns({ data });

  const getData = useCallback(() => {
    setFeatures([]);
    getFeatureCollection().then((collection) => {
      if (collection) {
        setFeatures(collection.features);
      }
    });
  }, [getFeatureCollection]);

  useEffect(() => {
    if (immediate) {
      getData();
    }
  }, [getData, immediate]);

  useEffect(() => {
    const updatedData: PoiProperties[] = [];
    for (const f of features) {
      const props = getFeaturePropertiesForCaptions({
        ...f.properties,
      }) as PoiProperties;
      if (analysisStore.isMyPoi) {
        const position = f.geometry.coordinates;
        geocoderStore.appendReversResult(position);
        const key = stringifyCoordinate(position);
        const address = reverseResults[key];
        const addressLoading = reverseLoading[key];
        if (address) {
          props.ADDRESS = address;
        } else if (addressLoading) {
          props.ADDRESS = '...';
        }
      }
      updatedData.push(props);
    }
    setData(updatedData);
  }, [reverseResults, reverseLoading, features]);

  return { columns, data, getData };
};
