import { useCallback, useEffect } from 'react';

import { MY_POI_LABEL, MY_POI_VALUE } from '../config';
import appStore from '../store/app';

export function useMyPoi() {
  const { poiCategories } = appStore;
  const myPoiAddedIndex = useCallback(
    () => poiCategories.findIndex((p) => p.value === MY_POI_VALUE),
    [poiCategories],
  );

  const cleanPoiCategories = useCallback(() => {
    const index = myPoiAddedIndex();
    if (index !== -1) {
      const newPoiCategories = [...poiCategories];
      newPoiCategories.splice(index, 1);
      appStore.setPoiCategories(newPoiCategories);
    }
  }, [myPoiAddedIndex, poiCategories]);

  useEffect(() => {
    if (myPoiAddedIndex() === -1) {
      const newPoiCategories = [
        ...poiCategories,
        { label: MY_POI_LABEL, value: MY_POI_VALUE },
      ];
      appStore.setPoiCategories(newPoiCategories);
    }
    return cleanPoiCategories;
  }, [cleanPoiCategories, myPoiAddedIndex, poiCategories]);

  return {};
}
