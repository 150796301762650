import { Button, Space } from 'antd';
import { notification } from 'antd';
import { union } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useAbortController } from '../../hooks/useAbortController';
import { useAnalysisApi } from '../../hooks/useAnalysisApi';
import analysisStore, { AnalysisStore } from '../../store/analysis';
import appStore from '../../store/app';
import chartsStore from '../../store/charts';
import filterStore from '../../store/filter';
import geocoderStore from '../../store/geocoder';
import managerStore from '../../store/manager';
import myPoiStore from '../../store/myPoi';
import { createCriteriaSerializableParam } from '../../utils/createCriteriaSerializableParam';
import { createKpiFilter } from '../../utils/createKpiFilter';
import { getFeaturePropertiesForCaptions } from '../../utils/featureProperties';
import { getPoiFeatureCollection } from '../../utils/getPoiFeatureCollection';
import { getQueryParams } from '../../utils/getQueryParams';
import {
  getTableColumns,
  getTableHiddenFields,
} from '../../utils/getTableColumns';
import { AppSelect } from '../shared/AppSelect';
import { MapSidebar } from '../shared/MapSidebar';

import { downloadCsv } from './utils/downloadCsv';

import type { PoiProperties } from '../../interfaces';
import type { SerializableParam } from '../../store/interface';

const exportPoiStore = new AnalysisStore(filterStore);
export const ExportSidebar = () => {
  const { analysisService } = useAnalysisApi({});
  const { makeSignal } = useAbortController();
  const { geocoder } = geocoderStore;
  const { coordinates } = myPoiStore;
  const { serializableParams } = managerStore;
  const {
    kpiSelectItems,
    competitorsKpiSelectItems,
    scenario,
    comparisonConfig,
  } = appStore;
  const { selectedBars } = chartsStore;
  const { isMyPoi, criteria, previousAnalysisExtent } = analysisStore;
  const { filterValueIds } = filterStore;
  const [isLoading, setIsLoading] = useState(false);

  const [kpi, setKpi] = useState<string[]>(() => criteria.kpi || []);
  const exportCriteria = useMemo(
    () => ({ ...criteria, kpi: union(kpi, filterValueIds) }),
    [criteria, kpi, filterValueIds],
  );

  const getSharedLink = useCallback(() => {
    const customParams = createCriteriaSerializableParam(exportCriteria);
    const exportParams: SerializableParam[] = [];

    const excludeParams = ['tool'];

    for (const p of serializableParams) {
      if (excludeParams.includes(p.key)) {
        continue;
      }
      const customOverride = customParams.find((c) => c.key === p.key);
      if (customOverride) {
        exportParams.push(customOverride);
      } else {
        exportParams.push(p);
      }
    }

    const params = getQueryParams(exportParams);

    return `${location.origin}${location.pathname}?${params}`;
  }, [serializableParams, exportCriteria]);

  const exportToCSV = useCallback(async () => {
    try {
      setIsLoading(true);
      const exportRequestCriteria = analysisService.createCriteria({
        ...exportCriteria,
        extent: previousAnalysisExtent,
        coordinates,
      });
      const resp = await analysisService.loadAnalysedData(
        exportRequestCriteria,
        exportPoiStore,
      );
      if (resp) {
        const { poi, filteredPoiIds } = exportPoiStore;
        const filters = createKpiFilter(selectedBars);
        const collection = await getPoiFeatureCollection({
          poi,
          poiIds: filteredPoiIds,
          signal: makeSignal(),
          filters,
          poiKpis: exportCriteria.kpi,
          isMyPoi,
          comparisonConfig,
        });

        const features = collection?.features;

        if (!features) {
          return;
        }
        if (isMyPoi) {
          for (const feature of features) {
            if (!feature.properties.ADDRESS) {
              try {
                const geocoderResult = await geocoderStore.appendReversResult(
                  feature.geometry.coordinates,
                );
                feature.properties.ADDRESS = geocoderResult;
              } catch {
                //
              }
            }
          }
        }
        const data = features.map(
          (f) => getFeaturePropertiesForCaptions(f.properties) as PoiProperties,
        );

        const hiddenFields = getTableHiddenFields({ data });
        const columns = getTableColumns({
          poiKpis: exportCriteria.kpi,
          hiddenFields,
        });

        const link = getSharedLink();
        columns.push({ title: link });

        downloadCsv({ columns, data });
      }
    } catch (e) {
      notification.error({
        duration: 1000 * 5,
        placement: 'top',
        message: (e as Error).message,
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    analysisService,
    exportCriteria,
    getSharedLink,
    previousAnalysisExtent,
    coordinates,
    selectedBars,
    makeSignal,
    isMyPoi,
    comparisonConfig,
  ]);

  useEffect(() => {
    return () => {
      geocoder && geocoder.abort();
    };
  }, [geocoder]);

  const kpiSelectOptions =
    scenario === 'critical-areas'
      ? [...kpiSelectItems, ...competitorsKpiSelectItems]
      : kpiSelectItems;

  return (
    <MapSidebar title="Export to CSV">
      <Space direction="vertical" style={{ width: '100%' }}>
        <AppSelect
          style={{ width: '100%' }}
          mode="multiple"
          value={kpi}
          onChange={setKpi}
          options={kpiSelectOptions}
          placeholder="Select KPI"
          showSearch
          allowClear
          disabled={scenario === 'critical-areas'}
        />
        <Button type="primary" loading={isLoading} onClick={exportToCSV}>
          Export
        </Button>
      </Space>
    </MapSidebar>
  );
};
