import { useLocation } from 'react-router-dom';

import { BASE_PATH } from '../config';

const useSharedLink = () => {
  const location = useLocation();
  const searchQueryNormalized = location.search
    .replace('tool=share', '')
    .replace('display_mode=table', '')
    .replace('display_mode=map', '')
    .replace('?&', '?');
  const pathnameNormalized = location.pathname.replace('/print', '');
  const linkToMap = `${window.location.protocol}//${window.location.host}${BASE_PATH}${pathnameNormalized}${searchQueryNormalized}`;
  const linkToTable = `${linkToMap}&display_mode=table`;
  return { linkToMap, linkToTable };
};

export { useSharedLink };
