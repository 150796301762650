import appStore from '../store/app';
import { createKpiPaint } from '../utils/createKpiPaint';

import type { Expression } from '@nextgis/expression';

export function getKpiPaint({
  kpi,
  activeChartId,
}: {
  kpi: string[];
  activeChartId: string | number | null;
}): Expression | undefined {
  const activeChart = activeChartId || kpi[0];
  if (activeChart) {
    const config = appStore.getConfigById(activeChart);
    if (config) {
      return createKpiPaint(config);
    }
  }
}
