import { Alert, Flex, Input, InputNumber, Slider } from 'antd';
import { debounce } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { styled } from 'styled-components';

import appStore from '../../store/app';
import { isFilterItemAnalysed } from '../../utils/filterUtils';
import { AppSelect } from '../shared/AppSelect';
import { LinearProgress } from '../shared/LinearProgress';

import type { FilterItem, SelectOption } from '../../interfaces';
import type { SliderMarks } from 'antd/es/slider';
import type { CSSProperties } from 'styled-components';

interface FilterControlProps {
  value?: FilterItem;
  onChange?: (newValues: FilterItem) => void;
  style?: CSSProperties;
  options?: SelectOption[];
  isAnalysisLoading?: boolean;
}

const SliderInput = styled(InputNumber<number>)`
  width: 90px;
  text-align: center;
  flex-shrink: 0;

  .ant-input-number-handler-wrap {
    display: none;
  }

  & + .ant-input-number {
    order: 2;
  }
`;

const FilterControlSlider = styled(Slider)`
  width: 100%;
  min-width: 200px;
  margin: 16px !important;

  .ant-slider-mark {
    width: calc(100% + 8px);
    left: -4px;
  }

  .ant-slider-mark-text {
    &:first-child {
      transform: translate(0) !important;
    }
    &:last-child {
      transform: translate(-100%) !important;
    }
  }
`;
// updateRange is outside rendereing function because of onChange causes max call stack size error
const updateRange = debounce(
  ({
    currentValue,
    newRange,
    onChange,
  }: {
    currentValue?: FilterItem;
    newRange?: [number, number];
    onChange?: (newValues: FilterItem) => void;
  }) => {
    if (newRange && currentValue && onChange)
      onChange({
        ...currentValue,
        range: newRange,
      });
  },
  50,
);

const FilterControl = ({
  value,
  onChange,
  style,
  options,
  isAnalysisLoading,
}: FilterControlProps) => {
  const marks: SliderMarks | undefined = value &&
    value.domainRange && {
      [value.domainRange[0]]: String(value.domainRange[0]),
      [value.domainRange[1]]: String(value.domainRange[1]),
    };

  const [localValue, setLocalValue] = useState(value && value.range);

  useEffect(() => {
    if (!!value && !!value.range && !localValue) setLocalValue(value.range);
  }, [value, localValue]);

  const onSliderChange = (newSliderValue: number[] | number) => {
    setLocalValue(newSliderValue as [number, number]);
    updateRange({
      currentValue: value,
      newRange: newSliderValue as [number, number],
      onChange,
    });
  };

  const onFromInputChange = (fromValue: number) => {
    const newRange: [number, number] | undefined =
      !!localValue && !!fromValue ? [fromValue, localValue[1]] : undefined;
    if (newRange) setLocalValue(newRange);
    updateRange({
      currentValue: value,
      newRange: newRange,
      onChange,
    });
  };
  const onToInputChange = (toValue: number) => {
    const newRange: [number, number] | undefined =
      !!localValue && !!toValue ? [localValue[0], toValue] : undefined;
    if (newRange) setLocalValue(newRange);
    updateRange({
      currentValue: value,
      newRange: newRange,
      onChange,
    });
  };

  const onSelectChange = (newSelectValue: string) => {
    if (onChange)
      onChange({
        id: newSelectValue,
        range: undefined,
        isFromAnalysisCriteria: false,
      });
  };

  const status = useMemo(() => {
    const isAnalysed = isFilterItemAnalysed(value);
    if (!value) return 'iddle';
    if (value && !isAnalysed && isAnalysisLoading) return 'loading';
    if (value && isAnalysed && value.domainRange === undefined) return 'failed';
    if (value && isAnalysed && !!value.domainRange) return 'success';
  }, [value, isAnalysisLoading]);

  const filterLabel =
    value && appStore.getKpiConfigById(value.id)?.labelDetailed;
  return (
    <Flex style={{ margin: '8px 0', ...style }} gap={16}>
      {value ? (
        <Input
          value={filterLabel}
          placeholder="Select KPI"
          readOnly={true}
          style={{ width: '210px', flexShrink: 0 }}
        />
      ) : (
        <AppSelect
          options={options}
          placeholder="Select KPI"
          style={{ width: '210px', flexShrink: 0 }}
          onChange={onSelectChange}
          showSearch
        />
      )}
      <Flex style={{ flexGrow: 1, alignItems: 'center' }}>
        {status === 'loading' && <LinearProgress color="#8692A2" />}
        {status === 'failed' && (
          <Alert
            message="No filtration available"
            type="warning"
            style={{ width: '100%' }}
          />
        )}

        {value && value.range && value.domainRange && localValue && (
          <>
            <SliderInput
              value={localValue[0]}
              onChange={(value) => {
                if (value) onFromInputChange(value);
              }}
              min={value.domainRange[0]}
              max={localValue[1]}
            />
            <SliderInput
              value={localValue[1]}
              min={localValue[0]}
              max={value.domainRange[1]}
              onChange={(value) => {
                if (value) onToInputChange(value);
              }}
            />
            <FilterControlSlider
              range
              min={value.domainRange[0]}
              max={value.domainRange[1]}
              value={localValue}
              marks={marks}
              onChange={onSliderChange}
              step={0.5}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
};

export { FilterControl };
