import { PlusOutlined } from '@ant-design/icons';
import { Button, Flex } from 'antd';
import { useRef } from 'react';

import { ControlMultiplicator } from '../components/shared/ControlMultiplicator';

import { HoursRangePicker } from './shared/HoursRangePicker';

import type { HourRange } from './shared/HoursRangePicker';
import type { CSSProperties } from 'styled-components';

interface PeakHoursControlProps {
  values: (HourRange | undefined)[];
  isDisabled?: boolean;
  onChange: (newValues: (HourRange | undefined)[]) => void;
  style?: CSSProperties;
}

const PeakHoursControl = ({
  values,
  isDisabled,
  onChange,
  style,
}: PeakHoursControlProps) => {
  const containerRef = useRef(null);

  const addControl = () => {
    onChange([...values, undefined]);
  };

  return (
    <div ref={containerRef} style={style}>
      <Button
        disabled={isDisabled}
        type="default"
        onClick={addControl}
        className="font-weight-regular"
        icon={<PlusOutlined />}
        style={{
          paddingLeft: '8px',
          paddingRight: '8px',
          display: values.length === 0 ? 'inline-block' : 'none',
        }}
      >
        Add peak hours
      </Button>

      {values.length > 0 && (
        <>
          <Flex align="center" style={{ marginBottom: '8px' }}>
            <h4 style={{ flexGrow: 1 }}>Peak hours</h4>
            <Button
              type="link"
              onClick={addControl}
              className="font-weight-regular"
              icon={<PlusOutlined />}
              style={{
                paddingLeft: '8px',
                paddingRight: '8px',
              }}
            >
              Add time range
            </Button>
          </Flex>
          <ControlMultiplicator<HourRange | undefined>
            values={values}
            controlValueProp="value"
            onChange={onChange}
            controlOnChangeProp="onChange"
            isLastRemovable={true}
          >
            <HoursRangePicker selectStyle={{ width: '116px' }} />
          </ControlMultiplicator>
        </>
      )}
    </div>
  );
};

export { PeakHoursControl };
