import { Button, Col, Layout, Row } from 'antd';

import logo from '../../assets/logo.svg';
import appStore from '../../store/app';

import { AppSidebar } from './AppSidebar';

import type { ReactNode } from 'react';

interface AppLayoutProps {
  children: ReactNode;
  renderSidebar?: ReactNode;
}

export function AppLayout({ children, renderSidebar }: AppLayoutProps) {
  const { Header, Content } = Layout;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header className="app-header">
        <Row
          justify="space-between"
          style={{ flex: '0 0 100%' }}
          align="middle"
        >
          <Col>
            <img
              style={{ width: '104px', verticalAlign: 'middle' }}
              src={logo}
            ></img>
            <span className="app-header-title">Business Analytics</span>
          </Col>
          <Col>
            <div>
              <Button
                ghost
                onClick={() => {
                  appStore.logout();
                }}
              >
                Log out
              </Button>
            </div>
          </Col>
        </Row>
      </Header>
      <Layout>
        <AppSidebar>{renderSidebar}</AppSidebar>
        <Content className="layout__content" style={{ display: 'flex' }}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
}
